import OptionsCard from '@lib/OptionsCard'
import SearchIcon from '@mui/icons-material/Search'
import SearchOffIcon from '@mui/icons-material/SearchOff'
import { Button, TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import styles from './optionCard.module.scss'

interface IOptionsCardProps {
  value: string
  handleSearch: (phrase: string, isSearched: boolean) => void
}

const TransportsOptionsCard = ({ ...props }: IOptionsCardProps): JSX.Element => {
  const { t } = useTranslation()
  const [phrase, setPhrase] = useState<string>('')

  const handleClear = () => {
    setPhrase('')
    props.handleSearch('', false)
  }

  useEffect(() => {
    if (props.value) setPhrase(props.value)
  }, [props.value])

  return (
    <OptionsCard>
      <TextField
        value={phrase}
        className={`${styles.optionCardSearch} ongoing-market-tour-1`}
        label={t('Market:publishedTable.searchTitle')}
        onChange={(e) => setPhrase(e.target.value)}
      />
      <Button
        variant={'contained'}
        onClick={() => props.handleSearch(phrase, true)}
        disabled={!phrase}
        className='ongoing-market-tour-2'
      >
        <SearchIcon />
      </Button>
      <Button
        variant={'contained'}
        onClick={() => handleClear()}
        disabled={!phrase}
        className='ongoing-market-tour-3'
      >
        <SearchOffIcon />
      </Button>
    </OptionsCard>
  )
}

export default TransportsOptionsCard
