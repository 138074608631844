import { useState, useRef } from 'react'
import ArchiveOptionCard from './components/ArchiveOptionCard'
import { fleetObject } from '@pkg/sokarUI'
import { useSearchLoadsQuery } from '@features/loads/loadsApiSlice'
import { useSearchDriverQuery } from '@/features/drivers/driversApiSlice'
import { useSearchTrailerQuery, useSearchTruckQuery } from '@/features/equipment/equipmentApiSlice'
import { IConfirmationDialogRef } from '@lib/ConfirmationDialog'

import { Box } from '@mui/system'

import ArchiveIcon from '@mui/icons-material/Archive'
import LoadsArchive from './components/LoadsArchive'
import DriversArchive from './components/DriversArchive'
import TrucksArchive from './components/TrucksArchive'
import TrailersArchive from './components/TrailersArchive'
import ConfirmationDialog from '@lib/ConfirmationDialog'

const FleetArchiveView: React.FC = () => {
  const [archiveKey, setArchiveKey] = useState<fleetObject>('load')
  const [searchPhrase, setSearchPhrase] = useState<string>('')
  const [isSearched, setIsSearched] = useState<boolean>(false)

  const handleSearch = (phrase: string, isSearched: boolean): void => {
    setSearchPhrase(phrase)
    setIsSearched(isSearched)
  }

  const {
    data: loads,
    isSuccess: isLoadsSuccess,
    refetch: refetchLoads,
  } = useSearchLoadsQuery(
    { phrase: searchPhrase, archived: true },
    {
      skip: !isSearched || archiveKey != 'load',
    },
  )

  const {
    data: drivers,
    isSuccess: isDriversSuccess,
    refetch: refetchDrivers,
  } = useSearchDriverQuery(
    { phrase: searchPhrase, archived: true },
    {
      skip: !isSearched || archiveKey != 'driver',
    },
  )

  const {
    data: trucks,
    isSuccess: isTrucksSuccess,
    refetch: refetchTrucks,
  } = useSearchTruckQuery(
    { phrase: searchPhrase, archived: true },
    {
      skip: !isSearched || archiveKey != 'truck',
    },
  )

  const {
    data: trailers,
    isSuccess: isTrailerSuccess,
    refetch: refetchTrailers,
  } = useSearchTrailerQuery(
    { phrase: searchPhrase, archived: true },
    {
      skip: !isSearched || archiveKey != 'trailer',
    },
  )

  const confirmationDialogRef = useRef<IConfirmationDialogRef>(null)

  return (
    <>
      <ArchiveOptionCard
        value={searchPhrase}
        archiveKey={archiveKey}
        setArchiveKey={setArchiveKey}
        handleSearch={handleSearch}
      />
      {!isSearched && (
        <Box
          sx={{
            height: 'calc(100vh - 200px)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ArchiveIcon sx={{ fontSize: '10rem', color: '#bbb' }} />
        </Box>
      )}
      {isSearched && archiveKey === 'load' && (
        <LoadsArchive
          data={loads ?? []}
          refetch={refetchLoads}
          confirmationDialogRef={confirmationDialogRef}
        />
      )}
      {isSearched && archiveKey === 'driver' && (
        <DriversArchive
          data={drivers ?? []}
          refetch={refetchDrivers}
          confirmationDialogRef={confirmationDialogRef}
        />
      )}
      {isSearched && archiveKey === 'truck' && (
        <TrucksArchive
          data={trucks ?? []}
          refetch={refetchTrucks}
          confirmationDialogRef={confirmationDialogRef}
        />
      )}
      {isSearched && archiveKey === 'trailer' && (
        <TrailersArchive
          data={trailers ?? []}
          refetch={refetchTrailers}
          confirmationDialogRef={confirmationDialogRef}
        />
      )}
      <ConfirmationDialog ref={confirmationDialogRef} />
    </>
  )
}

export default FleetArchiveView
