export const parseDistance = (distance: number, from: string, to: string): number => {
  if (from === 'kilometers' && to === 'meters') {
    return distance * 1000
  }
  if (from === 'miles' && to === 'meters') {
    return distance * 1609.34
  }
  if (from === 'meters' && to === 'kilometers') {
    return distance / 1000
  }
  if (from === 'meters' && to === 'miles') {
    return distance / 1609.34
  }

  return distance
}

export const parseDistanceUnitToSymbol = (unit: string): string => {
  if (unit === 'miles') {
    return 'mi'
  }
  if (unit === 'kilometers') {
    return 'km'
  }
  if (unit === 'meters') {
    return 'm'
  }

  throw new Error('Unsupported distance unit conversion requested. Aborting...')
}
