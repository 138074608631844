import { useEffect, useRef, MutableRefObject } from 'react'
import { t } from 'i18next'
import { useTheme } from '@emotion/react'

import DriverCard from '@app/fleet/views/DriversView/components/driverCard'
import { ITableColumn } from 'src/pkg/sokarUI'
import SideDialog from '@lib/SideDialog'
import ContentList, { IContentListRef } from '@lib/ContentList'
import { IDriver } from '@pkg/drivers/models'
import ContentTable, { ITableRef } from 'src/lib/ContentTable'
import DriverDialog from './driverDialog'
import { parsePhoneNumber } from 'src/shared/utils/formatters'

import { Chip, useMediaQuery } from '@mui/material'

const columns: ITableColumn[] = [
  {
    label: t('Fleet:driversTable.name'),
    dataKey: 'firstName',
    isSortable: true,
  },
  {
    label: t('Fleet:driversTable.surname'),
    dataKey: 'lastName',
    isSortable: true,
  },
  {
    label: t('Fleet:driversTable.phone'),
    dataKey: 'phone',
    isSortable: false,
  },
  {
    label: 'e-mail',
    dataKey: 'email',
    isSortable: false,
  },
  {
    label: t('Fleet:driversTable.driverType'),
    dataKey: 'groups',
    isSortable: false,
  },
]

interface DriverArchiveProps {
  data: any[]
  refetch: () => void
  confirmationDialogRef: MutableRefObject<any>
}

const DriversArchive: React.FC<DriverArchiveProps> = ({ ...props }) => {
  const appTheme: any = useTheme()
  const isMobile: boolean = useMediaQuery(appTheme.breakpoints.down('md'))

  const driverDialogRef = useRef<any>(null)
  const handleDriverDialogOpen = (row: IDriver): void => {
    driverDialogRef.current?.open(row)
  }
  const handleDriverDialogClose = (): void => driverDialogRef.current?.close()

  const cellRenderer = (column: ITableColumn, row: any): any => {
    if (column.dataKey === 'groups')
      return (
        <Chip
          color={'primary'}
          label={
            row.isOwner ? t('Fleet:driversTable.ownerDriver') : t('Fleet:driversTable.fleetDriver')
          }
        />
      )
    if (column.dataKey === 'phone') return parsePhoneNumber(row[column.dataKey])

    return row[column.dataKey]
  }

  const ref = useRef<ITableRef>(null)
  const listRef = useRef<IContentListRef>(null)
  useEffect(() => {
    if (isMobile) listRef.current?.open(props.data)
    else ref.current?.open(props.data, columns)
  }, [isMobile, props.data, columns])

  return (
    <>
      {isMobile ?
        <ContentList
          ref={listRef}
          listComponent={DriverCard}
          openDialog={handleDriverDialogOpen}
        />
      : <ContentTable
          ref={ref}
          cellRenderer={cellRenderer}
          openDialog={handleDriverDialogOpen}
        />
      }
      <SideDialog
        ref={driverDialogRef}
        title={t('Fleet:driverDialog.title')}
      >
        <DriverDialog
          refetch={props.refetch}
          elementRef={driverDialogRef}
          handleClose={handleDriverDialogClose}
          confirmationRef={props.confirmationDialogRef}
        />
      </SideDialog>
    </>
  )
}

export default DriversArchive
