import './style.scss'

import { ITheme } from '@pkg/sokarUI'

import { Paper, useTheme } from '@mui/material'

interface OptionsCardProps {
  children: React.ReactNode
  className?: string
}

const OptionsCard: React.FC<OptionsCardProps> = ({ children, className }) => {
  const appTheme: ITheme = useTheme()

  return (
    <Paper
      className={`options_card_wrapper ${className}`}
      sx={{ backgroundColor: appTheme.palette.background.default }}
    >
      {children}
    </Paper>
  )
}

export default OptionsCard
