import { useEffect, useState } from 'react'
import { useTheme } from '@emotion/react'
import { FieldError } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useGetTruckQuery, useSearchTruckQuery } from '@/features/equipment/equipmentApiSlice'
import styles from './style.module.scss'

import {
  Box,
  Stack,
  Table,
  Paper,
  Radio,
  Button,
  Tooltip,
  TableRow,
  TableBody,
  TableCell,
  TextField,
  RadioGroup,
  TableContainer,
  Typography,
  CircularProgress,
} from '@mui/material'

import SearchIcon from '@mui/icons-material/Search'

interface TruckSearchProps {
  isDisabled: boolean
  onChange: any
  value: string
  companyId?: string
  error?: FieldError
}

export const TruckSearch = ({ ...props }: TruckSearchProps): JSX.Element => {
  const { t } = useTranslation()
  const appTheme: any = useTheme()
  const [truckId, setTruckId] = useState<string | undefined>(undefined)
  const [searchPhrase, setSearchPhrase] = useState<string>('')
  const [isSearched, setIsSearched] = useState<boolean>(false)

  const {
    data: truck,
    isSuccess: isTruckSuccess,
    isFetching: isTrucksFetching,
  } = useGetTruckQuery(
    { id: truckId ?? '', carrierId: props.companyId ?? '' },
    { skip: !truckId || isSearched },
  )

  const {
    data: trucks,
    isSuccess,
    refetch: refetchTrucks,
    isFetching: isSearchFetching,
  } = useSearchTruckQuery(
    { phrase: searchPhrase, carrierId: props.companyId ?? '' },
    { skip: !isSearched },
  )

  useEffect(() => {
    if (props.value) setTruckId(props.value)
  }, [props.value])

  const handleSearch = (): void => {
    if (!isSearched) setIsSearched(true)
    else if (isSuccess) refetchTrucks()
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault() // Prevent default behavior of form submission
    }
  }

  return (
    <>
      <TableContainer
        component={Paper}
        className={!!props.error ? styles.searchWrapperError : styles.searchWrapper}
        sx={{ backgroundColor: appTheme.palette.background.default }}
      >
        <Stack
          direction={'row'}
          spacing={2}
        >
          <TextField
            label={t('Common:UI.search.truck')}
            sx={{ flex: 1 }}
            disabled={props.isDisabled}
            value={searchPhrase}
            onChange={(e) => setSearchPhrase(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <Tooltip title={t('Common:search')}>
            <Button
              variant={'contained'}
              onClick={handleSearch}
              disabled={searchPhrase.length === 0 || props.isDisabled}
            >
              <SearchIcon />
            </Button>
          </Tooltip>
        </Stack>
        <RadioGroup
          name={'carrier'}
          defaultValue={props.value ?? ''}
          onChange={props.onChange}
        >
          <Table className={styles.table}>
            {(isTrucksFetching || isSearchFetching) && (
              <Box className={'company_search_loader'}>
                <CircularProgress size={'6rem'} />
              </Box>
            )}
            <TableBody>
              {isTruckSuccess &&
                truck.length > 0 &&
                truck.map((truck: any) => (
                  <TableRow
                    key={truck.id}
                    className={styles.tableRow}
                  >
                    <TableCell>
                      <Radio
                        value={truck.id}
                        disabled={props.isDisabled}
                      />
                    </TableCell>
                    <TableCell>{truck.number}</TableCell>
                    <TableCell>{truck.plate}</TableCell>
                  </TableRow>
                ))}
              {isSuccess &&
                trucks.length > 0 &&
                trucks.map((truck: any) => (
                  <TableRow
                    key={truck.id}
                    className={styles.tableRow}
                  >
                    <TableCell>
                      <Radio
                        value={truck.id}
                        disabled={props.isDisabled}
                      />
                    </TableCell>
                    <TableCell>{truck.number}</TableCell>
                    <TableCell>{truck.plate}</TableCell>
                  </TableRow>
                ))}
              {isSearched && trucks?.length == 0 && (
                <Box className={styles.noMatch}>
                  <Typography>{t('Common:noMatch')}</Typography>
                </Box>
              )}
            </TableBody>
          </Table>
        </RadioGroup>
      </TableContainer>
      <Typography sx={{ color: appTheme.palette.error.main }}>{props.error?.message}</Typography>
    </>
  )
}

export default TruckSearch
