import { useReducer, createContext, ReactNode, Dispatch, Reducer } from 'react'

/**
 * Defines the severity levels for alerts
 * @typedef {('success' | 'error' | 'info' | 'warning')} AlertSeverity
 */
export type AlertSeverity = 'success' | 'error' | 'info' | 'warning'

/**
 * Represents the state for an alert
 * @interface IAlertState
 */
export interface IAlertState {
  show: boolean
  content: string
  severity: AlertSeverity
}

/**
 * Defines the actions that can be dispatched to the alert reducer
 * @typedef {Object} AlertAction
 * @property {'SHOW'} type - Action type to show the alert
 * @property {Object} payload - Payload for showing the alert
 * @property {string} payload.content - Content of the alert
 * @property {AlertSeverity} payload.severity - Severity of the alert
 * @property {'HIDE'} type - Action type to hide the alert
 */
export type AlertAction =
  | { type: 'SHOW'; payload: { content: string; severity?: AlertSeverity } }
  | { type: 'HIDE' }

/**
 * Context interface for the alert
 * @interface IAlertContext
 */
export interface IAlertContext {
  state: IAlertState
  dispatch: Dispatch<AlertAction>
}

/**
 * Initial state for the alert context
 * @constant {IAlertState} initialAlertState
 */
const initialAlertState: IAlertState = {
  show: false,
  severity: 'info',
  content: '',
}

/**
 * Context for managing alert
 * @constant {React.Context<IAlertContext>} AlertContext
 */
const AlertContext = createContext<IAlertContext | undefined>(undefined)

/**
 * Reducer function to handle alert actions
 * @param {IAlertState} state - The current state of the alert
 * @param {AlertAction} action - The action to be handled by the reducer
 * @returns {IAlertState} The new state after the action is processed
 */
const AlertReducer: Reducer<IAlertState, AlertAction> = (state, action) => {
  switch (action.type) {
    case 'SHOW':
      return {
        ...state,
        show: true,
        content: action.payload.content,
        severity: action.payload.severity ?? 'info',
      }
    case 'HIDE':
      return {
        ...state,
        show: false,
      }
    default:
      return state
  }
}

/**
 * Provider component that wraps the application and provides alert context
 * @param {Object} props - The component props
 * @param {ReactNode} props.children - The child components to be rendered within the provider
 * @returns {JSX.Element} The provider component with context
 */
export const AlertProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [state, dispatch] = useReducer(AlertReducer, initialAlertState)

  return <AlertContext.Provider value={{ state, dispatch }}>{children}</AlertContext.Provider>
}

export default AlertContext
