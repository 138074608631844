import { useEffect, useRef, useCallback } from 'react'
import { t } from 'i18next'
import dayjs from 'dayjs'
import { useGetLoadQuery } from '@features/loads/loadsApiSlice'
import ContentTable, { ITableRef } from '@lib/ContentTable'
import { ITableColumn, ITheme } from '@pkg/sokarUI'
import { ILoad } from '@pkg/loads/models'
import SideDialog from '@lib/SideDialog'
import ClientOfferDialog from './components/clientOfferDialog'
import ContentList from '@lib/ContentList'
import ClientOfferCard from './components/clientOfferCard'
import appConfig from '@/config'
import CountryFlag from '@/lib/CountryFlag'
import styles from './clientOffers.module.scss'

import { Skeleton, Typography, useMediaQuery, useTheme } from '@mui/material'

const columns: ITableColumn[] = [
  {
    label: t('Market:clientOffers.pickupLocation'),
    dataKey: 'pickupLocation',
    isSortable: false,
  },
  {
    label: t('Market:clientOffers.pickupDate'),
    dataKey: 'pickupDate',
    isSortable: false,
  },
  {
    label: t('Market:clientOffers.deliveryLocation'),
    dataKey: 'deliveryLocation',
    isSortable: false,
  },
  {
    label: t('Market:clientOffers.deliveryDate'),
    dataKey: 'deliveryDate',
    isSortable: false,
  },
  {
    label: t('Market:clientOffers.rate'),
    dataKey: 'rate',
    isSortable: false,
  },
]

const ClientOffers: React.FC = () => {
  const appTheme: ITheme = useTheme()
  // @ts-ignore
  const isMobile: boolean = useMediaQuery(appTheme.breakpoints.down('md'))
  const { data: transportOffers, isSuccess: isOffersSuccess } = useGetLoadQuery({
    published: true,
    tags: 'shipper-offer',
  })

  const tableRef = useRef<ITableRef>(null)
  const listRef = useRef<any>(null)
  useEffect(() => {
    if (transportOffers) tableRef.current?.open(transportOffers, columns)
    if (transportOffers && isMobile) listRef.current?.open(transportOffers)
  }, [transportOffers, isMobile])

  const cellRenderer = (column: ITableColumn, row: ILoad): JSX.Element => {
    if (column.dataKey === 'pickupLocation') {
      return (
        <div className={styles.containerLocation}>
          <CountryFlag countryName={row.stops[0].countryAbbreviation} />
          <Typography>{`${row.stops[0].countryAbbreviation} ${row.stops[0].city}`}</Typography>
        </div>
      )
    }
    if (column.dataKey === 'pickupDate') {
      return <Typography>{dayjs(row.stops[0].date).format(appConfig.DATE_FORMAT)}</Typography>
    }
    if (column.dataKey === 'deliveryLocation') {
      return (
        <div className={styles.containerLocation}>
          <CountryFlag countryName={row.stops[row.stops.length - 1].countryAbbreviation} />
          <Typography>{`${row.stops[row.stops.length - 1].countryAbbreviation} ${row.stops[row.stops.length - 1].city}`}</Typography>
        </div>
      )
    }
    if (column.dataKey === 'deliveryDate') {
      return (
        <Typography>
          {dayjs(row.stops[row.stops.length - 1].date).format(appConfig.DATE_FORMAT)}
        </Typography>
      )
    }
    if (column.dataKey === 'rate') {
      return <Typography>{row.publishedPriceCurrency + ' ' + row.publishedPrice}</Typography>
    }

    return <Typography>{row[column.dataKey as keyof ILoad] as string}</Typography>
  }

  const offerDialogRef = useRef<any>(null)
  const handleOfferDialogOpen = useCallback((row: ILoad): void => {
    offerDialogRef.current?.open(row)
  }, [])

  const getProperComponent = (): JSX.Element => {
    if (isMobile) {
      return (
        <ContentList
          ref={listRef}
          listComponent={ClientOfferCard}
          openDialog={handleOfferDialogOpen}
        />
      )
    }
    return (
      <ContentTable
        ref={tableRef}
        cellRenderer={cellRenderer}
        style={{ height: 'calc(100dvh - 120px)' }}
        openDialog={handleOfferDialogOpen}
      />
    )
  }

  if (!isOffersSuccess) {
    return <Skeleton />
  }

  return (
    <>
      {getProperComponent()}
      <SideDialog
        ref={offerDialogRef}
        title={t('Market:clientOfferDialog.title')}
      >
        <ClientOfferDialog />
      </SideDialog>
    </>
  )
}

export default ClientOffers
