import { t } from 'i18next'
import styles from './offersManager.module.scss'
import dayjs from 'dayjs'
import { ITableColumn } from '@pkg/sokarUI'
import { Box, Typography, Checkbox } from '@mui/material'
import CountryFlag from '@lib/CountryFlag'
import TrailerInfo from '@lib/TrailerInfo'
import appConfig from '@/config'
import { ILoad } from '@pkg/loads/models'

export const OffersManagerTableColumns: ITableColumn[] = [
  {
    label: t('Market:publishedTable.equipment'),
    dataKey: 'equipment',
    isSortable: true,
  },
  {
    label: t('Market:publishedTable.modifiedAt'),
    dataKey: 'modifiedAt',
    isSortable: true,
  },
  {
    label: t('Market:publishedTable.weight'),
    dataKey: 'weight',
    isSortable: true,
  },
  {
    label: t('Market:publishedTable.publishedPrice'),
    dataKey: 'publishedPrice',
    isSortable: true,
  },
  {
    label: t('Market:publishedTable.pickupLocation'),
    dataKey: 'pickupLocation',
    isSortable: false,
  },
  {
    label: t('Market:publishedTable.pickupDate'),
    dataKey: 'pickupDate',
    isSortable: false,
  },
  {
    label: t('Market:publishedTable.deliveryLocation'),
    dataKey: 'deliveryLocation',
    isSortable: false,
  },
  {
    label: t('Market:publishedTable.deliveryDate'),
    dataKey: 'deliveryDate',
    isSortable: false,
  },
]

export const OffersManagerCellRenderer = ({ dataKey }: ITableColumn, row: ILoad): JSX.Element => {
  if (dataKey === 'equipment') {
    return (
      <TrailerInfo
        trailers={row.equipment.split(',')}
        viewAll={false}
      />
    )
  }
  if (dataKey === 'modifiedAt') {
    return (
      <Box className={styles.modifiedAt}>
        <Typography>{dayjs(row.updatedAt).format(appConfig.DATE_FORMAT)}</Typography>
        <Typography>{dayjs(row.updatedAt).format('HH:mm')}</Typography>
      </Box>
    )
  }
  if (dataKey === 'weight') {
    return (
      <Typography>
        {row.weight} {row.weightUnit}
      </Typography>
    )
  }
  if (dataKey === 'publishedPrice') {
    return (
      <Typography>
        {row.publishedPrice} {row.publishedPriceCurrency}
      </Typography>
    )
  }
  if (dataKey === 'pickupDate') {
    return <Typography>{dayjs(row.stops[0].date).format(appConfig.DATE_FORMAT)}</Typography>
  }
  if (dataKey === 'deliveryDate') {
    return (
      <Typography>
        {dayjs(row.stops[row.stops.length - 1].date).format(appConfig.DATE_FORMAT)}
      </Typography>
    )
  }
  if (dataKey === 'pickupLocation') {
    return (
      <div className={styles.containerLocation}>
        <CountryFlag countryName={row.stops[0].countryAbbreviation} />
        <Typography>{`${row.stops[0].countryAbbreviation} ${row.stops[0].city}`}</Typography>
      </div>
    )
  }
  if (dataKey === 'deliveryLocation') {
    return (
      <div className={styles.containerLocation}>
        <CountryFlag countryName={row.stops[row.stops.length - 1].countryAbbreviation} />
        <Typography>{`${row.stops[row.stops.length - 1].countryAbbreviation} ${row.stops[row.stops.length - 1].city}`}</Typography>
      </div>
    )
  }

  return <Typography>{row[dataKey as keyof ILoad] as string}</Typography>
}
