import { SyntheticEvent } from 'react'
import useOwnUser from '@hooks/useOwnUser'
import { IUser } from '@pkg/users/models'
import { t } from 'i18next'
import { useSearchUserQuery } from '@features/users/usersApiSlice'
import { Autocomplete, Skeleton, TextField, MenuItem, Avatar } from '@mui/material'

interface EmployeeSelectProps {
  value: string | undefined
  onChange: (e: SyntheticEvent<Element, Event>, option: IEmployeeSelectOption | null) => void
}

const EmployeeSelect = ({ value, onChange }: EmployeeSelectProps) => {
  const { id: ownId, companyId: ownCompanyId } = useOwnUser()

  const { data: employees } = useSearchUserQuery(
    { companyId: ownCompanyId, pageNumber: 1, pageSize: 100 },
    { skip: !ownCompanyId },
  )

  if (!employees) {
    return <Skeleton height={60} />
  }

  return (
    <Autocomplete
      disablePortal
      value={parseEmployees(employees, ownId).find((option) => option.value === value) ?? null}
      onChange={onChange}
      options={parseEmployees(employees, ownId)}
      renderOption={(props, option) => {
        return (
          <MenuItem
            {...props}
            key={option.value}
          >
            <Avatar sx={{ mr: 1 }} /> {option.label}
          </MenuItem>
        )
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t('Common:coworker')}
        />
      )}
    />
  )
}

export default EmployeeSelect

interface IEmployeeSelectOption {
  label: string
  value: string
}

const parseEmployees = (employees: IUser[], ownId: string): IEmployeeSelectOption[] => {
  return employees
    .filter((employee) => {
      return employee.id !== ownId
    })
    .map((employee) => ({
      value: employee.id,
      label: `${employee.firstName} ${employee.lastName}`,
    }))
}
