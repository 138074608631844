import { useSelector } from 'react-redux'
import { selectCurrentRoles } from './authSlice'

interface RequireRoleProps {
  allowedRoles: string[]
  children: React.ReactNode
}

const RequireRole: React.FC<RequireRoleProps> = ({ allowedRoles, children }) => {
  const roles: string[] | undefined = useSelector(selectCurrentRoles)

  if (roles === undefined) return null

  return roles.find((role: string) => allowedRoles?.includes(role)) || roles.includes('admin') ?
      <>{children}</>
    : null
}

export default RequireRole
