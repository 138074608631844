import {
  useState,
  Suspense,
  lazy,
  LazyExoticComponent,
  useEffect,
  useCallback,
  MutableRefObject,
} from 'react'
import { t } from 'i18next'
import { useUpdateLoadMutation } from '@features/loads/loadsApiSlice'
import { ISideDialogChildProps, ISideDialogRef } from '@lib/SideDialog'
import useAlert from '@hooks/useAlert'
import { createUpdateLoadRequest } from '@pkg/loads/converters'
import RequireRole from '@features/auth/RequireRole'
import { parseError, parsePhoneNumber } from '@shared/utils/formatters'
import { CreateFromTemplate, InvoiceDetails } from '@pkg/documents/requests'
import { convertCompanyToInvoiceDetails } from '@pkg/documents/utils'
import styles from './detailsDialog.module.scss'
import useOwnUser from '@hooks/useOwnUser'
import { useGetCompaniesQuery } from '@features/companies/companiesApiSlice'
import {
  useCreateDocumentMutation,
  useGetDocumentContentMutation,
} from '@features/documents/documentsApiSlice'

import { Tooltip, IconButton, Button, TextField, MenuItem, useTheme } from '@mui/material'

import HomeIcon from '@mui/icons-material/Home'
import EditIcon from '@mui/icons-material/Edit'
import HistoryIcon from '@mui/icons-material/History'
import NoteAddIcon from '@mui/icons-material/NoteAdd'
import TrackChangesIcon from '@mui/icons-material/TrackChanges'
import { ICompany } from '@pkg/companies/models'
import { ITheme } from '@pkg/sokarUI'
import ArchiveIcon from '@mui/icons-material/Archive'
import { IConfirmationDialogRef } from '@lib/ConfirmationDialog'

interface DialogProps extends ISideDialogChildProps {
  elementRef: MutableRefObject<ISideDialogRef | null>
  confirmationRef: MutableRefObject<IConfirmationDialogRef | null>
  refetch: () => void
  handleClose: () => void
}

type dialogElements = 'info' | 'history' | 'edit'

const DetailsDialog: React.FC<DialogProps> = ({ ...props }) => {
  const appTheme: ITheme = useTheme()
  const [updateLoad] = useUpdateLoadMutation()
  const { companyId: ownCompanyId } = useOwnUser()
  const [loadId, setLoadId] = useState<string>('')
  const [generateDocument] = useCreateDocumentMutation()
  const [getDocumentsContent] = useGetDocumentContentMutation()
  const [elementKey, setElementKey] = useState<dialogElements>('info')
  const { dispatch: dispatchAlert } = useAlert()

  const handleStatusChange = () => {
    let loadData = createUpdateLoadRequest(props.data)
    const handleChange = () => {
      updateLoad(loadData)
        .unwrap()
        .then(() => {
          dispatchAlert({
            type: 'SHOW',
            payload: {
              content: t('Accounting:responses.transportStatusUpdated'),
              severity: 'success',
            },
          })
        })
        .then(() => props.confirmationRef.current?.close())
        .then(() => props.handleClose())
        .then(() => props.refetch())
        .catch((err: any) => {
          const error = parseError<any>(err)
          dispatchAlert({
            type: 'SHOW',
            payload: {
              content: String(t(error.dictKey, { ...error.dependencies })),
              severity: 'error',
            },
          })
          props.confirmationRef.current?.close()
        })
    }

    props.confirmationRef.current?.open({
      title: t('Market:offerDialog.changeStatus'),
      text: (
        <TextField
          select
          defaultValue={loadData.invoiceStatus}
          className={styles.select}
          onChange={(e) => {
            loadData.invoiceStatus = e.target.value
          }}
        >
          <MenuItem value={'ready'}>{t('Accounting:statuses.ready')}</MenuItem>
          <MenuItem value={'awaiting-rev-rc'}>{t('Accounting:statuses.awaiting-rev-rc')}</MenuItem>
          <MenuItem value={'awaiting-better-paperwork'}>
            {t('Accounting:statuses.awaiting-better-paperwork')}
          </MenuItem>
          <MenuItem value={'invoiced'}>{t('Accounting:statuses.invoiced')}</MenuItem>
        </TextField>
      ),
      actions: [
        <Button onClick={() => props.confirmationRef.current?.close()}>
          {t('Common:cancel')}
        </Button>,
        <Button onClick={() => handleChange()}>{t('Common:confirm')}</Button>,
      ],
    })
  }

  // DO NOT TOUCH!!! I hate JavaScript
  const [buyerState, setBuyerState] = useState<ICompany[] | undefined>(undefined)
  const { data: buyer } = useGetCompaniesQuery({ id: props.data.shipper.id, page: 0, pageSize: 1 })
  useEffect(() => {
    setBuyerState(buyer)
  }, [buyer])

  const { firstName, lastName, phone } = useOwnUser()

  const generateInvoice = useCallback(() => {
    if (!buyerState || buyerState.length != 1) {
      return
    }

    let data: Partial<CreateFromTemplate> = {
      templateId: 'c5c8155f-fbad-4cf2-9bac-8dd3a526cafc',
      category: 'invoice',
      loadId: [props.data.id],
      companyId: [ownCompanyId],
    }
    data.invoiceDetails = {
      services: props.data.services,
      ...data.invoiceDetails,
      ...convertCompanyToInvoiceDetails(buyerState[0]),
      issuerFirstName: firstName,
      issuerLastName: lastName,
      phone: parsePhoneNumber(phone),
    } as InvoiceDetails

    dispatchAlert({
      type: 'SHOW',
      payload: {
        content: t('Accounting:responses.startingFileGeneration'),
        severity: 'info',
      },
    })

    generateDocument(data as CreateFromTemplate)
      .unwrap()
      .then((res) => {
        getDocumentsContent(res.id)
          .unwrap()
          .then((content) => {
            fetch('data:application/pdf;base64,' + content.content)
              .then((res) => res.blob())
              .then((file) => {
                const url = URL.createObjectURL(file)
                const a = document.createElement('a')
                a.href = url
                a.download = res.id
                a.style.display = 'none'

                document.body.appendChild(a)
                a.click()

                document.body.removeChild(a)
                URL.revokeObjectURL(url)
              })
              .catch(() => {
                dispatchAlert({
                  type: 'SHOW',
                  payload: {
                    content: t('Accounting:responses.failedToDownload'),
                    severity: 'error',
                  },
                })
              })
              .then(() => {
                dispatchAlert({
                  type: 'SHOW',
                  payload: {
                    content: String(t('Accounting:responses.fileDownloaded')),
                    severity: 'success',
                  },
                })
              })
              .then(() => {
                props.refetch()
              })
          })
      })
      .then(() => {
        let loadData = createUpdateLoadRequest(props.data)
        loadData.invoiceStatus = 'invoiced'
        updateLoad(loadData)
      })
      .then(() => {
        props.handleClose()
      })
      .then(() => {
        props.refetch()
      })
      .catch((err) => {
        const error = parseError<any>(err)
        dispatchAlert({
          type: 'SHOW',
          payload: {
            content: String(t(error.dictKey, { ...error.dependencies })),
            severity: 'error',
          },
        })
      })
      .then(() => {
        dispatchAlert({
          type: 'SHOW',
          payload: {
            content: t('Accounting:responses.invoiceGenerated'),
            severity: 'success',
          },
        })
      })
  }, [buyerState])

  const handleArchiveLoad = () => {
    const handleArchive = () => {
      if (!props.data) {
        return
      }
      let loadData = createUpdateLoadRequest(props.data)
      loadData.archived = true
      updateLoad(loadData)
        .unwrap()
        .then(() => {
          dispatchAlert({
            type: 'SHOW',
            payload: {
              content: t('Fleet:responses.loadUpdated'),
              severity: 'success',
            },
          })
        })
        .then(() => props.confirmationRef.current?.close())
        .then(() => props.handleClose())
        .then(() => props.refetch())
        .catch((err: any) => {
          const error = parseError<any>(err.message)
          dispatchAlert({
            type: 'SHOW',
            payload: {
              content: String(t(error.dictKey, { ...error.dependencies })),
              severity: 'error',
            },
          })
          props.confirmationRef.current?.close()
        })
    }

    props.confirmationRef.current?.open({
      title: t('Fleet:confirmationDialog.archiveLoadTitle'),
      text: t('Fleet:confirmationDialog.archiveLoadText'),
      actions: [
        <Button onClick={() => props.confirmationRef.current?.close()}>{t('Common:no')}</Button>,
        <Button onClick={() => handleArchive()}>{t('Common:yes')}</Button>,
      ],
    })
  }

  const dialogActions = (
    <>
      <Tooltip
        title={t('Accounting:loadDialog.info')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => setElementKey('info')}
        >
          <HomeIcon />
        </IconButton>
      </Tooltip>
      <RequireRole allowedRoles={['accountant']}>
        <Tooltip
          title={t('Accounting:loadDialog.changeStatus')}
          placement={'left'}
          arrow={true}
        >
          <IconButton
            sx={{ color: appTheme.palette.text.light }}
            onClick={() => handleStatusChange()}
          >
            <TrackChangesIcon />
          </IconButton>
        </Tooltip>
      </RequireRole>
      <RequireRole allowedRoles={['accountant']}>
        <Tooltip
          title={t('Accounting:loadDialog.edit')}
          placement={'left'}
          arrow={true}
        >
          <IconButton
            sx={{ color: appTheme.palette.text.light }}
            onClick={() => setElementKey('edit')}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
      </RequireRole>
      <RequireRole allowedRoles={['accountant']}>
        <Tooltip
          title={t('Accounting:loadDialog.generateInvoice')}
          placement={'left'}
          arrow={true}
        >
          <IconButton
            sx={{ color: appTheme.palette.text.light }}
            onClick={() => generateInvoice()}
          >
            <NoteAddIcon />
          </IconButton>
        </Tooltip>
      </RequireRole>
      <Tooltip
        title={t('Accounting:loadDialog.history')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          disabled
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => setElementKey('history')}
        >
          <HistoryIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('Fleet:loadDialog.archive')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => handleArchiveLoad()}
        >
          <ArchiveIcon />
        </IconButton>
      </Tooltip>
    </>
  )

  const getProperElement = (key: dialogElements): LazyExoticComponent<React.ComponentType<any>> => {
    switch (key) {
      case 'edit':
        return lazy(() => import('@app/fleet/views/CurrentLoads/components/LoadDialog/edit'))
      // case 'history':
      //     return lazy(() => import('./history'))
      default:
        return lazy(() => import('./info'))
    }
  }

  useEffect(() => {
    if (!!props.data) setLoadId(props.data.id)
  }, [props.data])

  useEffect(() => {
    props.elementRef.current?.setActions(dialogActions)
  }, [loadId, buyer, buyerState])

  const ProperComponent = getProperElement(elementKey)

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <ProperComponent
        data={props.data}
        handleClose={props.handleClose}
      />
    </Suspense>
  )
}

export default DetailsDialog
