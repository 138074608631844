import { useState, forwardRef, useImperativeHandle, ElementRef } from 'react'

import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'

export interface IConfirmationDialogRef {
  open: (_options: any) => void
  close: () => void
}

export interface IConfirmationDialogOptions {
  title: string
  text: string
  actions: JSX.Element[]
}

const ConfirmationDialog = forwardRef<IConfirmationDialogRef, any>(({ ...props }, ref) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [options, setOptions] = useState<IConfirmationDialogOptions>()

  useImperativeHandle(ref, () => ({
    open(_options) {
      if (!_options) return
      setIsOpen(true)
      setOptions(_options)
    },
    close() {
      setIsOpen(false)
    },
  }))

  if (!options) return <></>

  return (
    <Dialog
      open={isOpen}
      maxWidth={'sm'}
      fullWidth
    >
      <DialogTitle>{options.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{options.text}</DialogContentText>
      </DialogContent>
      <DialogActions>{options.actions}</DialogActions>
    </Dialog>
  )
})

export default ConfirmationDialog
