import { HelpCenterOutlined } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { useTour } from '@reactour/tour'

/**
 * The `OnboardingButton` component sets tour steps based on the current location
 * and starts a onboarding tour when clicked.
 */
export const OnboardingButton = () => {
  const { setIsOpen } = useTour()

  return (
    <IconButton
      onClick={() => {
        setIsOpen(true)
      }}
    >
      <HelpCenterOutlined />
    </IconButton>
  )
}
