import { useState, FC, SyntheticEvent, ChangeEvent } from 'react'
import { t } from 'i18next'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import useOwnUser from '@hooks/useOwnUser'
import useAlert from '@hooks/useAlert'
import {
  useCreateTrailerMutation,
  useGetTrailerCategoriesQuery,
} from '@features/equipment/equipmentApiSlice'

import { IAutocompleteOption, ITheme, ICreateFleetObjectDialogProps } from '@pkg/sokarUI'
import { initICreateTrailerRequest } from '@pkg/equipment/initializers'
import { ICreateTrailerRequest } from '@pkg/equipment/requests'
import { trailerValidator } from '@pkg/equipment/validators'
import { parseError } from '@shared/utils/formatters'

import CompanySearch from '@lib/CompanySearch'

import {
  Box,
  Paper,
  Stack,
  Button,
  Checkbox,
  TextField,
  Typography,
  Autocomplete,
  useTheme,
} from '@mui/material'

const CreateTrailerDialog: FC<ICreateFleetObjectDialogProps> = ({ ...props }) => {
  const appTheme: ITheme = useTheme()
  const defaultValues = initICreateTrailerRequest()
  const { data: trailerTypes } = useGetTrailerCategoriesQuery(null)
  const options: IAutocompleteOption<string>[] = trailerTypes
    ? trailerTypes.map((key) => {
        return {
          label: t('Fleet:equipment.' + key),
          value: key,
        }
      })
    : []
  const { companyId } = useOwnUser()
  const [createTrailer] = useCreateTrailerMutation()
  const { dispatch: dispatchAlert } = useAlert()

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<ICreateTrailerRequest>({
    resolver: yupResolver(trailerValidator),
  })

  const onSubmit = (trailerData: ICreateTrailerRequest): void => {
    trailerData.companyId = companyId ?? ''
    if (!trailerData.isCarrierOwned) delete trailerData.carrierId
    createTrailer(trailerData)
      .unwrap()
      .then(() => {
        dispatchAlert({
          type: 'SHOW',
          payload: {
            content: t('Fleet:responses.trailerCreated'),
            severity: 'success',
          },
        })
      })
      .then(() => reset(defaultValues))
      .then(() => props.refetch())
      .then(() => props.handleClose(false))
      .catch((err: any) => {
        const error = parseError<any>(err.data.message)
        dispatchAlert({
          type: 'SHOW',
          payload: {
            content: String(t(error.dictKey, { ...error.dependencies })),
            severity: 'error',
          },
        })
      })
  }

  const [isCarrierSelectDisabled, setIsCarrierSelectDisabled] = useState<boolean>(true)
  const handleIsCarrierOwnedChange = (
    value: boolean,
    onChange: (...event: any[]) => void,
    e: ChangeEvent<HTMLInputElement>,
  ): void => {
    setIsCarrierSelectDisabled(!value)
    onChange(e)
  }

  return (
    <Stack
      component={'form'}
      onSubmit={handleSubmit(onSubmit)}
      direction={'column'}
      spacing={3}
    >
      <Controller
        control={control}
        name={'plate'}
        render={({ field }) => (
          <TextField
            {...field}
            label={t('Fleet:createTrailerDialog.plates')}
            error={!!errors?.plate}
            helperText={<Box height={2}>{errors?.plate?.message}</Box>}
          />
        )}
      />
      <Controller
        control={control}
        name={'category'}
        render={({ field }) => (
          <Autocomplete
            {...field}
            disablePortal
            options={options}
            disabled={options.length === 0}
            value={options.find(({ value }) => value === field.value)}
            onChange={(
              e: SyntheticEvent<Element, Event>,
              option: IAutocompleteOption<string> | null,
            ) => {
              if (option != null) field.onChange(option.value)
            }}
            PaperComponent={(params) => (
              <Paper
                {...params}
                sx={{ backgroundColor: appTheme.palette.background.default }}
              />
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                error={!!errors?.category}
                helperText={<Box height={2}>{errors?.category?.message}</Box>}
                label={t('Fleet:createTrailerDialog.type')}
              />
            )}
          />
        )}
      />
      <Controller
        control={control}
        name={'vin'}
        render={({ field }) => (
          <TextField
            {...field}
            label={'VIN'}
            error={!!errors?.vin}
            helperText={<Box height={2}>{errors?.vin?.message}</Box>}
          />
        )}
      />
      <Controller
        control={control}
        name={'make'}
        render={({ field }) => (
          <TextField
            {...field}
            label={t('Fleet:createTrailerDialog.make')}
            error={!!errors?.make}
            helperText={<Box height={2}>{errors?.make?.message}</Box>}
          />
        )}
      />
      <Controller
        control={control}
        name={'model'}
        render={({ field }) => (
          <TextField
            {...field}
            label={t('Fleet:createTrailerDialog.model')}
            error={!!errors?.model}
            helperText={<Box height={2}>{errors?.model?.message}</Box>}
          />
        )}
      />
      <Controller
        control={control}
        name={'year'}
        render={({ field }) => (
          <TextField
            {...field}
            type={'number'}
            label={t('Fleet:createTrailerDialog.year')}
            error={!!errors?.year}
            helperText={<Box height={2}>{errors?.year?.message}</Box>}
          />
        )}
      />
      <Controller
        control={control}
        name={'number'}
        render={({ field }) => (
          <TextField
            {...field}
            label={t('Fleet:createTrailerDialog.number')}
          />
        )}
      />
      <Stack
        spacing={0}
        direction={'row'}
        alignItems={'center'}
      >
        <Typography>{t('Fleet:createTrailerDialog.isCarrierOwned')}:</Typography>
        <Controller
          control={control}
          name={'isCarrierOwned'}
          render={({ field }) => (
            <Checkbox
              value={field.value}
              onChange={(e) => handleIsCarrierOwnedChange(e.target.checked, field.onChange, e)}
            />
          )}
        />
      </Stack>
      <Controller
        control={control}
        name={'carrierId'}
        render={({ field }) => (
          <CompanySearch
            {...field}
            error={errors?.carrierId}
            isDisabled={isCarrierSelectDisabled}
          />
        )}
      />
      <Button
        type={'submit'}
        variant={'contained'}
      >
        {t('Common:create')}
      </Button>
    </Stack>
  )
}

export default CreateTrailerDialog
