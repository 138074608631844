import { Box, Stack, TextField, Typography } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import InputLabel from '@mui/material/InputLabel'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { forwardRef, useImperativeHandle, useRef } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import SideDialog, { ISideDialogRef } from '@/lib/SideDialog'
import { IFormattedMarket } from '@/pkg/market/models'

export interface IMarketDialogRef {
  open: (isOpen: boolean, data: IFormattedMarket) => void
}

export interface IMarketProps {
  title: string
}

const MarketLoadDialog = forwardRef<IMarketDialogRef, IMarketProps>(({ title }, ref) => {
  const { t } = useTranslation()
  const marketDialogRef = useRef<ISideDialogRef>(null)

  const { control, reset } = useForm<IFormattedMarket>()

  const { fields: stops } = useFieldArray({
    control,
    name: 'stops',
  })

  useImperativeHandle(ref, () => ({
    open(isOpen, _data?: IFormattedMarket) {
      reset()
      if (_data) {
        reset(_data)
      }

      if (!isOpen) {
        marketDialogRef.current?.close()
      } else {
        marketDialogRef.current?.open(_data ?? true)
      }
    },
  }))

  const marketLoadForm = () => {
    return (
      <Stack
        component={'form'}
        onSubmit={() => {}}
        direction={'column'}
        spacing={3}
      >
        <>
          {stops.map((_field, index: number) => (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Box key={_field.id}>
                <Stack
                  direction={'row'}
                  spacing={2}
                >
                  <Typography>{t('Market:dialog.stopTitle', { noStop: index + 1 })}</Typography>
                  <span className={'create_load_dialog_text_field'} />
                </Stack>

                <span className='market-dialog row'>
                  <Controller
                    control={control}
                    name={`stops.${index}.city`}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={t('Market:dialog.id')}
                        disabled
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name={`stops.${index}.countryId`}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={t('Market:dialog.countryId')}
                        disabled
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name={`stops.${index}.postalCode`}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={t('Market:dialog.postalCode')}
                        disabled
                      />
                    )}
                  />
                </span>
              </Box>
            </LocalizationProvider>
          ))}
          <Typography>{t('Market:dialog.details')}</Typography>
          <span className='market-dialog row'>
            <Controller
              control={control}
              name={'id'}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t('Market:dialog.id')}
                  disabled
                />
              )}
            />
            <Controller
              control={control}
              name={'externalProvider'}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t('Market:dialog.externalProvider')}
                  disabled
                />
              )}
            />
          </span>
          <span className='market-dialog row'>
            <Controller
              control={control}
              name={'rateValue'}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t('Market:dialog.rateValue')}
                  disabled
                />
              )}
            />
            <Controller
              control={control}
              name={'rateCurrencyType'}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t('Market:dialog.rateCurrencyType')}
                  disabled
                />
              )}
            />
          </span>

          <span className='market-dialog row'>
            <Controller
              control={control}
              name={'mode'}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t('Market:dialog.mode')}
                  disabled
                />
              )}
            />
            <Controller
              control={control}
              name={'equipmentType'}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t('Market:dialog.equipmentType')}
                  disabled
                />
              )}
            />
          </span>

          <span className='market-dialog row'>
            <Controller
              control={control}
              name={'equipmentHeight.value'}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t('Market:dialog.equipmentHeight.value')}
                  disabled
                />
              )}
            />
            <Controller
              control={control}
              name={'equipmentHeight.unit'}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t('Market:dialog.equipmentHeight.unit')}
                  disabled
                />
              )}
            />
          </span>
          <span className='market-dialog row'>
            <Controller
              control={control}
              name={'equipmentLength.value'}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t('Market:dialog.equipmentLength.value')}
                  disabled
                />
              )}
            />
            <Controller
              control={control}
              name={'equipmentLength.unit'}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t('Market:dialog.equipmentLength.unit')}
                  disabled
                />
              )}
            />
          </span>
          <span className='market-dialog row'>
            <Controller
              control={control}
              name={'equipmentWidth.value'}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t('Market:dialog.equipmentWidth.value')}
                  disabled
                />
              )}
            />
            <Controller
              control={control}
              name={'equipmentWidth.unit'}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t('Market:dialog.equipmentWidth.unit')}
                  disabled
                />
              )}
            />
          </span>
          <span className='market-dialog row'>
            <Controller
              control={control}
              name={'loadDistance.value'}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t('Market:dialog.loadDistance.value')}
                  disabled
                />
              )}
            />
            <Controller
              control={control}
              name={'loadDistance.unit'}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t('Market:dialog.loadDistance.unit')}
                  disabled
                />
              )}
            />
          </span>
          <span className='market-dialog row'>
            <Controller
              control={control}
              name={'weight.value'}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t('Market:dialog.weight.value')}
                  disabled
                />
              )}
            />
            <Controller
              control={control}
              name={'weight.unit'}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t('Market:dialog.weight.unit')}
                  disabled
                />
              )}
            />
          </span>
          <span className='market-dialog row'>
            <Controller
              control={control}
              name={'preCoolTemperature.value'}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t('Market:dialog.preCoolTemperature.value')}
                  disabled
                />
              )}
            />
            <Controller
              control={control}
              name={'preCoolTemperature.unit'}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t('Market:dialog.preCoolTemperature.unit')}
                  disabled
                />
              )}
            />
          </span>
          <span className='market-dialog row'>
            <Controller
              control={control}
              name={'maximumTemperature.value'}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t('Market:dialog.maximumTemperature.value')}
                  disabled
                />
              )}
            />
            <Controller
              control={control}
              name={'maximumTemperature.unit'}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t('Market:dialog.maximumTemperature.unit')}
                  disabled
                />
              )}
            />
          </span>
          <span className='market-dialog row'>
            <Controller
              control={control}
              name={'minimumTemperature.value'}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t('Market:dialog.minimumTemperature.value')}
                  disabled
                />
              )}
            />
            <Controller
              control={control}
              name={'minimumTemperature.unit'}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t('Market:dialog.minimumTemperature.unit')}
                  disabled
                />
              )}
            />
          </span>
          <span className='market-dialog row'>
            <Controller
              control={control}
              name={'airRide'}
              render={({ field: { value } }) => (
                <span className='market-dialog checkbox'>
                  <InputLabel>{t('Market:dialog.airRide')}</InputLabel>
                  <Checkbox
                    checked={value}
                    disabled
                  />
                </span>
              )}
            />
            <Controller
              control={control}
              name={'seal'}
              render={({ field: { value } }) => (
                <span className='market-dialog checkbox'>
                  <InputLabel>{t('Market:dialog.seal')}</InputLabel>
                  <Checkbox
                    checked={value}
                    disabled
                  />
                </span>
              )}
            />
            <Controller
              control={control}
              name={'foodGrade'}
              render={({ field: { value } }) => (
                <span className='market-dialog checkbox'>
                  <InputLabel>{t('Market:dialog.foodGrade')}</InputLabel>
                  <Checkbox
                    checked={value}
                    disabled
                  />
                </span>
              )}
            />
          </span>
        </>
      </Stack>
    )
  }

  return (
    <SideDialog
      ref={marketDialogRef}
      title={title}
    >
      {marketLoadForm()}
    </SideDialog>
  )
})

export default MarketLoadDialog

MarketLoadDialog.displayName = 'MarketLoadDialog'
