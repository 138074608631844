import { assert } from '@shared/utils/utilities'
import { ObjectSchema } from 'yup'

import { euMyMarketOffersValidator } from './euValidators'
import { usMyMarketOffersValidator } from './usValidators'

/**
 * Gets the validation schema for market offers based on the specified region
 *
 * @param {string} region - The region for which to get the market offers validator.
 * Should be 'EU' for European market offers or any other string for US market offers
 * @returns {ObjectSchema<any>} The Yup validation schema for market offers
 * This schema validates the structure of market offer objects
 *
 * @throws {Error} Will throw an error if the region is an empty string
 *
 */
export const getMyMarketOffersValidator = (region: string): ObjectSchema<any> => {
  // TODO: figure out how to type it
  assert(region !== '', 'Region cannot be an empty string')
  if (region === 'EU') {
    return euMyMarketOffersValidator
  }

  return usMyMarketOffersValidator
}
