import { DocumentUpdate, Additional } from './requests'

export const initDocumentUpdate = (): DocumentUpdate => {
  return {
    id: '',
    name: '',
    category: '',
    expiresAt: '',
    issuedAt: '',
  }
}

export const initInvoiceAdditional = (): Additional => {
  return {
    name: '',
    bruttoPrice: 0,
    note: '',
  }
}
