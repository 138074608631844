import dayjs from 'dayjs'
import { ITheme } from '@pkg/sokarUI'
import { IStop } from '@pkg/loads/models'
import { useTranslation } from 'react-i18next'
import { useGetUserQuery } from '@features/users/usersApiSlice'
import { parsePhoneNumber } from '@shared/utils/formatters'
import styles from './offerDialog.module.scss'
import appConfig from '@/config'

import {
  Box,
  Stack,
  TableContainer,
  Table,
  Paper,
  TableRow,
  TableCell,
  useTheme,
  Skeleton,
  TableBody,
  Typography,
  Avatar,
} from '@mui/material'

const ClientOfferDialog = ({ ...props }): JSX.Element => {
  const { t } = useTranslation()
  const appTheme: ITheme = useTheme()

  const { data: owner } = useGetUserQuery(props.data?.owner?.id, { skip: !props.data?.owner?.id })

  return (
    <Stack
      direction={'column'}
      spacing={2}
    >
      <TableContainer
        component={Paper}
        sx={{ backgroundColor: appTheme.palette.background.default }}
      >
        <Table>
          <TableBody>
            <TableRow>
              <TableCell variant={'head'}>{t('Market:clientOfferDialog.shipper')}</TableCell>
              <TableCell>{props.data?.shipper?.name ?? ''}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant={'head'}>{t('Market:clientOfferDialog.price')}</TableCell>
              <TableCell>
                {props.data?.publishedPrice ?? ''} {props.data?.publishedPriceCurrency ?? ''}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Table>
                  <TableBody>
                    {props.data.stops.map((stop: IStop, index: number) => (
                      <>
                        <TableRow key={index}>
                          <TableCell variant={'head'}>
                            {stop.countryAbbreviation} {stop.city}
                          </TableCell>
                          <TableCell>{dayjs(stop.date).format(appConfig.DATE_FORMAT)}</TableCell>
                        </TableRow>
                      </>
                    ))}
                  </TableBody>
                </Table>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Box>
        <Stack
          direction={'row'}
          spacing={2}
        >
          <Avatar className={styles.avatar} />
          <Stack
            direction={'column'}
            spacing={2}
          >
            {owner ? (
              <>
                <Typography>
                  {owner.firstName} {owner.lastName}
                </Typography>
                <Typography>{parsePhoneNumber(owner.phone)}</Typography>
                <Typography>{owner.email}</Typography>
              </>
            ) : (
              <>
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </>
            )}
          </Stack>
        </Stack>
      </Box>
    </Stack>
  )
}

export default ClientOfferDialog
