import { LatLngTuple } from 'leaflet'

export const deg2rad = (deg: number) => {
  return deg * (Math.PI / 180)
}

export const getDistanceFromLatLonInKm = (p1?: LatLngTuple, p2?: LatLngTuple) => {
  if (!p1 || !p2) return 8000
  const [lat1, lon1] = p1
  const [lat2, lon2] = p2
  const R = 6371
  const dLat = deg2rad(lat2 - lat1)
  const dLon = deg2rad(lon2 - lon1)
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2)

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  return R * c
}

export const zoomSwitch = (d: number) => {
  switch (true) {
    case d > 10000:
      return 1
    case d <= 10000 && d > 8000:
      return 2
    case d <= 8000 && d > 6000:
      return 3
    case d <= 6000 && d > 4000:
      return 4
    case d <= 4000 && d > 2000:
      return 5
    default:
      return 6
  }
}

export const mil2km = (d: number) => {
  return d * 1.60934
}

export const km2mil = (d: number) => {
  return d * 0.62137
}
