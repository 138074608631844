/* eslint-disable sonarjs/no-duplicate-string */
import { IExternalLoad, IMarket } from '@pkg/market/models'
import { IAddSubscriptionRequest } from '@pkg/market/requests'
import { ISliceBuilder } from '@pkg/types'
import { apiSlice } from 'src/api/apiSlice'

export const marketApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder: ISliceBuilder) => ({
    getMarket: builder.query<IMarket[], undefined>({
      query: () => ({
        url: '/load-subscriber',
      }),
      transformResponse: (response: { subscriptions: IMarket[] }) => response.subscriptions,
      providesTags: ['subscriptions'],
    }),
    addSubscription: builder.mutation<IMarket, IAddSubscriptionRequest>({
      query: (subscription: IAddSubscriptionRequest) => ({
        url: '/load-subscriber',
        method: 'POST',
        body: subscription,
      }),
      transformResponse: (response: { subscriptions: IMarket }) => response.subscriptions,
      invalidatesTags: ['subscriptions'],
    }),
    updateSubscriber: builder.mutation<IMarket, IAddSubscriptionRequest>({
      query: (subscription: IAddSubscriptionRequest) => ({
        url: '/load-subscriber',
        method: 'PUT',
        body: subscription,
      }),
      transformResponse: (response: { subscriptions: IMarket }) => response.subscriptions,
      invalidatesTags: ['subscriptions', 'subscriptionsExternal'],
    }),
    deleteSubscriber: builder.mutation<string, string>({
      query: (id: string) => ({
        url: '/load-subscriber',
        method: 'DELETE',
        params: { id },
      }),
      invalidatesTags: ['subscriptions'],
    }),
    getMarketExternal: builder.query<IExternalLoad[], string | undefined>({
      query: (subscriptionId: string) => ({
        url: '/load-subscriber/external-loads',
        params: { subscriptionId },
      }),
      transformResponse: (response: { loads: IExternalLoad[] }) => response.loads,
      providesTags: ['subscriptionsExternal'],
    }),
  }),
})

export const {
  useGetMarketQuery,
  useAddSubscriptionMutation,
  useUpdateSubscriberMutation,
  useDeleteSubscriberMutation,
  useGetMarketExternalQuery,
} = marketApiSlice
