import { useState, useEffect } from 'react'
import { t } from 'i18next'
import { useGetAvailablePublishersQuery } from '@features/publisher/publisherApiSlice'

import { FormControl, InputLabel, Select, MenuItem, Skeleton } from '@mui/material'

interface IntegrationsFieldProps {
  value: string[]
  onChange: (arg: string[]) => void
}

const IntegrationsField = ({ value, onChange }: IntegrationsFieldProps): JSX.Element => {
  const { data: integrations, isFetching: isIntegrationsFetching } =
    useGetAvailablePublishersQuery()

  const [selectedIntegrations, setSelectedIntegrations] = useState<string[]>([])
  const handleSelection = (val: string | string[]): void => {
    setSelectedIntegrations(() => {
      if (typeof val == 'string') {
        onChange(val.split(','))
        return val.split(',')
      }
      onChange(val)
      return val
    })
  }

  useEffect((): void => {
    if (value) {
      setSelectedIntegrations(value)
    }
  }, [value])

  if (isIntegrationsFetching || !integrations) {
    return <Skeleton />
  }

  return (
    <FormControl>
      <InputLabel id={'integrationsFieldSelect'}>{t('Market:integrations.label')}</InputLabel>
      <Select
        multiple
        value={selectedIntegrations}
        labelId={'integrationsFieldSelect'}
        label={t('Market:integrations.label')}
        onChange={(e) => handleSelection(e.target.value)}
      >
        {Object.entries(integrations).map(([key, val]) => (
          <MenuItem
            value={key}
            disabled={!val}
            key={key}
          >
            {key} {!val && `(${t('Market:integrations.needsConfiguration')})`}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default IntegrationsField
