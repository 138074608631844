const useCustomerID = (): string => {
  const URL = window.location.href

  if (URL.includes('localhost')) return 'dev'
  if (URL.slice(0, URL.indexOf('.')).replace('https://', '') === 'sokar') {
    throw Error('Invalid URL provided')
  }

  return URL.slice(0, URL.indexOf('.')).replace('https://', '')
}

export default useCustomerID
