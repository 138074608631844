import { Route, Routes } from 'react-router-dom'
import GenerateSettlement from './views/GenerateSettlement'
import TransportsToInvoice from './views/TransportsToInvoice'
import InvoicedTransports from './views/InvoicedTransports'

import RequireAuth from '@features/auth/RequireAuth'

const Accounting = () => {
  return (
    <Routes>
      <Route element={<RequireAuth allowedRoles={['accountant']} />}>
        <Route
          path={'/generate-settlement'}
          element={<GenerateSettlement />}
        />
      </Route>
      <Route element={<RequireAuth />}>
        <Route
          path={'/invoiced'}
          element={<InvoicedTransports />}
        />
      </Route>
      <Route element={<RequireAuth />}>
        <Route
          path={'/to-invoice'}
          element={<TransportsToInvoice />}
        />
      </Route>
    </Routes>
  )
}

export default Accounting
