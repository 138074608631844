import { t } from 'i18next'
import { ITableColumn } from '@pkg/sokarUI'
import { ITruck } from '@pkg/equipment/models'
import { getFileAlertColour } from '@shared/utils/formatters'
import { SvgIconProps, Tooltip, Typography } from '@mui/material'

import UsedByCell from './components/usedByCell'

import WarningIcon from '@mui/icons-material/Warning'

export const trucksTableColumns: ITableColumn[] = [
  {
    label: t('Fleet:trucksTable.truckNumber'),
    dataKey: 'number',
    isSortable: false,
  },
  {
    label: t('Fleet:trucksTable.plates'),
    dataKey: 'plate',
    isSortable: true,
  },
  {
    label: t('Fleet:trucksTable.ownership'),
    dataKey: 'ownership',
    isSortable: false,
  },
  {
    label: t('Fleet:trucksTable.usedBy'),
    dataKey: 'usedBy',
    isSortable: false,
  },
]

export const trucksTableCellRenderer = (column: ITableColumn, row: ITruck): JSX.Element => {
  if (column.dataKey === 'ownership')
    return (
      <Typography>
        {row.isCarrierOwned
          ? t('Fleet:trucksTable.contractorsProperty')
          : t('Fleet:trucksTable.companysProperty')}
      </Typography>
    )
  if (column.dataKey === 'usedBy') {
    return <UsedByCell driverId={row.usedBy} />
  }
  if (column.dataKey === 'options') {
    if (row.documents?.length > 0 && getFileAlertColour(row.documents) != 'text') {
      return (
        <Tooltip title={t('Common:documentRequiresAttention')}>
          <WarningIcon color={getFileAlertColour(row.documents) as SvgIconProps['color']} />
        </Tooltip>
      )
    }
  }

  return <Typography>{row[column.dataKey as keyof ITruck] as string}</Typography>
}
