import { t } from 'i18next'
import dayjs from 'dayjs'
import { ITableColumn } from '@pkg/sokarUI'
import { getFileAlertColour } from '@shared/utils/formatters'
import appConfig from '@/config'

import { Typography, Tooltip, SvgIconProps } from '@mui/material'

import WarningIcon from '@mui/icons-material/Warning'

export const currentLoadsTableColumns: ITableColumn[] = [
  {
    label: t('Fleet:currentLoads.number'),
    dataKey: 'externalId',
    isSortable: false,
  },
  {
    label: t('Fleet:currentLoads.pickupLocation'),
    dataKey: 'pickupLocation',
    isSortable: false,
  },
  {
    label: t('Fleet:currentLoads.pickupDate'),
    dataKey: 'pickupDate',
    isSortable: false,
  },
  {
    label: t('Fleet:currentLoads.deliveryLocation'),
    dataKey: 'deliveryLocation',
    isSortable: false,
  },
  {
    label: t('Fleet:currentLoads.deliveryDate'),
    dataKey: 'deliveryDate',
    isSortable: false,
  },
  {
    label: t('Fleet:currentLoads.status'),
    dataKey: 'statusName',
    isSortable: false,
  },
]

export const currentLoadsCellRenderer = (column: ITableColumn, row: any): any => {
  if (column.dataKey === 'statusName') {
    return (
      <Typography color={'primary'}>
        {t(`Fleet:currentLoads.statuses.${row[column.dataKey]}`)}
      </Typography>
    )
  }
  if (column.dataKey === 'pickupDate') {
    return dayjs(row.stops[0].date).format(appConfig.DATE_FORMAT)
  }
  if (column.dataKey === 'deliveryDate') {
    return dayjs(row.stops[row.stops.length - 1].date).format(appConfig.DATE_FORMAT)
  }
  if (column.dataKey === 'pickupLocation') {
    return row.stops[0].countryAbbreviation + ' ' + row.stops[0].city
  }
  if (column.dataKey === 'deliveryLocation') {
    return (
      row.stops[row.stops.length - 1].countryAbbreviation +
      ' ' +
      row.stops[row.stops.length - 1].city
    )
  }
  if (column.dataKey === 'options') {
    if (row.documents?.length > 0 && getFileAlertColour(row.documents) != 'text') {
      return (
        <Tooltip title={t('Common:documentRequiresAttention')}>
          <WarningIcon color={getFileAlertColour(row.documents) as SvgIconProps['color']} />
        </Tooltip>
      )
    }
  }

  return row[column.dataKey]
}
