import { SetStateAction, useEffect, useState, Dispatch, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { ICompany } from '@pkg/companies/models'
import { ITheme } from '@pkg/sokarUI'
import styles from './companySelection.module.scss'
import debounce from 'lodash.debounce'
import { useSearchCompaniesQuery } from '@features/companies/companiesApiSlice'

import { Box, Paper, Autocomplete, TextField, useTheme, Typography } from '@mui/material'

interface CompanySelectionProps {
  setCompany: Dispatch<SetStateAction<ICompany | undefined>>
}

const CompanySelection = ({ ...props }: CompanySelectionProps): JSX.Element => {
  const { t } = useTranslation()
  const appTheme: ITheme = useTheme()
  const [searchPhrase, setSearchPhrase] = useState<string | undefined>(undefined)
  const [toSkip, setToSkip] = useState<boolean>(true)

  const { data: companies } = useSearchCompaniesQuery(
    { phrase: searchPhrase, page: 0, pageSize: 100 },
    { skip: toSkip },
  )

  const debouncedSearch = useCallback(
    debounce((phrase: string | undefined) => {
      if (phrase && phrase.length >= 3) {
        setToSkip(false)
      } else {
        setToSkip(true)
      }
    }, 300),
    [],
  )

  useEffect(() => {
    debouncedSearch(searchPhrase)

    return () => {
      debouncedSearch.cancel()
    }
  }, [searchPhrase, debouncedSearch])

  return (
    <Box
      component={Paper}
      className={styles.wrapper}
      sx={{ bgcolor: appTheme.palette.background.default }}
    >
      <Typography
        className={styles.title}
        sx={{ color: appTheme.palette.primary.main }}
      >
        {t('Accounting:selectCompany')}
      </Typography>
      <Autocomplete
        noOptionsText={t('Common:noOptions')}
        options={companies && !toSkip ? parseCompanies(companies.companies) : []}
        onChange={(e, option) => props.setCompany(option?.model)}
        renderInput={(params) => (
          <TextField
            {...params}
            //   @ts-ignore
            onChange={(e) => setSearchPhrase(e.target.value)}
            label={t('Accounting:company')}
          />
        )}
      />
    </Box>
  )
}

export default CompanySelection

interface CompanyOption {
  label: string
  value: string
  model: ICompany
}

const parseCompanies = (companies: ICompany[]): CompanyOption[] => {
  return companies.map((company) => ({
    label: company.name,
    value: company.id,
    model: company,
  }))
}
