import { useCreateLoadMutation } from '@features/loads/loadsApiSlice'
import { yupResolver } from '@hookform/resolvers/yup'
import useAlert from '@hooks/useAlert'
import { TruckStopForm } from '@lib/components'
import IntegrationsField from '@lib/IntegrationsField'
import TrailerSelect from '@lib/TrailerSelect'
import { Box, Button, MenuItem, Stack, TextField, Typography } from '@mui/material'
import { getMyMarketOffersDefaults, getMyMarketOffersStopDefaults } from '@pkg/market/defaults'
import { ICreateStop } from '@pkg/market/models'
import { getMyMarketOffersValidator } from '@pkg/market/validators/getters'
import { getRegion, parseError } from '@shared/utils/formatters'
import { t } from 'i18next'
import { Controller, useFieldArray, useForm } from 'react-hook-form'

import appConfig from '@/config'
import { ICreateMarketOffer } from '@/pkg/market/models'
import { parseCreateMyMarketOffer } from '@/pkg/market/parsers'

import styles from '../offerDialog/offerDialog.module.scss'

const CreateOfferDialog = ({ ...props }): JSX.Element => {
  const country: string | null = localStorage.getItem('country')
  const [createOffer] = useCreateLoadMutation()
  const { dispatch: dispatchAlert } = useAlert()

  const {
    control,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
    clearErrors,
  } = useForm<ICreateMarketOffer>({
    defaultValues: getMyMarketOffersDefaults(appConfig.REGION),
    resolver: yupResolver(getMyMarketOffersValidator(appConfig.REGION)),
  })

  const {
    fields: stops,
    append,
    remove,
    swap,
  } = useFieldArray({
    control,
    name: 'stops',
  })

  const moveUp = (index: number) => {
    if (index > 0) {
      swap(index, index - 1)
    }
  }

  const moveDown = (index: number) => {
    if (index < stops.length - 1) {
      swap(index, index + 1)
    }
  }

  const onSubmit = (data: ICreateMarketOffer): void => {
    const request = parseCreateMyMarketOffer(appConfig.REGION, data)

    createOffer(request)
      .unwrap()
      .then(() => {
        dispatchAlert({
          type: 'SHOW',
          payload: {
            content: t('Fleet:responses.loadCreated'),
            severity: 'success',
          },
        })
      })
      .then(() => reset())
      .then(() => props.handleClose())
      .then(() => props.refetch())
      .catch((err) => {
        const error = parseError<any>(err.data.message)
        dispatchAlert({
          type: 'SHOW',
          payload: {
            content: String(t(error.dictKey, { ...error.dependencies })),
            severity: 'error',
          },
        })
      })
  }

  const handleTruckStopChange = (index: number, data: any): void => {
    setValue(`stops.${index}`, { ...data })
    if (!!errors?.stops?.[index]?.city?.message && data.city) {
      clearErrors(`stops.${index}.city`)
    }
  }

  return (
    <Stack
      component={'form'}
      onSubmit={handleSubmit(onSubmit)}
      direction={'column'}
      spacing={2}
    >
      {stops.map((field, index: number) => {
        return (
          <TruckStopForm
            id={field.id}
            index={index}
            values={field as any}
            errors={errors}
            callback={handleTruckStopChange}
            moveUp={index === 0 ? undefined : moveUp}
            remove={stops.length > 2 ? remove : undefined}
            moveDown={index === stops.length - 1 ? undefined : moveDown}
          />
        )
      })}
      <Button
        variant={'contained'}
        onClick={() => {
          append(getMyMarketOffersStopDefaults(appConfig.REGION) as ICreateStop)
        }}
      >
        {t('Market:offerDialog.addStop')}
      </Button>
      <Typography>{t('Market:offerDialog.details')}</Typography>
      <Controller
        control={control}
        name={'type'}
        render={({ field }) => (
          <TextField
            {...field}
            select
            label={t('Market:offerDialog.type')}
            error={!!errors?.type}
            helperText={
              <Box
                className={styles.errorBox}
                height={2}
              >
                {errors?.type?.message}
              </Box>
            }
          >
            <MenuItem value={'PTL'}>{t('Market:offerDialog.typePTL')}</MenuItem>
            <MenuItem value={'LTL'}>{t('Market:offerDialog.typeLTL')}</MenuItem>
            <MenuItem value={'FTL'}>{t('Market:offerDialog.typeFTL')}</MenuItem>
          </TextField>
        )}
      />
      <Controller
        control={control}
        name={'loadingMeters'}
        render={({ field }) => (
          <TextField
            {...field}
            type={'number'}
            className={styles.textField}
            label={t('Fleet:createLoadDialog.loadingMeters')}
            error={!!errors?.loadingMeters}
            helperText={
              <Box
                className={styles.errorBox}
                height={2}
              >
                {errors?.loadingMeters?.message}
              </Box>
            }
            onWheel={(e) => (e.target as HTMLInputElement).blur()}
          />
        )}
      />
      <Stack
        direction={'row'}
        spacing={2}
      >
        <Controller
          control={control}
          name={'publishedPrice'}
          render={({ field }) => (
            <TextField
              {...field}
              type={'number'}
              className={styles.textField}
              label={t('Market:offerDialog.publishedPrice')}
              error={!!errors?.publishedPrice}
              helperText={
                <Box
                  className={styles.errorBox}
                  height={2}
                >
                  {errors?.publishedPrice?.message}
                </Box>
              }
              onWheel={(e) => (e.target as HTMLInputElement).blur()}
            />
          )}
        />
        <Controller
          control={control}
          name={'publishedPriceCurrency'}
          render={({ field }) => (
            <TextField
              {...field}
              select
              className={styles.textField}
              label={t('Market:offerDialog.publishedPriceCurrency')}
              error={!!errors?.publishedPriceCurrency}
              helperText={
                <Box
                  className={styles.errorBox}
                  height={2}
                >
                  {errors?.publishedPriceCurrency?.message}
                </Box>
              }
            >
              {/* @ts-ignore */}
              {appConfig[getRegion(country) as keyof typeof appConfig]?.currencies?.map(
                (currency: string) => (
                  <MenuItem value={currency}>{t(`Common:currencies.${currency}`)}</MenuItem>
                ),
              )}
            </TextField>
          )}
        />
      </Stack>
      <Controller
        control={control}
        name={'commodityName'}
        render={({ field }) => (
          <TextField
            {...field}
            label={t('Market:offerDialog.commodityName')}
            error={!!errors?.commodityName}
            helperText={
              <Box
                className={styles.errorBox}
                height={2}
              >
                {errors?.commodityName?.message}
              </Box>
            }
          />
        )}
      />
      <Stack
        direction={'row'}
        spacing={2}
      >
        <Controller
          control={control}
          name={'weight'}
          render={({ field }) => (
            <TextField
              {...field}
              type={'number'}
              className={styles.textField}
              label={t('Market:offerDialog.weight')}
              error={!!errors?.weight}
              helperText={
                <Box
                  className={styles.errorBox}
                  height={2}
                >
                  {errors?.weight?.message}
                </Box>
              }
              onWheel={(e) => (e.target as HTMLInputElement).blur()}
            />
          )}
        />
        <Controller
          control={control}
          name={'weightUnit'}
          render={({ field }) => (
            <TextField
              {...field}
              select
              className={styles.textField}
              label={t('Market:offerDialog.weightUnit')}
              error={!!errors?.weightUnit}
              helperText={
                <Box
                  className={styles.errorBox}
                  height={2}
                >
                  {errors?.weightUnit?.message}
                </Box>
              }
            >
              <MenuItem value={'lbs'}>{t('Market:offerDialog.weightUnit_lbs')}</MenuItem>
              <MenuItem value={'kg'}>{t('Market:offerDialog.weightUnit_kg')}</MenuItem>
              <MenuItem value={'t'}>{t('Market:offerDialog.weightUnit_t')}</MenuItem>
            </TextField>
          )}
        />
      </Stack>
      <Controller
        control={control}
        name={'equipment'}
        render={({ field }) => (
          <TrailerSelect
            {...field}
            error={errors.equipment}
          />
        )}
      />
      <Controller
        control={control}
        name={'vehicleSize'}
        render={({ field }) => (
          <TextField
            select
            {...field}
            label={t('Market:offerDialog.vehicleSize')}
          >
            {appConfig.VEHICLE_SIZES.map((size) => (
              <MenuItem
                key={size}
                value={size}
              >
                {t(`Common:vehicleSizes.${size}`)}
              </MenuItem>
            ))}
          </TextField>
        )}
      />
      <Controller
        control={control}
        name={'paymentDays'}
        render={({ field }) => (
          <TextField
            select
            {...field}
            label={t('Market:offerDialog.paymentDays')}
          >
            <MenuItem value={'1'}>1</MenuItem>
            <MenuItem value={'2'}>2</MenuItem>
            <MenuItem value={'30'}>30</MenuItem>
            <MenuItem value={'49'}>49</MenuItem>
          </TextField>
        )}
      />
      <Controller
        control={control}
        name={'description'}
        render={({ field }) => (
          <TextField
            {...field}
            multiline
            rows={3}
            label={t('Market:offerDialog.description')}
            error={!!errors?.description}
            helperText={
              <Box
                className={styles.errorBox}
                height={2}
              >
                {errors?.description?.message}
              </Box>
            }
          />
        )}
      />
      <Controller
        control={control}
        name={'publishExternalPlatforms'}
        render={({ field }) => <IntegrationsField {...field} />}
      />
      <Button
        variant={'contained'}
        type={'submit'}
      >
        {t('Common:create')}
      </Button>
    </Stack>
  )
}

export default CreateOfferDialog
