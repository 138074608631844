import * as Yup from 'yup'
import { t } from 'i18next'

export const driverValidator = Yup.object().shape({
  firstName: Yup.string()
    .required(t('Fleet:validatorResponses.firstNameRequiredConstraint') ?? '')
    .min(3, t('Fleet:validatorResponses.firstNameMinimumConsraint') ?? ''),
  lastName: Yup.string()
    .required(t('Fleet:validatorResponses.lastNameRequiredConstraint') ?? '')
    .min(3, t('Fleet:validatorResponses.lastNameMinimumConstraint') ?? ''),
  phone: Yup.string()
    .required(t('Fleet:validatorResponses.phoneRequiredConstraint') ?? '')
    .min(9, t('Fleet:validatorResponses.phoneRequiredConstraint') ?? ''),
  email: Yup.string()
    .required(t('Fleet:validatorResponses.emailRequiredConstraint') ?? '')
    .email(t('Fleet:validatorResponses.emailEmailConstraint') ?? ''),
  number: Yup.string()
    .required(t('Fleet:validatorResponses.driverNumberRequiredConstraint') ?? '')
    .length(4, t('Fleet:validatorResponses.driverNumberLengthConstraint') ?? ''),
  socialSecurity: Yup.string().required(
    t('Fleet:validatorResponses.socialSecurityRequiredConstraint') ?? '',
  ),
  compensationType: Yup.string().required(
    t('Fleet:validatorResponses.compensationTypeRequiredConstraint') ?? '',
  ),
  isOwner: Yup.boolean(),
  carrierId: Yup.string().when('isOwner', {
    is: true,
    then: Yup.string().required(t('Fleet:validatorResponses.carrierIdRequiredConstraint') ?? ''),
  }),
})
