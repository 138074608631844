import { useEffect, useReducer, Reducer } from 'react'
import { t } from 'i18next'
import { ITheme } from '@pkg/sokarUI'

import { Tooltip, Typography, useTheme } from '@mui/material'

interface TrailerInfoProps {
  trailers: string[]
  viewAll: boolean
}

interface ITrailerInfoState {
  show: boolean
  trailers: string[]
  anchor: HTMLElement | null
}

type TrailerInfoAction =
  | { type: 'ADD'; payload: { trailers: string[] } }
  | { type: 'SHOW'; payload: { anchor: HTMLElement } }
  | { type: 'HIDE' }

const trailerInfoReducer: Reducer<ITrailerInfoState, TrailerInfoAction> = (state, action) => {
  switch (action.type) {
    case 'ADD':
      return {
        ...state,
        trailers: action.payload.trailers,
      }
    case 'SHOW':
      return {
        ...state,
        show: true,
        anchor: action.payload.anchor,
      }
    case 'HIDE':
      return {
        ...state,
        show: false,
        anchor: null,
      }
    default:
      return state
  }
}

const TrailerInfo = ({ ...props }: TrailerInfoProps): JSX.Element => {
  const appTheme: ITheme = useTheme()
  const [state, dispatch] = useReducer(trailerInfoReducer, {
    show: false,
    trailers: [],
    anchor: null,
  })

  useEffect((): void => {
    if (props.trailers) {
      dispatch({
        type: 'ADD',
        payload: {
          trailers: props.trailers,
        },
      })
    }
  }, [props.trailers])

  if (typeof state.trailers != 'string' && state.trailers.length > 1) {
    var trailersLabel: String = state.trailers.map(trailer => t(`Fleet:equipment.${trailer}`)).join(", ")
    var visibleTrailersLabel: String
    if (props.viewAll || trailersLabel.length <= 10) {
        visibleTrailersLabel = trailersLabel
    } else {
        visibleTrailersLabel = trailersLabel.slice(0, 10) + "..."
    }

    return props.viewAll ? (
        <Typography>
            {visibleTrailersLabel}
        </Typography>
    ) : (
        <Tooltip title={trailersLabel}>
            <Typography>{visibleTrailersLabel}</Typography>
        </Tooltip>
    )
  }

  return (
    <Typography>
    {t(`Fleet:equipment.${state.trailers}`)}
    </Typography>
  )
}

export default TrailerInfo
