import { ChangeEvent } from 'react'

/**
 * The function `onNameChange` takes an input event and a callback function, converts the input value
 * to uppercase, and calls the callback function with the modified value.
 * @param e - The `e` parameter is an event object that represents a change event on an input or
 * textarea element. It is of type `ChangeEvent<HTMLInputElement | HTMLTextAreaElement>`, which means
 * it can be an event on either an input element or a textarea element.
 * @param onChange - The `onChange` parameter is a function that takes a string value as an argument
 * and does something with that value. In the context of the `onNameChange` function, it is used to
 * update the value of the input element to the uppercase version of the new value entered by the user.
 */
export const onNameChange = (
  e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  onChange: (value: string) => void,
) => {
  const newValue = e.target.value.toLocaleUpperCase()

  onChange(newValue)
}
