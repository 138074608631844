import { useSendInterestMutation } from '@features/loads/loadsApiSlice'
import { useGetUserQuery } from '@features/users/usersApiSlice'
import useAlert from '@hooks/useAlert'
import { IConfirmationDialogRef } from '@lib/ConfirmationDialog'
import RouteMap from '@lib/RouteMap'
import { ISideDialogChildProps, ISideDialogRef } from '@lib/SideDialog'
import TrailerInfo from '@lib/TrailerInfo'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import StarIcon from '@mui/icons-material/Star'
import {
  Avatar,
  Box,
  Button,
  Collapse,
  IconButton,
  Paper,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import { IStop } from '@pkg/loads/models'
import { ITheme } from '@pkg/sokarUI'
import { parseError, parsePhoneNumber } from '@shared/utils/formatters'
import dayjs from 'dayjs'
import { t } from 'i18next'
import { Fragment, memo, MutableRefObject, useEffect, useState } from 'react'

import appConfig from '@/config'

import styles from './carrierLoadDialog.module.scss'

interface CarrierLoadDialogProps extends ISideDialogChildProps {
  elementRef: MutableRefObject<ISideDialogRef | null>
  confirmationRef: MutableRefObject<IConfirmationDialogRef | null>
  handleClose: () => void
}

const CarrierLoadDialog = ({ ...props }: CarrierLoadDialogProps): JSX.Element => {
  const appTheme: ITheme = useTheme()
  const { dispatch: dispatchAlert } = useAlert()
  const [sendInterest] = useSendInterestMutation()

  const { data: owner, isSuccess: isOwnerSuccess } = useGetUserQuery(props.data?.owner.id, {
    skip: !props.data?.owner.id,
  })
  const [isStopsOpen, setIsStopsOpen] = useState<boolean>(true)

  const handleShowInterest = (): void => {
    if (!props.data.id) {
      return
    }

    const handleSendMail = (id: string) => {
      sendInterest(id)
        .unwrap()
        .then(() => {
          dispatchAlert({
            type: 'SHOW',
            payload: {
              content: t('Market:offerDialog.interestSend'),
              severity: 'success',
            },
          })
          props.confirmationRef.current?.close()
          props.handleClose()
        })
        .catch((err: any) => {
          const error = parseError<any>(err.message)
          dispatchAlert({
            type: 'SHOW',
            payload: {
              content: String(t(error.dictKey, { ...error.dependencies })),
              severity: 'error',
            },
          })
          props.confirmationRef.current?.close()
        })
    }

    props.confirmationRef.current?.open({
      title: t('Market:offerDialog.showInterestTitle'),
      text: (
        <p className={styles.confirmationDialogText}>{t('Market:offerDialog.showInterestText')}</p>
      ),
      actions: [
        <Button onClick={() => props.confirmationRef.current?.close()}>{t('Common:no')}</Button>,
        <Button onClick={() => handleSendMail(props.data.id)}>{t('Common:yes')}</Button>,
      ],
    })
  }

  const dialogActions: JSX.Element = (
    <Fragment>
      <Tooltip title={t('Market:offerDialog.showInterest')}>
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={handleShowInterest}
          className='forcarriers-market-tour-10'
        >
          <StarIcon />
        </IconButton>
      </Tooltip>
    </Fragment>
  )

  useEffect((): void => {
    props.elementRef.current?.setActions(dialogActions)
  }, [props.data])

  if (!props.data) {
    return (
      <>
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </>
    )
  }

  const MemoizedRouteMap = memo(RouteMap)

  return (
    <Stack
      direction={'column'}
      spacing={2}
    >
      <Typography variant={'h5'}>{t('Market:carrierLoadDialog.info')}</Typography>
      <Stack
        direction={'column'}
        spacing={2}
      >
        <Box className={styles.mapWrapper}>
          <MemoizedRouteMap offer={props.data} />
        </Box>
        <Typography className={styles.ownerTitle}>{t('Market:carrierLoadDialog.owner')}</Typography>
        <Box className={styles.contact}>
          <Avatar className={styles.avatar} />
          {isOwnerSuccess && (
            <Stack
              direction={'column'}
              spacing={2}
            >
              <Typography component={'h2'}>
                {isOwnerSuccess && owner.firstName} {isOwnerSuccess && owner.lastName}
              </Typography>
              <Typography
                component={'a'}
                href={`tel:${owner.phone.replace(/^0+/, '')}`}
              >
                {parsePhoneNumber(owner.phone)}
              </Typography>
              <Typography
                component={'a'}
                href={`mailto:${props.data?.owner.email}`}
              >
                {props.data?.owner.email}
              </Typography>
            </Stack>
          )}
        </Box>
      </Stack>
      <TableContainer
        component={Paper}
        sx={{ backgroundColor: appTheme.palette.background.default }}
      >
        <Table>
          <TableBody>
            <TableRow>
              <TableCell variant={'head'}>{t('Market:carrierLoadDialog.type')}</TableCell>
              <TableCell>
                <Typography>{props.data?.type ?? ''}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant={'head'}>{t('Market:carrierLoadDialog.weight')}</TableCell>
              <TableCell>
                <Typography>{props.data?.weight ?? ''} {props.data?.weightUnit}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant={'head'}>{t('Market:carrierLoadDialog.commodityName')}</TableCell>
              <TableCell>
                <Typography>{props.data?.commodityName ?? ''}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant={'head'}>{t('Market:carrierLoadDialog.price')}</TableCell>
              <TableCell>
                <Typography>{props.data?.publishedPrice ?? ''} {props.data?.publishedPriceCurrency ?? ''}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant={'head'}>{t('Market:offerDialog.vehicleSize')}</TableCell>
              <TableCell>
                <Typography>{t(`Common:vehicleSizes.${props.data?.vehicleSize}`)}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant={'head'}>{t('Market:carrierLoadDialog.trailer')}</TableCell>
              <TableCell>
                <TrailerInfo trailers={props.data?.equipment.split(',') ?? []} viewAll={true} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant={'head'}>{t('Market:carrierLoadDialog.loadingMeters')}</TableCell>
              <TableCell>
                <Typography>{props.data?.loadingMeters ?? '-'}</Typography>
              </TableCell>
            </TableRow>
            <TableRow
              onClick={() => setIsStopsOpen(!isStopsOpen)}
              className={styles.extendableTableCell}
            >
              <TableCell variant={'head'}>{t('Market:carrierLoadDialog.stops')}</TableCell>
              <TableCell>
                {isStopsOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                colSpan={2}
                className={styles.collapsableTableCell}
              >
                <Collapse
                  in={isStopsOpen}
                  unmountOnExit
                >
                  <Table>
                    <TableBody>
                      {props.data.stops.map((stop: IStop, index: number) => (
                        <Fragment key={index}>
                          <TableRow className={styles.noBorderBottom}>
                            <TableCell variant={'head'}>
                              <Typography>{stop.countryAbbreviation} {stop.city}</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography>{dayjs(stop.date).format(appConfig.DATE_FORMAT)}
                              {!!stop.dateTo && (
                                <> - {dayjs(stop.dateTo).format(appConfig.DATE_FORMAT)}</>
                              )}</Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow className={styles.noBorderTop}>
                            <TableCell variant={'head'}>
                              {t(`Market:carrierLoadDialog.${stop.category}`)}
                            </TableCell>
                            <TableCell>
                              <Typography>{dayjs(stop.date).format('HH:mm')}
                              {!!stop.dateTo && <> - {dayjs(stop.dateTo).format('HH:mm')}</>}</Typography>
                            </TableCell>
                          </TableRow>
                        </Fragment>
                      ))}
                      <TableRow>
                        <TableCell variant={'head'}>
                          {t('Market:carrierLoadDialog.description')}
                        </TableCell>
                        <TableCell>
                          <Typography>{props.data?.description ? props.data?.description : '-'}</Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell variant={'head'}>
                          {t('Market:offerDialog.paymentDays')}
                        </TableCell>
                        <TableCell>
                          <Typography>{props.data?.paymentDays}</Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Collapse>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  )
}

export default CarrierLoadDialog
