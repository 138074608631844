import { t } from 'i18next'
import { ITableColumn } from '@pkg/sokarUI'
import { ITrailer } from '@pkg/equipment/models'
import { getFileAlertColour } from '@shared/utils/formatters'

import UsedByCell from './components/usedByCell'

import { SvgIconProps, Chip, Typography, Tooltip } from '@mui/material'

import WarningIcon from '@mui/icons-material/Warning'

export const trailerTableColumns: ITableColumn[] = [
  {
    label: t('Fleet:trailersTable.trailerNumber'),
    dataKey: 'number',
    isSortable: false,
  },
  {
    label: t('Fleet:trailersTable.plates'),
    dataKey: 'plate',
    isSortable: true,
  },
  {
    label: t('Fleet:trailersTable.ownership'),
    dataKey: 'ownership',
    isSortable: false,
  },
  {
    label: t('Fleet:trailersTable.type'),
    dataKey: 'category',
    isSortable: true,
  },
  {
    label: t('Fleet:trailersTable.usedBy'),
    dataKey: 'usedBy',
    isSortable: false,
  },
]

export const trailerTableCellRenderer = (column: ITableColumn, row: ITrailer): JSX.Element => {
  if (column.dataKey === 'category')
    return (
      <Chip
        color={'primary'}
        label={row['category']}
      />
    )
  if (column.dataKey === 'ownership')
    return (
      <Typography>
        {row['isCarrierOwned']
          ? t('Fleet:trailersTable.contractorsProperty')
          : t('Fleet:trailersTable.companysProperty')}
      </Typography>
    )
  if (column.dataKey === 'usedBy') {
    return <UsedByCell driverId={row['usedBy'] as string} />
  }
  if (column.dataKey === 'options') {
    if (row.documents?.length > 0 && getFileAlertColour(row.documents) != 'text') {
      return (
        <Tooltip title={t('Common:documentRequiresAttention')}>
          <WarningIcon color={getFileAlertColour(row.documents) as SvgIconProps['color']} />
        </Tooltip>
      )
    }
  }

  return <Typography>{row[column.dataKey as keyof ITrailer] as string}</Typography>
}
