import { useState, Suspense, lazy, LazyExoticComponent, useEffect, MutableRefObject } from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@emotion/react'
import { useUpdateLoadMutation } from '@/features/loads/loadsApiSlice'
import { ISideDialogChildProps, ISideDialogRef } from 'src/lib/SideDialog'
import useAlert from '@hooks/useAlert'
import { createUpdateLoadRequest } from '@pkg/loads/converters'
import { parseError } from '@shared/utils/formatters'
import { IConfirmationDialogRef } from '@lib/ConfirmationDialog'

import { Tooltip, IconButton, Button } from '@mui/material'

import HomeIcon from '@mui/icons-material/Home'
import UnarchiveIcon from '@mui/icons-material/Unarchive'
import HistoryIcon from '@mui/icons-material/History'

interface LoadDialogProps extends ISideDialogChildProps {
  elementRef: MutableRefObject<ISideDialogRef>
  confirmationRef: MutableRefObject<IConfirmationDialogRef | null>
  refetch: () => void
  handleClose: () => void
}

type loadDialogElements = 'info' | 'history'

const LoadDialog: React.FC<LoadDialogProps> = ({ ...props }) => {
  const { t } = useTranslation()
  const appTheme: any = useTheme()
  const [updateLoad] = useUpdateLoadMutation()
  const [loadId, setLoadId] = useState<string>('')
  const [elementKey, setElementKey] = useState<loadDialogElements>('info')
  const { dispatch: dispatchAlert } = useAlert()

  const handleUnarchiveLoad = () => {
    const handleUnarchive = () => {
      let loadData = createUpdateLoadRequest(props.data!)
      loadData.archived = false
      updateLoad(loadData)
        .unwrap()
        .then(() => {
          dispatchAlert({
            type: 'SHOW',
            payload: {
              content: t('Fleet:responses.loadUpdated'),
              severity: 'success',
            },
          })
        })
        .then(() => props.confirmationRef.current?.close())
        .then(() => props.handleClose())
        .then(() => props.refetch())
        .catch((err: any) => {
          const error = parseError<any>(err.message)
          dispatchAlert({
            type: 'SHOW',
            payload: {
              content: String(t(error.dictKey, { ...error.dependencies })),
              severity: 'error',
            },
          })
          props.confirmationRef.current?.close()
        })
    }

    props.confirmationRef.current?.open({
      title: t('Fleet:confirmationDialog.unarchiveLoadTitle'),
      text: t('Fleet:confirmationDialog.unarchiveLoadText'),
      actions: [
        <Button onClick={() => props.confirmationRef.current?.close()}>{t('Common:no')}</Button>,
        <Button onClick={() => handleUnarchive()}>{t('Common:yes')}</Button>,
      ],
    })
  }
  const dialogActions = (
    <>
      <Tooltip
        title={t('Fleet:loadDialog.info')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => setElementKey('info')}
        >
          <HomeIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('Fleet:loadDialog.history')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          disabled
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => setElementKey('history')}
        >
          <HistoryIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('Fleet:loadDialog.unarchive')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => handleUnarchiveLoad()}
        >
          <UnarchiveIcon />
        </IconButton>
      </Tooltip>
    </>
  )

  const getProperElement = (
    key: loadDialogElements,
  ): LazyExoticComponent<React.ComponentType<any>> => {
    switch (key) {
      // case 'history':
      //     return lazy(() => import('./history'))
      default:
        return lazy(() => import('@app/fleet/views/CurrentLoads/components/LoadDialog/info'))
    }
  }

  useEffect(() => {
    if (!!props.data) setLoadId(props.data.id)
  }, [props.data])

  useEffect(() => {
    props.elementRef.current?.setActions(dialogActions)
  }, [loadId])

  const ProperComponent = getProperElement(elementKey)

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <ProperComponent
        data={props.data}
        handleClose={props.handleClose}
      />
    </Suspense>
  )
}

export default LoadDialog
