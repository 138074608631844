import { useEffect, useReducer, forwardRef, Reducer, SyntheticEvent } from 'react'
import { t } from 'i18next'
import appConfig from '@/config'
import styles from './companySearch.module.scss'
import { ITheme } from '@pkg/sokarUI'
import { ICompany } from '@pkg/companies/models'
import { ControllerRenderProps, FieldError } from 'react-hook-form'

import {
  useSearchCompaniesQuery,
  useCheckCompanyQuery,
} from '@features/companies/companiesApiSlice'

import {
  Box,
  Table,
  Stack,
  Paper,
  Radio,
  Button,
  Tooltip,
  TableRow,
  TableCell,
  TextField,
  TableBody,
  RadioGroup,
  Typography,
  TableContainer,
  CircularProgress,
  useTheme,
} from '@mui/material'

import SearchIcon from '@mui/icons-material/Search'
import VerifiedIcon from '@mui/icons-material/Verified'
import DangerousIcon from '@mui/icons-material/Dangerous'
import FactCheckIcon from '@mui/icons-material/FactCheck'

interface CompanySearchProps extends ControllerRenderProps {
  isDisabled: boolean
  error?: FieldError
  companyName?: string
}

interface ICompanySearchState {
  id: string
  companyId: string
  companyCountryCode: string
  companyVatId: string
  searchPhrase: string
  isSearched: boolean
}

const defaultValues: ICompanySearchState = {
  id: Math.random().toString(36).substring(2, 15),
  companyId: '',
  companyCountryCode: '',
  companyVatId: '',
  searchPhrase: '',
  isSearched: false,
}

type CompanySearchActions =
  | { command: 'randomizeId' }
  | { command: 'changeCompany'; payload: string }
  | { command: 'verifyCompany'; payload: ICompany[] }
  | { command: 'startSearch' }
  | { command: 'finishSearch' }
  | { command: 'changeSearchPhrase'; payload: string }

const companyReducer: Reducer<ICompanySearchState, CompanySearchActions> = (state, action) => {
  switch (action.command) {
    case 'randomizeId':
      return {
        ...state,
        id: Math.random().toString(36).substring(2, 15),
      }
    case 'changeCompany':
      return {
        ...state,
        companyId: action.payload,
      }
    case 'verifyCompany':
      const company = action.payload.find((cmp) => cmp.id === state.companyId)
      if (!company) {
        return state
      }

      return {
        ...state,
        companyCountryCode: company.countryCode,
        companyVatId: company.vatId,
      }
    case 'startSearch':
      return {
        ...state,
        isSearched: true,
        id: Math.random().toString(36).substring(2, 15),
      }
    case 'finishSearch':
      return {
        ...state,
        isSearched: false,
      }
    case 'changeSearchPhrase':
      return {
        ...state,
        searchPhrase: action.payload,
      }
    default:
      return state
  }
}

const CompanySearch = forwardRef<HTMLDivElement, Omit<CompanySearchProps, 'variant'>>(
  ({ value, isDisabled, onChange, error, companyName }, ref) => {
    const appTheme: ITheme = useTheme()
    const [state, dispatch] = useReducer(companyReducer, defaultValues)

    const { data: { companies } = {}, isFetching: isFetchingSearch } = useSearchCompaniesQuery(
      { phrase: state.searchPhrase, page: 1, pageSize: appConfig.PAGE_SIZE, key: state.id },
      { skip: !state.isSearched },
    )

    const {
      data: verificationData,
      isFetching: isVerificationFetching,
      isUninitialized: isVerificationUninitialized,
    } = useCheckCompanyQuery(
      { countryCode: state.companyCountryCode, vatId: state.companyVatId },
      { skip: !state.companyCountryCode || !state.companyVatId },
    )

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Enter') {
        event.preventDefault()
      }
      dispatch({ command: 'finishSearch' })
    }

    const handleCompanyChange = (event: SyntheticEvent, newValue: string): void => {
      dispatch({ command: 'changeCompany', payload: newValue })
      onChange(newValue, state.searchPhrase)
    }

    useEffect((): void => {
      if (companyName) {
        dispatch({ command: 'changeSearchPhrase', payload: companyName })
      }
      if (value && value !== state.companyId) {
        dispatch({ command: 'changeCompany', payload: value })
        dispatch({ command: 'startSearch' })
      }
    }, [value])

    return (
      <>
        <TableContainer
          ref={ref}
          component={Paper}
          className={error ? styles.searchWrapperError : styles.searchWrapper}
          sx={{ backgroundColor: appTheme.palette.background.default }}
        >
          <Stack
            spacing={2}
            direction={'row'}
            className={styles.searchRow}
            sx={{ backgroundColor: appTheme.palette.background.default }}
          >
            <TextField
              value={state.searchPhrase}
              disabled={isDisabled}
              onKeyDown={handleKeyDown}
              className={styles.searchField}
              label={t('Common:UI.search.ownerCompany')}
              onChange={(e) => dispatch({ command: 'changeSearchPhrase', payload: e.target.value })}
            />
            <Tooltip title={t('Common:search')}>
              <Button
                variant={'contained'}
                onClick={() => dispatch({ command: 'startSearch' })}
                disabled={state.searchPhrase.length === 0 || isDisabled}
              >
                <SearchIcon />
              </Button>
            </Tooltip>
            <Tooltip title={t('Common:verify')}>
              <Button
                variant={'contained'}
                disabled={!state.companyId}
                onClick={() => dispatch({ command: 'verifyCompany', payload: companies! })}
              >
                {isVerificationFetching && !isVerificationUninitialized ? (
                  <CircularProgress sx={{ color: appTheme.palette.text.light }} />
                ) : (
                  <FactCheckIcon />
                )}
              </Button>
            </Tooltip>
          </Stack>
          {isFetchingSearch && (
            <Box className={styles.loader}>
              <CircularProgress />
            </Box>
          )}
          <RadioGroup
            name={'carrier'}
            value={state.companyId}
            onChange={handleCompanyChange}
          >
            <Table className={styles.table}>
              <TableBody>
                {companies &&
                  companies?.length > 0 &&
                  companies?.map((company: ICompany) => (
                    <TableRow
                      key={company.id}
                      className={styles.tableRow}
                    >
                      <TableCell>
                        <Radio
                          value={company.id}
                          disabled={isDisabled}
                        />
                      </TableCell>
                      <TableCell>
                        <Table>
                          <TableRow>
                            <TableCell>
                              <Typography
                                className={styles.subtitle}
                                sx={{ color: appTheme.palette.grey['500'] }}
                              >
                                {t('Companies:table.name')}
                              </Typography>
                              {company.name}
                            </TableCell>
                            <TableCell>
                              <Typography
                                className={styles.subtitle}
                                sx={{ color: appTheme.palette.grey['500'] }}
                              >
                                {t('Companies:table.address')}
                              </Typography>
                              {company.correspondence}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Typography
                                className={styles.subtitle}
                                sx={{ color: appTheme.palette.grey['500'] }}
                              >
                                {t('Companies:table.vatId')}
                              </Typography>
                              {company.vatId}
                            </TableCell>
                            <TableCell>
                              <Typography
                                className={styles.subtitle}
                                sx={{ color: appTheme.palette.grey['500'] }}
                              >
                                {t('Companies:table.regon')}
                              </Typography>
                              {company.regon}
                            </TableCell>
                          </TableRow>
                        </Table>
                      </TableCell>
                    </TableRow>
                  ))}
                {state.isSearched && companies?.length === 0 && (
                  <Box className={styles.noMatch}>
                    <Typography>{t('Common:noMatch')}</Typography>
                  </Box>
                )}
              </TableBody>
            </Table>
          </RadioGroup>
        </TableContainer>
        <Box className={styles.feedbackContainer}>
          {!isVerificationFetching && !isVerificationUninitialized && verificationData?.valid && (
            <Typography className={styles.verification}>
              <VerifiedIcon sx={{ color: appTheme.palette.success.main }} />
              {t('Companies:valid')}
            </Typography>
          )}
          {!isVerificationFetching && !isVerificationUninitialized && !verificationData?.valid && (
            <Typography className={styles.verification}>
              <DangerousIcon sx={{ color: appTheme.palette.error.main }} />
              {t('Companies:notValid')}
            </Typography>
          )}
          <Typography sx={{ color: appTheme.palette.error.main }}>{error?.message}</Typography>
        </Box>
      </>
    )
  },
)

CompanySearch.displayName = 'CompanySearch'

export default CompanySearch
