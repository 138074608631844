import appConfig from '@/config'
import { countryCodes } from '@pkg/countryCodes'
import { CountryCode, ICountryConfig } from '@pkg/types'

export const getRegion = (countryCode: string): string => {
  return countryCodes[countryCode.toLocaleUpperCase() as CountryCode]
}

export const getDateFormat = (): string => {
  const country: string | null = localStorage.getItem('country')

  return !!country ?
      (appConfig[getRegion(country) as keyof typeof appConfig] as ICountryConfig).dateFormat
    : 'YYYY-MM-DD'
}
