import { useTranslation } from 'react-i18next'
import { Controller, Control, FieldArrayWithId } from 'react-hook-form'
import styles from './additionalsList.module.scss'
import { initInvoiceAdditional } from '@pkg/documents/initializers'
import { Additional as InvoiceAdditional, CreateFromTemplate } from '@pkg/documents/requests'

import { List, ListItem, MenuItem, TextField, Button, IconButton, Tooltip } from '@mui/material'

import DeleteIcon from '@mui/icons-material/Delete'

export type Additional = 'invoiceDetails.services' | 'invoiceDetails.deductions'

interface AdditionalsListProps<T extends Additional> {
  type: T
  value: FieldArrayWithId<CreateFromTemplate, T, 'id'>[]
  control: Control<CreateFromTemplate>
  append: (value: InvoiceAdditional) => void
  remove: () => void
}

const AdditionalsList = <T extends Additional>({
  ...props
}: AdditionalsListProps<T>): JSX.Element => {
  const { t } = useTranslation()

  return (
    <List className={styles.listWrapper}>
      {props.value.map((additional, index: number) => (
        <ListItem
          key={additional.id}
          className={styles.listItem}
        >
          <Controller
            control={props.control}
            name={`${props.type}.${index}.name`}
            render={({ field }) => (
              <TextField
                select
                {...field}
                label={t('Accounting:category')}
              >
                <MenuItem value={'truck-lease'}>
                  {t('Accounting:additionalCategories.truck-lease')}
                </MenuItem>
                <MenuItem value={'trailer-lease'}>
                  {t('Accounting:additionalCategories.trailer-lease')}
                </MenuItem>
                <MenuItem value={'cargo-insurance'}>
                  {t('Accounting:additionalCategories.cargo-insurance')}
                </MenuItem>
                <MenuItem value={'insurance-occ'}>
                  {t('Accounting:additionalCategories.insurance-occ')}
                </MenuItem>
                <MenuItem value={'trailer-insurance'}>
                  {t('Accounting:additionalCategories.trailer-insurance')}
                </MenuItem>
                <MenuItem value={'scale'}>{t('Accounting:additionalCategories.scale')}</MenuItem>
                <MenuItem value={'parking'}>
                  {t('Accounting:additionalCategories.parking')}
                </MenuItem>
                <MenuItem value={'permit'}>{t('Accounting:additionalCategories.permit')}</MenuItem>
                <MenuItem value={'labour'}>{t('Accounting:additionalCategories.labour')}</MenuItem>
                <MenuItem value={'layover'}>
                  {t('Accounting:additionalCategories.layover')}
                </MenuItem>
                <MenuItem value={'advance'}>
                  {t('Accounting:additionalCategories.advance')}
                </MenuItem>
                <MenuItem value={'detention'}>
                  {t('Accounting:additionalCategories.detention')}
                </MenuItem>
                <MenuItem value={'efs'}>{t('Accounting:additionalCategories.efs')}</MenuItem>
                <MenuItem value={'late-fee'}>
                  {t('Accounting:additionalCategories.late-fee')}
                </MenuItem>
                <MenuItem value={'lumper'}>{t('Accounting:additionalCategories.lumper')}</MenuItem>
                <MenuItem value={'dot-inspection'}>
                  {t('Accounting:additionalCategories.dot-inspection')}
                </MenuItem>
                <MenuItem value={'tonu'}>{t('Accounting:additionalCategories.tonu')}</MenuItem>
                <MenuItem value={'escrow'}>{t('Accounting:additionalCategories.escrow')}</MenuItem>
                <MenuItem value={'fuel'}>{t('Accounting:additionalCategories.fuel')}</MenuItem>
                <MenuItem value={'ifta'}>{t('Accounting:additionalCategories.ifta')}</MenuItem>
                <MenuItem value={'tolls'}>{t('Accounting:additionalCategories.tolls')}</MenuItem>
                <MenuItem value={'other'}>{t('Accounting:additionalCategories.other')}</MenuItem>
              </TextField>
            )}
          />
          <Controller
            control={props.control}
            name={`${props.type}.${index}.bruttoPrice`}
            render={({ field }) => (
              <TextField
                {...field}
                label={t('Accounting:amount')}
                type={'number'}
              />
            )}
          />
          <Controller
            control={props.control}
            name={`${props.type}.${index}.note`}
            render={({ field }) => (
              <TextField
                {...field}
                label={t('Accounting:note')}
              />
            )}
          />
          <Tooltip title={t('Accounting:removeAdditional')}>
            <IconButton onClick={props.remove}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </ListItem>
      ))}
      <ListItem>
        <Button
          variant={'contained'}
          onClick={() => props.append(initInvoiceAdditional())}
          className={styles.addButton}
        >
          {t('Common:add')}
        </Button>
      </ListItem>
    </List>
  )
}

export default AdditionalsList
