import { SetStateAction, Dispatch } from 'react'
import { settingsComponent } from './../../index'
import { Stack, List, useTheme, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import RequireRole from '@features/auth/RequireRole'

import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ITheme } from '@pkg/sokarUI'

import LockIcon from '@mui/icons-material/Lock'
import PersonIcon from '@mui/icons-material/Person'
import VpnKeyIcon from '@mui/icons-material/VpnKey'

interface SettingsNavProps {
  setNavComponent: Dispatch<SetStateAction<settingsComponent>>
}

const SettingsNav = ({ setNavComponent }: SettingsNavProps): JSX.Element => {
  const { i18n, t } = useTranslation()
  const appTheme: ITheme = useTheme()

  useEffect(() => {
    const handleLanguageChange = () => {
      setNavComponent((prev) => prev)
    }

    i18n.on('languageChanged', handleLanguageChange)

    return () => {
      i18n.off('languageChanged', handleLanguageChange)
    }
  }, [i18n])

  return (
    <Stack
      direction={'column'}
      spacing={2}
    >
      <List
        sx={{ bgcolor: appTheme.palette.background.default }}
        component='nav'
      >
        <ListItemButton onClick={() => setNavComponent('personal')}>
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText primary={t('Root:auth.personal')} />
        </ListItemButton>
        <ListItemButton onClick={() => setNavComponent('security')}>
          <ListItemIcon>
            <LockIcon />
          </ListItemIcon>
          <ListItemText primary={t('Root:auth.security')} />
        </ListItemButton>
        <RequireRole allowedRoles={['employee']}>
          <ListItemButton onClick={() => setNavComponent('integrations')}>
            <ListItemIcon>
              <VpnKeyIcon />
            </ListItemIcon>
            <ListItemText primary={t('Root:auth.integrations')} />
          </ListItemButton>
        </RequireRole>
      </List>
    </Stack>
  )
}

export default SettingsNav
