import CityField from '@lib/CityField'
import CountrySelect from '@lib/CountrySelect'
import OptionsCard from '@lib/OptionsCard'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import MapIcon from '@mui/icons-material/Map'
import SearchIcon from '@mui/icons-material/Search'
import SearchOffIcon from '@mui/icons-material/SearchOff'
import {
  Box,
  Button,
  Collapse,
  IconButton,
  Typography,
  Tooltip,
  InputAdornment,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { ICity } from '@pkg/loads/models'
import { parseDistance, parseDistanceUnitToSymbol } from '@pkg/market/parsers'
import { ISearchMarketOffersRequest } from '@pkg/market/requests'
import { ITheme } from '@pkg/sokarUI'
import { assert } from '@shared/utils/utilities'
import { t } from 'i18next'
import { Dispatch, FormEvent, Reducer, SetStateAction, useEffect, useReducer } from 'react'

import appConfig from '@/config'
import TrailerSelect from '@/lib/TrailerSelect'
import { Clear as ClearIcon } from '@mui/icons-material'

import styles from './optionCard.module.scss'

interface ICarrierLoadOptionCardProps {
  isMapView: boolean
  handleSearch: Dispatch<SetStateAction<Partial<ISearchMarketOffersRequest>>>
  handleViewToggle: () => void
  onBoardingStep?: number
}

interface ICarrierMarketOptionCardState {
  isSearchExpanded: boolean
  originCountry: string
  originCity: string
  destinationCountry: string
  destinationCity: string
  searchValues: ISearchMarketOffersRequest
}

const defaultValues: ICarrierMarketOptionCardState = {
  originCountry: 'PL',
  originCity: '',
  destinationCountry: 'PL',
  destinationCity: '',
  isSearchExpanded: false,
  searchValues: {
    'originSearch.deadheadMeters': 150000,
    'originSearch.latitude': null,
    'originSearch.longitude': null,
    'destinationSearch.deadheadMeters': 150000,
    'destinationSearch.latitude': null,
    'destinationSearch.longitude': null,
    equipment: null,
  },
}

type CarrierMarketActions =
  | { command: 'toggleView' }
  | { command: 'toggleExpand' }
  | { command: 'handleValueChange'; payload: Record<string, unknown> }
  | { command: 'handleOriginChange'; payload: ICity }
  | { command: 'handleDestinationChange'; payload: ICity }
  | { command: 'handleChangeOriginDeadhead'; payload: number }
  | { command: 'handleChangeDestinationDeadhead'; payload: number }
  | { command: 'handleEquipmentChange'; payload: string }
  | {
      command: 'clearSearch'
      payload: Dispatch<SetStateAction<Partial<ISearchMarketOffersRequest>>>
    }
  | {
      command: 'handleSearch'
      payload: Dispatch<SetStateAction<Partial<ISearchMarketOffersRequest>>>
    }
  | { command: 'handleClearPickup' }
  | { command: 'handleClearDelivery' }
  | { command: 'handleChangePickupCountry'; payload: string }
  | { command: 'handleChangeDeliveryCountry'; payload: string }

const CarrierMarketOptionsCardReducer: Reducer<
  ICarrierMarketOptionCardState,
  CarrierMarketActions
> = (state, action) => {
  switch (action.command) {
    case 'toggleView':
      return state
    case 'toggleExpand':
      return {
        ...state,
        isSearchExpanded: !state.isSearchExpanded,
      }
    case 'handleValueChange':
      return {
        ...state,
        ...action.payload,
      }
    case 'handleOriginChange':
      return {
        ...state,
        originCity: action.payload.name,
        searchValues: {
          ...state.searchValues,
          'originSearch.latitude': action.payload.latitude,
          'originSearch.longitude': action.payload.longitude,
          'originSearch.deadheadMeters': [0, null, undefined].includes(
            state.searchValues['originSearch.deadheadMeters'],
          )
            ? 150000
            : state.searchValues['originSearch.deadheadMeters'],
        },
      }
    case 'handleDestinationChange':
      return {
        ...state,
        destinationCity: action.payload.name,
        searchValues: {
          ...state.searchValues,
          'destinationSearch.latitude': action.payload.latitude,
          'destinationSearch.longitude': action.payload.longitude,
          'destinationSearch.deadheadMeters': [0, null, undefined].includes(
            state.searchValues['destinationSearch.deadheadMeters'],
          )
            ? 150000
            : state.searchValues['destinationSearch.deadheadMeters'],
        },
      }
    case 'clearSearch':
      action.payload({})

      return defaultValues
    case 'handleSearch':
      for (const key in state.searchValues) {
        if (state.searchValues[key as keyof typeof state.searchValues] === null) {
          if (key === 'originSearch.latitude') {
            delete state.searchValues['originSearch.deadheadMeters']
          }
          if (key === 'destinationSearch.latitude') {
            delete state.searchValues['destinationSearch.deadheadMeters']
          }
          delete state.searchValues[key as keyof typeof state.searchValues]
        }
      }

      action.payload(state.searchValues)

      return {
        ...state,
        isSearchExpanded: false,
      }
    case 'handleChangeOriginDeadhead':
      return {
        ...state,
        searchValues: {
          ...state.searchValues,
          'originSearch.deadheadMeters': action.payload,
        },
      }
    case 'handleChangeDestinationDeadhead':
      return {
        ...state,
        searchValues: {
          ...state.searchValues,
          'destinationSearch.deadheadMeters': action.payload,
        },
      }
    case 'handleEquipmentChange':
      return {
        ...state,
        searchValues: {
          ...state.searchValues,
          equipment: action.payload === '' ? null : action.payload,
        },
      }
    case 'handleClearPickup':
      return {
        ...state,
        originCountry: 'PL',
        originCity: '',
        searchValues: {
          ...state.searchValues,
          'originSearch.latitude': null,
          'originSearch.longitude': null,
          'originSearch.deadheadMeters': 150000,
        },
      }
    case 'handleClearDelivery':
      return {
        ...state,
        destinationCountry: 'PL',
        destinationCity: '',
        searchValues: {
          ...state.searchValues,
          'destinationSearch.latitude': null,
          'destinationSearch.longitude': null,
          'destinationSearch.deadheadMeters': 150000,
        },
      }
    case 'handleChangePickupCountry':
      return {
        ...state,
        originCity: '',
        originCountry: action.payload,
        searchvalues: {
          'originSearch.latitude': null,
          'originSearch.longitude': null,
          'originSearch.deadheadMeters': 150000,
        },
      }
    case 'handleChangeDeliveryCountry':
      return {
        ...state,
        destinationCity: '',
        destinationCountry: action.payload,
        searchvalues: {
          'destinationSearch.latitude': null,
          'destinationSearch.longitude': null,
          'destinationSearch.deadheadMeters': 150000,
        },
      }
    default:
      return state
  }
}

const CarrierLoadOptionCard = ({ ...props }: ICarrierLoadOptionCardProps): JSX.Element => {
  const appTheme: ITheme = useTheme()
  const isMobile: boolean = useMediaQuery(appTheme.breakpoints.down('md'))
  const [state, dispatch] = useReducer(CarrierMarketOptionsCardReducer, defaultValues)

  useEffect(() => {
    if (props.onBoardingStep === 1) {
      dispatch({ command: 'toggleExpand' })
    }
  }, [props.onBoardingStep])

  return (
    <OptionsCard className={`${styles.wrapper} forcarriers-market-tour-1`}>
      <Box>
        <Collapse
          in={isMobile ? state.isSearchExpanded : true}
          timeout={'auto'}
          unmountOnExit
        >
          <Box className={styles.inputRow}>
            <Box className={styles.categoryColumn}>
              <Box className={styles.category}>
                <Typography sx={{ color: appTheme.palette.primary.main }}>
                  {t('Market:search.pickup')}
                </Typography>
              </Box>
              <Box className={styles.inputRow}>
                <CountrySelect
                  value={state.originCountry}
                  onChange={(_e, option) => {
                    assert(option !== null, 'option cannot be NULL at this point')

                    dispatch({
                      command: 'handleChangePickupCountry',
                      payload: option.value,
                    })
                  }}
                />
                <CityField
                  index={0}
                  value={state.originCity}
                  country={state.originCountry}
                  handleValueChange={(_i, city) =>
                    dispatch({
                      command: 'handleOriginChange',
                      payload: city,
                    })
                  }
                />
                <IconButton
                  onClick={() => dispatch({ command: 'handleClearPickup' })}
                  aria-label='clear'
                  size='small'
                  className='forcarriers-market-tour-5'
                >
                  <ClearIcon />
                </IconButton>
              </Box>
            </Box>
            <Box className={styles.categoryColumn}>
              <Box className={styles.category}>
                <Typography sx={{ color: appTheme.palette.primary.main }}>
                  {t('Market:search.delivery')}
                </Typography>
              </Box>
              <Box className={styles.inputRow}>
                <CountrySelect
                  value={state.destinationCountry}
                  onChange={(_e, option) => {
                    assert(option !== null, 'option cannot be NULL at this point')

                    dispatch({
                      command: 'handleChangeDeliveryCountry',
                      payload: option.value,
                    })
                  }}
                />
                <CityField
                  index={0}
                  value={state.destinationCity}
                  country={state.destinationCountry}
                  handleValueChange={(_i, city) =>
                    dispatch({
                      command: 'handleDestinationChange',
                      payload: city,
                    })
                  }
                />
                <IconButton
                  onClick={() => dispatch({ command: 'handleClearDelivery' })}
                  aria-label='clear'
                  size='small'
                  className='forcarriers-market-tour-6'
                >
                  <ClearIcon />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </Collapse>
        <Collapse
          in={state.isSearchExpanded}
          timeout={'auto'}
          unmountOnExit
        >
          <Box className={`${styles.inputRow} forcarriers-market-tour-3`}>
            <TextField
              value={parseDistance(
                state.searchValues['originSearch.deadheadMeters'] ?? 0,
                'meters',
                appConfig.DEFAULT_DISTANCE_UNIT,
              )}
              type={'number'}
              label={t('Market:search.originDeadhead')}
              onInput={(e: FormEvent<HTMLInputElement>) => {
                dispatch({
                  command: 'handleChangeOriginDeadhead',
                  payload: parseDistance(
                    Number((e.target as HTMLInputElement).value),
                    appConfig.DEFAULT_DISTANCE_UNIT,
                    'meters',
                  ),
                })
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    {parseDistanceUnitToSymbol(appConfig.DEFAULT_DISTANCE_UNIT)}
                  </InputAdornment>
                ),
              }}
              onWheel={(e) => (e.target as HTMLInputElement).blur()}
            />
            <TextField
              value={parseDistance(
                state.searchValues['destinationSearch.deadheadMeters'] ?? 0,
                'meters',
                appConfig.DEFAULT_DISTANCE_UNIT,
              )}
              type={'number'}
              label={t('Market:search.destinationDeadhead')}
              onInput={(e: FormEvent<HTMLInputElement>) => {
                dispatch({
                  command: 'handleChangeDestinationDeadhead',
                  payload: parseDistance(
                    Number((e.target as HTMLInputElement).value),
                    appConfig.DEFAULT_DISTANCE_UNIT,
                    'meters',
                  ),
                })
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    {parseDistanceUnitToSymbol(appConfig.DEFAULT_DISTANCE_UNIT)}
                  </InputAdornment>
                ),
              }}
              onWheel={(e) => (e.target as HTMLInputElement).blur()}
            />
          </Box>
        </Collapse>
      </Box>
      <Box className={styles.endColumn}>
        <Box className={`${styles.inputRow} ${styles.buttonsRow}`}>
          <Button
            variant={'contained'}
            onClick={() => dispatch({ command: 'toggleExpand' })}
            className='forcarriers-market-tour-2'
          >
            {state.isSearchExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </Button>
          <Button
            variant={'contained'}
            onClick={() => dispatch({ command: 'handleSearch', payload: props.handleSearch })}
            className='forcarriers-market-tour-4'
          >
            <SearchIcon />
          </Button>
          <Button
            variant={'contained'}
            onClick={props.handleViewToggle}
            className='forcarriers-market-tour-7'
          >
            {props.isMapView ? <FormatListBulletedIcon /> : <MapIcon />}
          </Button>
        </Box>
        <Collapse
          in={state.isSearchExpanded}
          timeout={'auto'}
          unmountOnExit
          className='forcarriers-market-tour-3'
        >
          <TrailerSelect
            value={state.searchValues.equipment?.split(',') ?? []}
            onChange={(v) =>
              dispatch({
                command: 'handleEquipmentChange',
                payload: v,
              })
            }
          />
        </Collapse>
      </Box>
    </OptionsCard>
  )
}

export default CarrierLoadOptionCard
