import L, { LatLngTuple } from 'leaflet'

import { DeadheadRadius, DistanceUnit } from '@/pkg/market/models'

import { km2mil, mil2km } from './calcDist'

export interface IUpdateMap {
  deadheadRadius: DeadheadRadius
  unit: DistanceUnit
  position?: LatLngTuple
  radius: number
  updatedRadius: number
  circle: L.Circle | null
  setCircle: (val: L.Circle | null) => void
  setRadius: (val: number) => void
  _L: typeof L
  map: L.Map
  debounce: number
}

const updateMap = ({
  deadheadRadius,
  unit,
  position,
  radius,
  updatedRadius,
  circle,
  setCircle,
  setRadius,
  _L,
  map,
  debounce,
}: IUpdateMap) => {
  const _deadheadRadius = deadheadRadius ?? {
    value: radius,
    unit,
  }

  let radiusConverted = radius

  if (_deadheadRadius.unit === 'Kilometers' && unit === 'Miles') {
    radiusConverted = mil2km(radiusConverted)
  } else if (unit === 'Kilometers' && _deadheadRadius.unit === 'Miles') {
    radiusConverted = km2mil(radiusConverted)
  }

  if (!position || updatedRadius === radiusConverted || debounce % 2 === 0) {
    return
  }

  if (circle) {
    circle.setLatLng(position).setRadius(radiusConverted * 1000)
  } else {
    const newCircle = _L
      .circle(position, {
        radius: radiusConverted * 1000,
      })
      .addTo(map)

    setCircle(newCircle)
  }

  setRadius(radiusConverted)
}

export default updateMap
