import styles from './card.module.scss'
import dayjs from 'dayjs'
import { ILoad } from '@pkg/loads/models'
import { ITheme } from '@/pkg/sokarUI'
import appConfig from '@/config'

import { Box, Stack, Paper, Divider, Typography, Skeleton, useTheme } from '@mui/material'

import PersonIcon from '@mui/icons-material/Person'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'

interface ILoadCardProps {
  data: ILoad
  openDialog: (row: ILoad) => void
}

const LoadCard = ({ ...props }: ILoadCardProps): JSX.Element => {
  const appTheme: ITheme = useTheme()

  if (!props.data) {
    return <Skeleton height={200} />
  }

  return (
    <Paper
      className={styles.wrapper}
      onClick={() => props.openDialog(props.data)}
      sx={{ backgroundColor: appTheme.palette.background.default }}
    >
      <Stack
        direction={'column'}
        spacing={2}
      >
        <Typography>{props.data.externalId}</Typography>
        <Divider />
        <Typography>
          {props.data.stops[0].city} {props.data.stops[0].zipCode}
        </Typography>
        <Typography>{dayjs(props.data.stops[0].date).format(appConfig.DATE_FORMAT)}</Typography>
        <ArrowDownwardIcon />
        <Typography>
          {props.data.stops[props.data.stops.length - 1].city}{' '}
          {props.data.stops[props.data.stops.length - 1].zipCode}
        </Typography>
        <Typography>
          {dayjs(props.data.stops[props.data.stops.length - 1].date).format(appConfig.DATE_FORMAT)}
        </Typography>
        <Divider />
        <Box className={styles.text}>
          <PersonIcon />
          {props.data.drivers && (
            <Typography>
              {props.data.drivers[0].firstName} {props.data.drivers[0].lastName}
            </Typography>
          )}
        </Box>
      </Stack>
    </Paper>
  )
}

export default LoadCard
