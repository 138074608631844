import { Route, Routes } from 'react-router-dom'
import RequireAuth from '@features/auth/RequireAuth'

import ExternalLoads from './views/ExternalLoads'
import CarrierMarket from './views/CarrierMarket'
import OffersManager from './views/OffersManager'
import OngoingTransports from './views/OngoingTransports'
import ClientOffers from './views/ClientOffers'
import ClientOffersManager from './views/ClientOffersManager'

const Fleet = () => {
  return (
    <Routes>
      <Route element={<RequireAuth allowedRoles={['employee', 'dispatcher']} />}>
        <Route
          path={'/external'}
          element={<ExternalLoads />}
        />
      </Route>
      <Route element={<RequireAuth allowedRoles={['employee', 'dispatcher', 'carrier']} />}>
        <Route
          path={'/for-carriers'}
          element={<CarrierMarket />}
        />
      </Route>
      <Route element={<RequireAuth allowedRoles={['employee', 'dispatcher']} />}>
        <Route
          path={'/offers-manager'}
          element={<OffersManager />}
        />
      </Route>
      <Route element={<RequireAuth allowedRoles={['employee', 'dispatcher']} />}>
        <Route
          path={'/ongoing'}
          element={<OngoingTransports />}
        />
      </Route>
      <Route element={<RequireAuth allowedRoles={['employee', 'dispatcher']} />}>
        <Route
          path={'/for-brokers'}
          element={<ClientOffers />}
        />
      </Route>
      <Route element={<RequireAuth allowedRoles={['client']} />}>
        <Route
          path={'/transport-needs'}
          element={<ClientOffersManager />}
        />
      </Route>
    </Routes>
  )
}

export default Fleet
