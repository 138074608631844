/* eslint-disable react/display-name */
import '../styles.scss'

import CloseIcon from '@mui/icons-material/Close'
import DoneIcon from '@mui/icons-material/Done'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from '@mui/material'
import { t } from 'i18next'
import { forwardRef } from 'react'
import { ScrollerProps, TableBodyProps, TableComponents, TableProps } from 'react-virtuoso'

import { IFormattedMarket, IMarketColumn, IMarketStop } from '@/pkg/market/models'
import { ITheme } from '@/pkg/sokarUI'

const tableHeaders: IMarketColumn[] = [
  { label: t('Market:table.externalProvider'), dataKey: 'externalProvider' },
  { label: t('Market:table.stops'), dataKey: 'stops' },
  { label: t('Market:table.rateValue'), dataKey: 'rateValue' },
  { label: t('Market:table.mode'), dataKey: 'mode' },
  { label: t('Market:table.equipmentType'), dataKey: 'equipmentType' },
  { label: t('Market:table.loadDistance'), dataKey: 'loadDistance' },
  { label: t('Market:table.weight'), dataKey: 'weight' },
  { label: t('Market:table.preCoolTemperature'), dataKey: 'preCoolTemperature' },
  { label: t('Market:table.airRide'), dataKey: 'airRide' },
  { label: t('Market:table.seal'), dataKey: 'seal' },
  { label: t('Market:table.foodGrade'), dataKey: 'foodGrade' },
]

export const MarketVirtuosoTableComponents: TableComponents<IFormattedMarket> = {
  Scroller: forwardRef<HTMLDivElement, ScrollerProps>((props, ref) => (
    <TableContainer
      component={Paper}
      {...props}
      ref={ref}
    />
  )),
  Table: (props: TableProps) => {
    const appTheme: ITheme = useTheme()
    return (
      <Table
        {...props}
        sx={{
          borderCollapse: 'separate',
          tableLayout: 'auto',
          backgroundColor: appTheme.palette.background.default,
        }}
      />
    )
  },
  // @ts-ignore
  TableHead,
  TableRow: ({ item: _item, ...props }) => (
    <TableRow
      className='market table-row'
      {...props}
    />
  ),
  TableBody: forwardRef<HTMLTableSectionElement, TableBodyProps>((props, ref) => (
    <TableBody
      {...props}
      ref={ref}
    />
  )),
}

export const MarketFixedHeaderContent = () => {
  const appTheme: ITheme = useTheme()
  return (
    <TableRow>
      {tableHeaders.map((header: IMarketColumn) => (
        <TableCell
          key={header.dataKey}
          variant='head'
          className='market table-header'
          align='center'
          sx={{
            backgroundColor: appTheme.palette.primary.main,
            color: appTheme.palette.text.light,
          }}
        >
          {header.label}
        </TableCell>
      ))}
    </TableRow>
  )
}

const MarketRowSwitch = (
  dataKey: string,
  row: IFormattedMarket,
  greenColor: string,
  redColor: string,
) => {
  switch (dataKey) {
    case 'equipmentHeight':
    case 'equipmentLength':
    case 'equipmentWidth':
    case 'loadDistance':
    case 'weight':
    case 'preCoolTemperature':
    case 'maximumTemperature':
    case 'minimumTemperature':
      return `${row[dataKey].value} ${row[dataKey].unit === 'Undefined' ? '' : row[dataKey].unit}`
    case 'airRide':
    case 'seal':
    case 'foodGrade':
      return row[dataKey as keyof IFormattedMarket] ?
          <DoneIcon sx={{ color: greenColor }} />
        : <CloseIcon sx={{ color: redColor }} />
    case 'rateValue':
      return `${row[dataKey as keyof IFormattedMarket]} ${row.rateCurrencyType}`
    case 'stops':
      return `${(row[dataKey as keyof IFormattedMarket] as IMarketStop[])[0].city} - ${(row[dataKey as keyof IFormattedMarket] as IMarketStop[])[(row[dataKey as keyof IFormattedMarket] as IMarketStop[]).length - 1].city}`

    default:
      return `${row[dataKey as keyof IFormattedMarket]}`
  }
}

export const MarketRowContent = (_: number, row: IFormattedMarket, dialogRef: any) => {
  const appTheme: ITheme = useTheme()
  return (
    <>
      {tableHeaders.map(({ dataKey }: IMarketColumn) => (
        <TableCell
          key={dataKey}
          className={`market table-cell ${dataKey}`}
          onClick={() => dialogRef.current?.open(true, row)}
          align='center'
        >
          {MarketRowSwitch(
            dataKey,
            row,
            appTheme.palette.success.main,
            appTheme.palette.error.main,
          )}
        </TableCell>
      ))}
    </>
  )
}
