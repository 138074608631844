import { apiSlice } from '@api/apiSlice'
import { IPublisherOffer } from '@pkg/publishers/models'

const baseURL = '/load-publisher'

export const publisherApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAvailablePublishers: builder.query<Record<string, boolean>, void>({
      query: () => ({
        url: `${baseURL}/external-publishers`,
      }),
    }),
    getRedirectURL: builder.query<{ redirectUrl: string }, string>({
      query: (integration) => ({
        url: `${baseURL}/auth-redirect-url?externalProvider=${integration}`,
      }),
    }),
    getPublishedOffers: builder.query<
      IPublisherOffer[],
      { id?: string; customerId?: string; loadId?: string; published?: boolean }
    >({
      query: (params) => ({
        url: `${baseURL}/offers`,
        params: { ...params },
      }),
      transformResponse: (response: { offers: IPublisherOffer[] }) => response.offers,
    }),
    retryOffer: builder.mutation<object, string>({
      query: (id) => ({
        url: `${baseURL}/retry-offer`,
        body: { id },
        method: 'POST',
      }),
    }),
  }),
})

export const {
  useGetAvailablePublishersQuery,
  useGetRedirectURLQuery,
  useGetPublishedOffersQuery,
  useRetryOfferMutation,
} = publisherApiSlice
