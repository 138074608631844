import { useCreateDriverMutation } from '@features/drivers/driversApiSlice'
import {
  useCreateTrailerMutation,
  useCreateTruckMutation,
} from '@features/equipment/equipmentApiSlice'
import { useCreateLoadMutation } from '@features/loads/loadsApiSlice'
import { yupResolver } from '@hookform/resolvers/yup'
import useAlert from '@hooks/useAlert'
import useOwnUser from '@hooks/useOwnUser'
import PhoneNumberField from '@lib/PhoneNumberField'
import {
  Box,
  Button,
  Checkbox,
  MenuItem,
  Stack,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from '@mui/material'
import { IDriverCreateRequest } from '@pkg/drivers/requests'
import { ICreateTrailerRequest, ICreateTruckRequest } from '@pkg/equipment/requests'
import { initICreateCarrierLoadRequest, initICreateStopRequest } from '@pkg/loads/initializers'
import { ICreateCarrierInstanceLoadRequest } from '@pkg/loads/requests'
import { brokerInstancePublishedLoadValidator } from '@pkg/loads/validators'
import MultipleDriversSearch from '@shared/components/multipleDriversSearch'
import TrailerSearch from '@shared/components/trailerSearch'
import TruckSearch from '@shared/components/truckSearch'
import { parseError } from '@shared/utils/formatters'
import { getRegion } from '@shared/utils/formatters'
import { Reducer, useEffect, useReducer, useState } from 'react'
import { Controller, FieldErrors, useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import appConfig from '@/config'
import CompanySearch from '@/lib/CompanySearch'
import { TruckStopForm } from '@/lib/components'
import EmployeeSelect from '@/lib/EmployeeSelect'
import TrailerSelect from '@/lib/TrailerSelect'

import styles from './loadDialog.module.scss'

const steps = ['add stops & basic info', 'companies & pay', 'driver & equipment']

interface ICreateLoadProps {
  refetch: () => void
  handleClose: () => void
}

interface IChangeCompanyPayload {
  name: string
  id: string
}

type CreateOngoingTransportActions =
  | { command: 'changeShipper'; payload: IChangeCompanyPayload }
  | { command: 'changeCarrier'; payload: IChangeCompanyPayload }

interface CreateOngoingTransportState {
  shipper: IChangeCompanyPayload
  carrier: IChangeCompanyPayload
}

const createOngoingTransportReducer: Reducer<
  CreateOngoingTransportState,
  CreateOngoingTransportActions
> = (state, action) => {
  switch (action.command) {
    case 'changeShipper':
      return {
        ...state,
        shipper: action.payload,
      }
    case 'changeCarrier':
      return {
        ...state,
        carrier: action.payload,
      }
    default:
      return state
  }
}

const CreateLoadDialog = ({ refetch, handleClose }: ICreateLoadProps): JSX.Element => {
  const { t } = useTranslation()
  const [state, dispatch] = useReducer(createOngoingTransportReducer, {
    carrier: {
      id: '',
      name: '',
    },
    shipper: {
      id: '',
      name: '',
    },
  })
  const country: string | null = localStorage.getItem('country')
  const [createLoad] = useCreateLoadMutation()
  const { dispatch: dispatchAlert } = useAlert()

  const isStepFailed = (
    index: number,
    errors: FieldErrors<ICreateCarrierInstanceLoadRequest>,
  ): boolean => {
    const errorKeys0: string[] = ['stops', 'weight']
    const errorKeys1: string[] = ['shipperPrice', 'carrierPrice', 'shipperId', 'carrierId']
    const errorKeys2: string[] = ['truckId', 'trailerId', 'driverIds']
    switch (index) {
      case 0:
        return errorKeys0.some((key) => key in errors)
      case 1:
        return errorKeys1.some((key) => key in errors)
      case 2:
        return errorKeys2.some((key) => key in errors)
      default:
        return false
    }
  }

  const [createDriver] = useCreateDriverMutation()
  const [createTruck] = useCreateTruckMutation()
  const [createTrailer] = useCreateTrailerMutation()
  const { companyId } = useOwnUser()

  const {
    control: trailerControl,
    getValues: getTrailer,
    formState: { errors: trailerErrors },
  } = useForm<ICreateTrailerRequest>()

  const {
    control: truckControl,
    getValues: getTruck,
    formState: { errors: truckErrors },
  } = useForm<ICreateTruckRequest>()

  const {
    control: driverControl,
    getValues: getDriver,
    formState: { errors: driverErrors },
  } = useForm<IDriverCreateRequest>()

  const {
    control,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
    clearErrors,
  } = useForm<ICreateCarrierInstanceLoadRequest>({
    defaultValues: initICreateCarrierLoadRequest(),
    resolver: yupResolver(brokerInstancePublishedLoadValidator),
  })

  const {
    fields: stops,
    append,
    remove,
    swap,
  } = useFieldArray({
    control,
    name: 'stops',
  })

  const moveUp = (index: number) => {
    if (index > 0) swap(index, index - 1)
  }

  const moveDown = (index: number) => {
    if (index < stops.length - 1) swap(index, index + 1)
  }

  useEffect(() => {
    setValue('publishedPrice', 69)
  }, [])
  const onSubmit = async (loadData: ICreateCarrierInstanceLoadRequest): Promise<void> => {
    try {
      if (addDriver) {
        const driverData = getDriver()
        driverData.carrierId = loadData.carrierId
        driverData.companyId = loadData.carrierId

        const createdDriver = await createDriver(driverData).unwrap()
        loadData.driverIds = [createdDriver.id]
      }

      if (addTruck) {
        const truckData = getTruck()
        truckData.carrierId = loadData.carrierId
        truckData.companyId = loadData.carrierId

        const createdTruck = await createTruck(truckData).unwrap()
        loadData.truckId = createdTruck.id
      }

      if (addTrailer) {
        const trailerData = getTrailer()
        trailerData.carrierId = loadData.carrierId
        trailerData.companyId = loadData.carrierId

        const createdTrailer = await createTrailer(trailerData).unwrap()
        loadData.trailerId = createdTrailer.id
      }

      loadData.tags = ['ongoing-transport']

      for (const stop of loadData.stops) {
        if (stop.time && stop.date) {
          const time = new Date(stop.time)
          const date = new Date(stop.date)
          const offset = date.getTimezoneOffset()
          date.setHours(time.getHours(), time.getMinutes() - offset, 0)
          stop.date = date.toISOString()
          delete stop.time
        }
        if (stop.timeTo && stop.dateTo) {
          const time = new Date(stop.timeTo)
          const date = new Date(stop.dateTo)
          const offset = date.getTimezoneOffset()
          date.setHours(time.getHours(), time.getMinutes() - offset, 0)
          stop.dateTo = date.toISOString()
          delete stop.timeTo
        }
      }

      await createLoad(loadData).unwrap()
      dispatchAlert({
        type: 'SHOW',
        payload: {
          content: t('Market:response.transportCreated'),
          severity: 'success',
        },
      })

      reset()
      handleClose()
      refetch()
    } catch (err: any) {
      const error = parseError<any>(err?.data?.message)
      dispatchAlert({
        type: 'SHOW',
        payload: {
          content: String(t(error.dictKey, { ...error.dependencies })),
          severity: 'error',
        },
      })
    }
  }

  const [carrierId, setCarrierId] = useState<string>('')
  const handleCarrierChange = (e: string, searchPhrase: string): void => {
    setCarrierId(e)
    setValue('carrierId', e)
    dispatch({
      command: 'changeCarrier',
      payload: {
        id: e,
        name: searchPhrase,
      },
    })
  }

  const handleShipperChange = (e: string, searchPhrase: string): void => {
    setValue('shipperId', e)
    dispatch({
      command: 'changeShipper',
      payload: {
        id: e,
        name: searchPhrase,
      },
    })
  }

  const [loadCreationStep, setLoadCreationStep] = useState<number>(0)
  const [addDriver, setAddDriver] = useState<boolean>(false)
  const [addTruck, setAddTruck] = useState<boolean>(false)
  const [addTrailer, setAddTrailer] = useState<boolean>(false)

  const handleTruckStopChange = (index: number, data: any): void => {
    setValue(`stops.${index}`, { ...data })
    if (!!errors?.stops?.[index]?.city?.message && data.city) {
      clearErrors(`stops.${index}.city`)
    }
  }

  return (
    <Stack
      component={'form'}
      onSubmit={handleSubmit(onSubmit)}
      direction={'column'}
      spacing={3}
    >
      <Box>
        <Stepper activeStep={loadCreationStep}>
          {steps.map((label, index) => {
            let isError: boolean = false
            if (isStepFailed(index, errors)) {
              isError = true
            }
            return (
              <Step
                key={label}
                className={'create_load_dialog_text_step'}
                onClick={() => setLoadCreationStep(index)}
              >
                <StepLabel error={isError} />
              </Step>
            )
          })}
        </Stepper>
      </Box>
      {loadCreationStep === 0 && (
        <>
          {stops.map((field, index: number) => {
            return (
              <TruckStopForm
                id={field.id}
                index={index}
                values={field as any}
                errors={errors}
                callback={handleTruckStopChange}
                moveUp={index === 0 ? undefined : moveUp}
                remove={stops.length > 2 ? remove : undefined}
                moveDown={index === stops.length - 1 ? undefined : moveDown}
              />
            )
          })}
          <Button
            variant={'contained'}
            onClick={() => {
              append(initICreateStopRequest())
            }}
          >
            {t('Fleet:createLoadDialog.addStop')}
          </Button>
          <Typography>{t('Fleet:createLoadDialog.details')}</Typography>
          <Controller
            control={control}
            name={'type'}
            render={({ field }) => (
              <TextField
                {...field}
                select
                label={t('Fleet:createLoadDialog.type')}
                error={!!errors?.type}
                helperText={<Box height={2}>{errors?.type?.message}</Box>}
              >
                <MenuItem value={'PTL'}>{t('Fleet:createLoadDialog.type_PTL')}</MenuItem>
                <MenuItem value={'LTL'}>{t('Fleet:createLoadDialog.type_LTL')}</MenuItem>
                <MenuItem value={'FTL'}>{t('Fleet:createLoadDialog.type_FTL')}</MenuItem>
              </TextField>
            )}
          />
          <Controller
            control={control}
            name={'commodityName'}
            render={({ field }) => (
              <TextField
                {...field}
                label={t('Fleet:createLoadDialog.commodityName')}
                error={!!errors?.commodityName}
                helperText={<Box height={2}>{errors?.commodityName?.message}</Box>}
              />
            )}
          />
          <Stack
            direction={'row'}
            spacing={2}
          >
            <Controller
              control={control}
              name={'weight'}
              render={({ field }) => (
                <TextField
                  {...field}
                  type={'number'}
                  className={styles.textfield}
                  label={t('Fleet:createLoadDialog.weight')}
                  error={!!errors?.weight}
                  helperText={<Box height={2}>{errors?.weight?.message}</Box>}
                />
              )}
            />
            <Controller
              control={control}
              name={'weightUnit'}
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  className={styles.textfield}
                  label={t('Fleet:createLoadDialog.weightUnit')}
                  error={!!errors?.weightUnit}
                  helperText={<Box height={2}>{errors?.weightUnit?.message}</Box>}
                >
                  <MenuItem value={'lbs'}>{t('Fleet:createLoadDialog.weightUnit_lbs')}</MenuItem>
                  <MenuItem value={'kg'}>{t('Fleet:createLoadDialog.weightUnit_kg')}</MenuItem>
                  <MenuItem value={'t'}>{t('Fleet:createLoadDialog.weightUnit_t')}</MenuItem>
                </TextField>
              )}
            />
          </Stack>
          <Controller
            control={control}
            name={'paymentDays'}
            render={({ field }) => (
              <TextField
                select
                {...field}
                label={t('Market:offerDialog.paymentDays')}
              >
                <MenuItem value={'1'}>1</MenuItem>
                <MenuItem value={'2'}>2</MenuItem>
                <MenuItem value={'30'}>30</MenuItem>
                <MenuItem value={'49'}>49</MenuItem>
              </TextField>
            )}
          />
          <Controller
            control={control}
            name={'description'}
            render={({ field }) => (
              <TextField
                {...field}
                multiline
                rows={3}
                label={t('Fleet:createLoadDialog.description')}
                error={!!errors?.description}
                helperText={<Box height={2}>{errors?.description?.message}</Box>}
              />
            )}
          />
          <Controller
            control={control}
            name={'secondOwner'}
            render={({ field }) => (
              <EmployeeSelect
                {...field}
                onChange={(e, option) => {
                  setValue('secondOwner', option?.value)
                }}
              />
            )}
          />
          <Button
            variant={'contained'}
            type={'submit'}
            onClick={() => setLoadCreationStep((prev) => (prev += 1))}
          >
            {t('Common:next')}
          </Button>
        </>
      )}
      {loadCreationStep === 1 && (
        <>
          <Typography>{t('Market:offerDialog.shipper')}</Typography>
          <Controller
            control={control}
            name={'shipperId'}
            render={({ field }) => (
              <CompanySearch
                {...field}
                error={errors?.shipperId}
                isDisabled={false}
                onChange={handleShipperChange}
                companyName={state.shipper.name}
              />
            )}
          />
          <Stack
            direction={'row'}
            spacing={2}
          >
            <Controller
              control={control}
              name={'shipperPrice'}
              render={({ field }) => (
                <TextField
                  {...field}
                  type={'number'}
                  className={styles.textfield}
                  label={t('Market:offerDialog.shipperPrice')}
                  error={!!errors?.shipperPrice}
                  helperText={<Box height={2}>{errors?.shipperPrice?.message}</Box>}
                />
              )}
            />
            <Controller
              control={control}
              name={'shipperPriceCurrency'}
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  className={styles.textfield}
                  label={t('Market:offerDialog.shipperPriceCurrency')}
                  error={!!errors?.shipperPriceCurrency}
                  helperText={<Box height={2}>{errors?.shipperPriceCurrency?.message}</Box>}
                >
                  {/* @ts-ignore */}
                  {appConfig[getRegion(country) as keyof typeof appConfig]?.currencies?.map(
                    (currency: string) => (
                      <MenuItem value={currency}>{t('Common:currencies.' + currency)}</MenuItem>
                    ),
                  )}
                </TextField>
              )}
            />
          </Stack>
          <Typography>{t('Fleet:createLoadDialog.carrier')}</Typography>
          <Controller
            control={control}
            name={'carrierId'}
            render={({ field }) => (
              <CompanySearch
                {...field}
                onChange={handleCarrierChange}
                error={errors?.carrierId}
                isDisabled={false}
                companyName={state.carrier.name}
              />
            )}
          />
          <Stack
            direction={'row'}
            spacing={2}
          >
            <Controller
              control={control}
              name={'carrierPrice'}
              render={({ field }) => (
                <TextField
                  {...field}
                  type={'number'}
                  className={styles.textfield}
                  label={t('Market:offerDialog.carrierPrice')}
                  error={!!errors?.shipperPrice}
                  helperText={<Box height={2}>{errors?.shipperPrice?.message}</Box>}
                />
              )}
            />
            <Controller
              control={control}
              name={'carrierPriceCurrency'}
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  className={styles.textfield}
                  label={t('Market:offerDialog.carrierPriceCurrency')}
                  error={!!errors?.shipperPriceCurrency}
                  helperText={<Box height={2}>{errors?.shipperPriceCurrency?.message}</Box>}
                >
                  {/* @ts-ignore */}
                  {appConfig[getRegion(country) as keyof typeof appConfig]?.currencies?.map(
                    (currency: string) => (
                      <MenuItem value={currency}>{t('Common:currencies.' + currency)}</MenuItem>
                    ),
                  )}
                </TextField>
              )}
            />
          </Stack>
          <Stack
            direction={'row'}
            spacing={2}
          >
            <Button
              variant={'contained'}
              type={'submit'}
              className={styles.textfield}
              onClick={() => setLoadCreationStep((prev) => (prev -= 1))}
            >
              {t('Common:previous')}
            </Button>
            <Button
              variant={'contained'}
              type={'submit'}
              className={styles.textfield}
              onClick={() => setLoadCreationStep((prev) => (prev += 1))}
            >
              {t('Common:next')}
            </Button>
          </Stack>
        </>
      )}
      {loadCreationStep === 2 && (
        <>
          <Controller
            control={control}
            name={'driverIds'}
            render={({ field }) => (
              <MultipleDriversSearch
                {...field}
                error={errors?.driverIds}
                isDisabled={!carrierId || addDriver}
                companyId={carrierId}
              />
            )}
          />
          <Stack
            direction={'row'}
            spacing={2}
            className={styles.addModelStack}
          >
            <Checkbox
              onClick={() => setAddDriver((prev) => !prev)}
              disabled={!carrierId || carrierId === companyId}
            />
            <Typography>{t('Market:offerDialog.addDriver')}</Typography>
          </Stack>
          {addDriver && (
            <>
              <Controller
                control={driverControl}
                name={'firstName'}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t('Market:offerDialog.name')}
                    error={!!driverErrors?.firstName}
                    helperText={<Box height={2}>{driverErrors?.firstName?.message}</Box>}
                  />
                )}
              />
              <Controller
                control={driverControl}
                name={'lastName'}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t('Market:offerDialog.surname')}
                    error={!!driverErrors?.lastName}
                    helperText={<Box height={2}>{driverErrors?.lastName?.message}</Box>}
                  />
                )}
              />
              <Controller
                control={driverControl}
                name={'phone'}
                render={({ field }) => (
                  <PhoneNumberField
                    {...field}
                    error={driverErrors.phone}
                  />
                )}
              />
              <Controller
                control={driverControl}
                name={'socialSecurity'}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t('Market:offerDialog.socialSecurity')}
                    error={!!driverErrors?.socialSecurity}
                    helperText={<Box height={2}>{driverErrors?.socialSecurity?.message}</Box>}
                  />
                )}
              />
            </>
          )}
          <Controller
            control={control}
            name={'truckId'}
            render={({ field }) => (
              <TruckSearch
                {...field}
                error={errors?.truckId}
                isDisabled={!carrierId}
                companyId={carrierId}
              />
            )}
          />
          <Stack
            direction={'row'}
            spacing={2}
            className={styles.addModelStack}
          >
            <Checkbox
              onClick={() => setAddTruck((prev) => !prev)}
              disabled={!carrierId || carrierId === companyId}
            />
            <Typography>{t('Market:offerDialog.addTruck')}</Typography>
          </Stack>
          {addTruck && (
            <>
              <Controller
                control={truckControl}
                name={'plate'}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t('Market:offerDialog.plates')}
                    error={!!truckErrors?.plate}
                    helperText={<Box height={2}>{truckErrors?.plate?.message}</Box>}
                  />
                )}
              />
              <Controller
                control={truckControl}
                name={'number'}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t('Market:offerDialog.number')}
                  />
                )}
              />
            </>
          )}
          <Controller
            control={control}
            name={'trailerId'}
            render={({ field }) => (
              <TrailerSearch
                {...field}
                error={errors?.trailerId}
                isDisabled={!carrierId}
                companyId={carrierId}
              />
            )}
          />
          <Stack
            direction={'row'}
            spacing={2}
            className={styles.addModelStack}
          >
            <Checkbox
              onClick={() => setAddTrailer((prev) => !prev)}
              disabled={!carrierId || carrierId === companyId}
            />
            <Typography>{t('Market:offerDialog.addTrailer')}</Typography>
          </Stack>
          {addTrailer && (
            <>
              <Controller
                control={trailerControl}
                name={'plate'}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t('Market:offerDialog.plates')}
                    error={!!trailerErrors?.plate}
                    helperText={<Box height={2}>{trailerErrors?.plate?.message}</Box>}
                  />
                )}
              />
              <Controller
                control={trailerControl}
                name={'year'}
                render={({ field }) => (
                  <TextField
                    {...field}
                    type={'number'}
                    label={t('Market:offerDialog.year')}
                    error={!!trailerErrors?.year}
                    helperText={<Box height={2}>{trailerErrors?.year?.message}</Box>}
                  />
                )}
              />
              <Controller
                control={trailerControl}
                name={'number'}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t('Market:offerDialog.number')}
                  />
                )}
              />
              <Controller
                control={trailerControl}
                name={'category'}
                render={({ field }) => (
                  <TrailerSelect
                    {...field}
                    error={trailerErrors.category}
                  />
                )}
              />
            </>
          )}
          <Stack
            direction={'row'}
            spacing={2}
          >
            <Button
              variant={'contained'}
              type={'submit'}
              className={styles.textfield}
              onClick={() => setLoadCreationStep((prev) => (prev -= 1))}
            >
              {t('Common:previous')}
            </Button>
            <Button
              variant={'contained'}
              type={'submit'}
              className={styles.textfield}
            >
              {t('Common:create')}
            </Button>
          </Stack>
        </>
      )}
    </Stack>
  )
}

export default CreateLoadDialog
