import { StepType } from '@reactour/tour'
import { t } from 'i18next'

export const forCarriersMarketSteps: StepType[] = [
  {
    selector: '.forcarriers-market-tour-1',
    content: t('Market:onboarding.forCarriers.step1') as string,
  },
  {
    selector: '.forcarriers-market-tour-2',
    content: t('Market:onboarding.forCarriers.step2') as string,
  },
  {
    selector: '.forcarriers-market-tour-3',
    content: t('Market:onboarding.forCarriers.step3') as string,
  },
  {
    selector: '.forcarriers-market-tour-4',
    content: t('Market:onboarding.forCarriers.step4') as string,
  },
  {
    selector: '.forcarriers-market-tour-5',
    content: t('Market:onboarding.forCarriers.step5') as string,
  },
  {
    selector: '.forcarriers-market-tour-6',
    content: t('Market:onboarding.forCarriers.step6') as string,
  },
  {
    selector: '.forcarriers-market-tour-7',
    content: t('Market:onboarding.forCarriers.step7') as string,
  },
  {
    selector: '.forcarriers-market-tour-8',
    content: t('Market:onboarding.forCarriers.step8') as string,
  },
  {
    selector: '.forcarriers-market-tour-9',
    content: t('Market:onboarding.forCarriers.step9') as string,
  },
  {
    selector: '.forcarriers-market-tour-10',
    content: t('Market:onboarding.forCarriers.step10') as string,
  },
]
