import { useGetTrailerQuery, useSearchTrailerQuery } from '@features/equipment/equipmentApiSlice'
import useOwnUser from '@hooks/useOwnUser'
import useRefetchWithAlert from '@hooks/useRefetchWithAlert'
import ConfirmationDialog, { IConfirmationDialogRef } from '@lib/ConfirmationDialog'
import ContentList, { IContentListRef } from '@lib/ContentList'
import ContentTable, { ITableRef } from '@lib/ContentTable'
import SideDialog, { ISideDialogRef } from '@lib/SideDialog'
import AddIcon from '@mui/icons-material/Add'
import ReplayIcon from '@mui/icons-material/Replay'
import { Box, IconButton, Skeleton, Tooltip, useMediaQuery, useTheme } from '@mui/material'
import { ITrailer } from '@pkg/equipment/models'
import { ITheme } from '@pkg/sokarUI'
import { t } from 'i18next'
import { FC, useCallback, useEffect, useRef, useState } from 'react'

import { useSokarTour } from '@/hooks/useSokarTour'

import CreateTrailerDialog from './components/createTrailerDialog'
import TrailerCard from './components/trailerCard'
import TrailerDialog from './components/trailerDialog'
import TrailerOptionsCard from './components/trailersOptionsCard'
import styles from './trailers.module.scss'
import {
  trailerTableCellRenderer as cellRenderer,
  trailerTableColumns as columns,
} from './view.helpers'

const TrailersView: FC = () => {
  const { companyId } = useOwnUser()
  const appTheme: ITheme = useTheme()
  const isMobile: boolean = useMediaQuery(appTheme.breakpoints.down('md'))

  const createTrailerDialogRef = useRef<ISideDialogRef>(null)
  const handleCreateTrailerDialogOpen = (): void => {
    createTrailerDialogRef.current?.open({})
  }
  const handleCreateTrailerDialogClose = (): void => {
    createTrailerDialogRef.current?.close()
  }

  const trailerDialogRef = useRef<ISideDialogRef>(null)
  const handleTrailerDialogOpen = useCallback((row: ITrailer): void => {
    trailerDialogRef.current?.open(row)
  }, [])
  const handleTrailerDialogClose = useCallback((): void => {
    trailerDialogRef.current?.close()
  }, [])

  const {
    data: trailers,
    isSuccess: isTrailersSuccess,
    refetch: refetchTrailers,
  } = useGetTrailerQuery({ companyId: companyId ?? '', archived: false })
  const refresh = useRefetchWithAlert(refetchTrailers)

  const { setIsOpen, currentStep } = useSokarTour(
    !!trailers,
    true,
    'trailers-fleet-tour',
    'Fleet:onboarding.trailers',
    10,
  )

  useEffect(() => {
    if (currentStep >= 2 && trailers?.length === 0) {
      setIsOpen(false)
    }
    if (currentStep === 2 && trailers) {
      trailerDialogRef.current?.open(trailers[0])
    }
  }, [currentStep, setIsOpen, trailers])

  const [isTrailersSearched, setIsTrailersSearched] = useState<boolean>(false)
  const [searchPhrase, setSearchPhrase] = useState<string>('')
  const handleSearchTrailers = (phrase: string, isSearched: boolean): void => {
    setIsTrailersSearched(isSearched)
    setSearchPhrase(phrase)
  }

  const { data: searchedTrailers } = useSearchTrailerQuery(
    { phrase: searchPhrase, archived: false },
    { skip: !isTrailersSearched },
  )

  const freeActions: JSX.Element = (
    <>
      <Tooltip
        title={t('Fleet:trailersTable.add')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={handleCreateTrailerDialogOpen}
          className='trailers-fleet-tour-2'
        >
          <AddIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('Fleet:trailersTable.refresh')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => refresh()}
        >
          <ReplayIcon />
        </IconButton>
      </Tooltip>
    </>
  )

  const ref = useRef<ITableRef>(null)
  const listRef = useRef<IContentListRef>(null)
  useEffect((): void => {
    if (isTrailersSearched && !isMobile) {
      ref.current?.open(searchedTrailers, columns)
      return
    }
    if (isTrailersSearched && isMobile) {
      listRef.current?.open(searchedTrailers)
      return
    }
    if (isMobile) {
      listRef.current?.open(trailers)
      return
    }
    ref.current?.open(trailers, columns)
  }, [isMobile, trailers, isTrailersSearched, searchedTrailers])

  const confirmationDialogRef = useRef<IConfirmationDialogRef>(null)

  const getProperComponent = (): JSX.Element => {
    if (isMobile) {
      return (
        <ContentList
          ref={listRef}
          openDialog={handleTrailerDialogOpen}
          listComponent={TrailerCard}
        />
      )
    }
    return (
      <ContentTable
        ref={ref}
        freeActions={freeActions}
        cellRenderer={cellRenderer}
        openDialog={handleTrailerDialogOpen}
      />
    )
  }

  if (!isTrailersSuccess) {
    return (
      <Box className={styles.loader}>
        {Array(12)
          .fill(null)
          .map((_, index) => (
            <Skeleton
              key={index}
              animation={'wave'}
            />
          ))}
      </Box>
    )
  }

  return (
    <>
      <TrailerOptionsCard handleSearch={handleSearchTrailers} />
      {getProperComponent()}
      <SideDialog
        ref={createTrailerDialogRef}
        title={t('Fleet:createTrailerDialog.title')}
      >
        <CreateTrailerDialog
          refetch={refetchTrailers}
          handleClose={handleCreateTrailerDialogClose}
        />
      </SideDialog>
      <SideDialog
        ref={trailerDialogRef}
        title={t('Fleet:trailerDialog.title')}
      >
        <TrailerDialog
          refetch={refetchTrailers}
          handleClose={handleTrailerDialogClose}
          elementRef={trailerDialogRef}
          confirmationRef={confirmationDialogRef}
        />
      </SideDialog>
      <ConfirmationDialog ref={confirmationDialogRef} />
    </>
  )
}

export default TrailersView
