import { getRegion } from '@/shared/utils/formatters'
import { createFilterOptions } from '@mui/material'
import { countryCodes } from '@pkg/countryCodes'
import { t } from 'i18next'

export interface ICountrySelectOption {
  label: string
  value: string
}

export const parseCountriesForSelect = (): ICountrySelectOption[] => {
  const country: string | null = localStorage.getItem('country')

  return Object.keys(countryCodes)
    .filter((code) => countryCodes[code as keyof typeof countryCodes] === getRegion(country ?? ''))
    .map((c: string) => ({
      value: c,
      label: c,
    }))
}

export const parseCountriesForFilter = createFilterOptions({
  stringify: (option: ICountrySelectOption) =>
    `(${option.value}) ${t(`Common:countries.${option.value}`)}`,
})
