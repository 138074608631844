import CityField from '@lib/CityField'
import CountrySelect from '@lib/CountrySelect'
import DateField from '@lib/DateField'
import {
  Delete as DeleteIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material'
import {
  Box,
  IconButton,
  MenuItem,
  Skeleton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { LocalizationProvider, TimeField } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { ICity, IStop } from '@pkg/loads/models'
import { assert } from '@shared/utils/utilities'
import dayjs, { Dayjs } from 'dayjs'
import { t } from 'i18next'
import { memo, Reducer, useEffect, useReducer } from 'react'

import styles from './truckStop.module.scss'

interface TruckStopFormProps {
  id: string
  index: number
  values?: IStop & { id: string }
  errors?: any
  moveUp?: (index: number) => void
  callback: (index: number, state: TruckStopFormState) => void
  moveDown?: (index: number) => void
  remove?: (index: number) => void
}

interface TruckStopFormState {
  city: string
  lat: number
  lon: number
  zipCode: string
  countryAbbreviation: string
  category: string
  date: Date
  dateTo: Date
  isDateTouched?: boolean
  street: string
  facility: string
  description: string
}

const truckStopDefaults: TruckStopFormState = {
  city: '',
  lat: 0,
  lon: 0,
  zipCode: '',
  countryAbbreviation: '',
  category: '',
  date: new Date(),
  dateTo: new Date(),
  isDateTouched: false,
  street: '',
  facility: '',
  description: '',
}

type TruckStopFormActions =
  | { command: 'handleValuesChange'; payload: any }
  | { command: 'handleCountryChange'; payload: string }
  | {
      command: 'handleCityChange'
      payload: ICity
    }
  | {
      command: 'handleCategoryChange'
      payload: string
    }
  | { command: 'handleDateChange'; payload: Date }
  | { command: 'handleDateToChange'; payload: Date }
  | { command: 'handleTimeChange'; payload: Dayjs | null }
  | { command: 'handleTimeToChange'; payload: Dayjs | null }
  | { command: 'handleDateToTouch' }
  | { command: 'handleAddressChange'; payload: string }
  | { command: 'handleFacilityChange'; payload: string }
  | { command: 'handleDescriptionChange'; payload: string }

const truckStopFormReducer: Reducer<TruckStopFormState, TruckStopFormActions> = (state, action) => {
  switch (action.command) {
    case 'handleValuesChange':
      return {
        ...state,
        ...action.payload,
      }
    case 'handleCityChange':
      return {
        ...state,
        city: action.payload.name,
        lat: action.payload.latitude,
        lon: action.payload.longitude,
        zipCode: action.payload.postalCode,
      }
    case 'handleCountryChange':
      assert(action.payload !== null, 'country cannot be NULL')

      return {
        ...state,
        city: '',
        latitude: 0,
        longitude: 0,
        countryAbbreviation: action.payload,
      }
    case 'handleCategoryChange':
      assert(['P', 'R', 'D'].includes(action.payload), 'out of range value provided for stop field')

      return {
        ...state,
        category: action.payload,
      }
    case 'handleDateChange':
      return {
        ...state,
        date: action.payload,
        dateTo: state.isDateTouched ? state.dateTo : action.payload,
      }
    case 'handleDateToChange':
      return {
        ...state,
        dateTo: action.payload,
        isDateTouched: true,
      }
    case 'handleTimeChange':
      const t = dayjs(action.payload)
      const nd = new Date(state.date)

      if (t.isValid()) {
        nd.setHours(t.hour())
        nd.setMinutes(t.minute())
      } else {
        nd.setHours(0)
        nd.setMinutes(0)
      }

      return {
        ...state,
        date: nd,
      }
    case 'handleTimeToChange':
      const tt = dayjs(action.payload)
      const ndt = new Date(state.dateTo)

      if (tt.isValid()) {
        ndt.setHours(tt.hour())
        ndt.setMinutes(tt.minute())
      } else {
        ndt.setHours(0)
        ndt.setMinutes(0)
      }

      return {
        ...state,
        dateTo: ndt,
      }
    case 'handleDateToTouch':
      return {
        ...state,
        isDateTouched: true,
      }
    case 'handleAddressChange':
      return {
        ...state,
        street: action.payload,
      }
    case 'handleFacilityChange':
      return {
        ...state,
        facility: action.payload,
      }
    case 'handleDescriptionChange':
      return {
        ...state,
        description: action.payload,
      }
    default:
      return state
  }
}

const TruckStopForm = memo(
  ({
    id,
    index,
    errors,
    values,
    moveUp,
    moveDown,
    callback,
    remove,
  }: TruckStopFormProps): JSX.Element => {
    const [state, dispatch] = useReducer(truckStopFormReducer, truckStopDefaults)

    useEffect((): void => {
      if (values !== undefined) {
        dispatch({ command: 'handleValuesChange', payload: values })
        if (values.time !== undefined) {
          dispatch({ command: 'handleTimeChange', payload: dayjs(values.time) })
        }
        if (values.timeTo !== undefined) {
          dispatch({ command: 'handleTimeToChange', payload: dayjs(values.timeTo) })
        }
      }
    }, [values])

    useEffect((): void => {
      const cstate = { ...state }
      delete cstate.isDateTouched
      callback(index, state)
    }, [state])

    return (
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        key={id}
      >
        <Box
          key={id}
          className={'columnBox'}
        >
          <Stack
            direction={'row'}
            spacing={2}
          >
            <Typography>{t('Market:offerDialog.stopTitle', { noStop: index + 1 })}</Typography>
            <span className={styles.textField} />
            <Tooltip title={t('Market:offerDialog.moveDown')}>
              <IconButton
                disabled={!moveDown}
                onClick={() => moveDown && moveDown(index)}
              >
                <ExpandMoreIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={t('Fleet:offerLoadDialog.moveUp')}>
              <IconButton
                onClick={() => moveUp && moveUp(index)}
                disabled={!moveUp}
              >
                <ExpandLessIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={t('Market:offerDialog.deleteStop', { noStop: index + 1 })}>
              <IconButton
                disabled={!remove}
                onClick={() => remove && remove(index)}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Stack>
          <Stack
            direction={'column'}
            spacing={4}
          >
            <Stack
              direction={'row'}
              spacing={2}
            >
              {!state.countryAbbreviation ? (
                <Skeleton height={60} />
              ) : (
                <CountrySelect
                  value={state.countryAbbreviation}
                  className={styles.textField}
                  onChange={(e, option) => {
                    assert(option !== null, 'forbidden value of NULL for country field')

                    dispatch({
                      command: 'handleCountryChange',
                      payload: option.value,
                    })
                  }}
                  error={errors?.stops?.[index]?.countryAbbreviation}
                />
              )}
              <CityField
                index={index}
                value={state.city}
                key={`${state.countryAbbreviation}:${index}`}
                country={state.countryAbbreviation}
                error={errors?.stops?.[index]?.city}
                handleValueChange={(i, city) => {
                  dispatch({ command: 'handleCityChange', payload: city })
                }}
              />
            </Stack>
            <Stack
              spacing={2}
              direction={'row'}
            >
              <TextField
                select
                value={state.category}
                className={styles.textField}
                label={t('Lib:truckStopForm.category')}
                error={!!errors?.stops?.[index]?.category}
                onChange={(e) => {
                  dispatch({ command: 'handleCategoryChange', payload: e.target.value })
                }}
                helperText={
                  <Box
                    className={styles.errorBox}
                    height={2}
                  >
                    {errors?.stops?.[index]?.category?.message}
                  </Box>
                }
              >
                <MenuItem value={'P'}>{t('Market:offerDialog.P')}</MenuItem>
                <MenuItem value={'R'}>{t('Market:offerDialog.R')}</MenuItem>
                <MenuItem value={'D'}>{t('Market:offerDialog.D')}</MenuItem>
              </TextField>
              <TextField
                value={state.street}
                onChange={(e) =>
                  dispatch({ command: 'handleAddressChange', payload: e.target.value })
                }
                className={styles.textField}
                label={t('Lib:truckStopForm.address')}
              />
            </Stack>
            <Stack
              direction={'row'}
              spacing={2}
            >
              <TextField
                value={state.facility}
                onChange={(e) =>
                  dispatch({ command: 'handleFacilityChange', payload: e.target.value })
                }
                className={styles.textField}
                label={t('Lib:truckStopForm.facility')}
              />
              <TextField
                value={state.description}
                onChange={(e) =>
                  dispatch({ command: 'handleDescriptionChange', payload: e.target.value })
                }
                className={styles.textField}
                label={t('Lib:truckStopForm.description')}
              />
            </Stack>
            <Stack
              direction={'row'}
              spacing={2}
            >
              <DateField
                value={state.date}
                onChange={(e) => dispatch({ command: 'handleDateChange', payload: e })}
                error={errors?.stops?.[index]?.date}
              />

              <DateField
                value={state.dateTo}
                onFocus={() => dispatch({ command: 'handleDateToTouch' })}
                onChange={(e) => dispatch({ command: 'handleDateToChange', payload: e })}
                error={errors?.stops?.[index]?.dateTo}
              />
            </Stack>
            <Stack
              direction={'row'}
              spacing={2}
            >
              <TimeField
                ampm={false}
                label={t('Lib:truckStopForm.time')}
                value={dayjs(state.date)}
                onChange={(e) => dispatch({ command: 'handleTimeChange', payload: e })}
                format={'HH:mm'}
                className={styles.textField}
                slotProps={{
                  textField: {
                    error: !!errors?.stops?.[index]?.time,
                    helperText: (
                      <Box
                        className={styles.errorBox}
                        height={2}
                      >
                        {errors?.stops?.[index]?.time?.message}
                      </Box>
                    ),
                  },
                }}
              />
              <TimeField
                ampm={false}
                label={t('Lib:truckStopForm.timeTo')}
                value={dayjs(state.dateTo)}
                onChange={(e) => dispatch({ command: 'handleTimeToChange', payload: e })}
                format={'HH:mm'}
                className={styles.textField}
                slotProps={{
                  textField: {
                    error: !!errors?.stops?.[index]?.timeTo,
                    helperText: (
                      <Box
                        className={styles.errorBox}
                        height={2}
                      >
                        {errors?.stops?.[index]?.timeTo?.message}
                      </Box>
                    ),
                  },
                }}
              />
            </Stack>
          </Stack>
        </Box>
      </LocalizationProvider>
    )
  },
)

TruckStopForm.displayName = 'TruckStopForm'

export default TruckStopForm
