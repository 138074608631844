import { TextField } from '@mui/material'
import { AsYouType } from 'libphonenumber-js'
import { ChangeEvent, forwardRef } from 'react'

import { useRefreshFieldOnUpdate } from '@/shared/utils/useRefreshFieldOnUpdate'

export interface IPhoneField {
  value?: string
  onChange: (value: string) => void
  onBlur: () => void
  disabled?: boolean
  name: string
  label?: string
  error?: boolean
  helperText?: JSX.Element
  placeholder?: string
}

export const PhoneField = forwardRef<HTMLDivElement, IPhoneField>(
  ({ value, onChange, onBlur, disabled, name, label, error, helperText, placeholder }, ref) => {
    const refresh = useRefreshFieldOnUpdate(value)

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      const phoneNumber = e.target.value
      let formattedPhoneNumber

      if (phoneNumber && !phoneNumber.startsWith('+')) {
        formattedPhoneNumber = new AsYouType().input(`+${e.target.value}`)
      } else {
        formattedPhoneNumber = new AsYouType().input(e.target.value)
      }
      onChange(formattedPhoneNumber)
    }

    const handleBlur = () => {
      onBlur()
    }

    return (
      <>
        {!!refresh && (
          <TextField
            value={value}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={disabled}
            name={name}
            ref={ref}
            label={label}
            error={error}
            helperText={helperText}
            placeholder={placeholder}
          />
        )}
      </>
    )
  },
)

PhoneField.displayName = 'PhoneField'
