/* eslint-disable css-modules/no-unused-class */
import { useGetCarrierLoadQuery } from '@features/loads/loadsApiSlice'
import useRefetchWithAlert from '@hooks/useRefetchWithAlert'
import ClusteredMap from '@lib/ClusteredMap'
import ConfirmationDialog, { IConfirmationDialogRef } from '@lib/ConfirmationDialog'
import ContentList, { IContentListRef } from '@lib/ContentList'
import ContentTable, { ITableRef } from '@lib/ContentTable'
import SideDialog, { ISideDialogRef } from '@lib/SideDialog'
import ReplayIcon from '@mui/icons-material/Replay'
import { Box, IconButton, Skeleton, Tooltip, useMediaQuery, useTheme } from '@mui/material'
import { ICarrierMarketOffer } from '@pkg/market/models'
import { ISearchMarketOffersRequest } from '@pkg/market/requests'
import { ITheme } from '@pkg/sokarUI'
import { t } from 'i18next'
import { FC, useCallback, useEffect, useRef, useState } from 'react'

import { useSokarTour } from '@/hooks/useSokarTour'

import styles from './carrierMarket.module.scss'
import CarrierLoadDialog from './components/CarrierLoadDialog'
import CarrierLoadOptionCard from './components/CarrierLoadOptionCard'
import OfferCard from './components/OfferCard'
import tourData from './tour_data.json'
import {
  carrierMarketCellRenderer as cellRenderer,
  CarrierMarketTableColumns as columns,
} from './view.helpers'

const MarketplaceView: FC = () => {
  const appTheme: ITheme = useTheme()
  const isMobile: boolean = useMediaQuery(appTheme.breakpoints.down('md'))
  const [isMapView, setIsMapView] = useState<boolean>(false)

  const freeActions: JSX.Element = (
    <Tooltip
      title={t('Market:publishedTable.refresh')}
      placement={'left'}
      arrow={true}
    >
      <IconButton
        sx={{ color: appTheme.palette.text.light }}
        onClick={() => refresh()}
      >
        <ReplayIcon />
      </IconButton>
    </Tooltip>
  )

  const offerDialogRef = useRef<ISideDialogRef>(null)
  const handleOfferDialogClose = useCallback((): void => {
    offerDialogRef.current?.close()
  }, [])

  const [searchParameters, setSearchParameters] = useState<Partial<ISearchMarketOffersRequest>>(
    {} as Partial<ISearchMarketOffersRequest>,
  )
  const {
    data: loadOffers,
    refetch: refetchOffers,
    isFetching: isOffersFetching,
  } = useGetCarrierLoadQuery({ published: true, tags: 'broker-load', ...searchParameters })
  const refresh = useRefetchWithAlert(refetchOffers)

  const ref = useRef<ITableRef>(null)
  const listRef = useRef<IContentListRef>(null)

  const confirmationDialogRef = useRef<IConfirmationDialogRef>(null)

  const {
    isOpen: isTourOpen,
    currentStep,
    setCurrentStep,
  } = useSokarTour(
    !!loadOffers,
    true,
    'forcarriers-market-tour',
    'Market:onboarding.forCarriers',
    10,
    {
      7: true,
      8: false,
    },
  )

  useEffect((): void => {
    if (isMobile) {
      if ((!loadOffers || loadOffers.length === 0) && isTourOpen) {
        listRef.current?.open([tourData.offer])
      } else {
        listRef.current?.open(loadOffers)
      }
    } else {
      if ((!loadOffers || loadOffers.length === 0) && isTourOpen) {
        ref.current?.open([tourData.offer], columns)
      } else {
        ref.current?.open(loadOffers, columns)
      }
    }
  }, [isMobile, loadOffers, isOffersFetching, isMapView, isTourOpen])

  const handleOfferDialogOpen = useCallback(
    (row: ICarrierMarketOffer): void => {
      if (isTourOpen) {
        setTimeout((): void => {
          setCurrentStep(8)
        }, 350)
      }
      offerDialogRef.current?.open(row)
    },
    [isTourOpen],
  )

  const getProperView = (): JSX.Element => {
    if (isOffersFetching) {
      return (
        <Box className={styles.loader}>
          {Array(12)
            .fill(null)
            .map((_, index) => (
              <Skeleton
                key={index}
                animation={'wave'}
              />
            ))}
        </Box>
      )
    }
    if (isMapView) {
      return (
        <ClusteredMap
          data={loadOffers ?? []}
          isDialogOpen={false}
          handleObjectSelect={handleOfferDialogOpen}
          type={'offeredLoads'}
          display={'pickupLocation'}
          sx={{ height: 'calc(100dvh - 275px)' }}
        />
      )
    }
    if (isMobile) {
      return (
        <ContentList
          ref={listRef}
          openDialog={handleOfferDialogOpen}
          listComponent={OfferCard}
        />
      )
    }
    return (
      <ContentTable
        ref={ref}
        style={{ height: 'calc(100dvh - 275px)' }}
        freeActions={freeActions}
        cellRenderer={cellRenderer}
        openDialog={handleOfferDialogOpen}
      />
    )
  }

  return (
    <>
      <CarrierLoadOptionCard
        isMapView={isMapView}
        handleSearch={setSearchParameters}
        handleViewToggle={() => setIsMapView((prev) => !prev)}
        onBoardingStep={currentStep}
      />
      {getProperView()}
      <SideDialog
        ref={offerDialogRef}
        title={t('Market:carrierLoadDialog.title')}
        className='forcarriers-market-tour-9'
      >
        <CarrierLoadDialog
          handleClose={handleOfferDialogClose}
          elementRef={offerDialogRef}
          confirmationRef={confirmationDialogRef}
        />
      </SideDialog>
      <ConfirmationDialog ref={confirmationDialogRef} />
    </>
  )
}

export default MarketplaceView
