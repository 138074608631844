import { StepType } from '@reactour/tour'
import { t } from 'i18next'

export const externalMarketSteps: StepType[] = [
  {
    selector: '.external-market-tour-1',
    content: t('Market:onboarding.externalMarket.step1') as string,
  },
  {
    selector: '.external-market-tour-2',
    content: t('Market:onboarding.externalMarket.step2') as string,
  },
  {
    selector: '.external-market-tour-3',
    content: t('Market:onboarding.externalMarket.step3') as string,
  },
]
