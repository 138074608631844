import { useRef, useEffect, useCallback } from 'react'
import { t } from 'i18next'
import SideDialog, { ISideDialogRef } from '@lib/SideDialog'
import { ITheme } from '@pkg/sokarUI'
import styles from './transportsToInvoice.module.scss'
import ContentTable, { ITableRef } from '@lib/ContentTable'
import DetailsDialog from './components/DetailsDialog'
import { ILoad } from '@pkg/loads/models'
import { useGetLoadQuery } from '@features/loads/loadsApiSlice'

import {
  TransportsToInvoiceColumns as columns,
  TransportsToInvoiceCellRenderer as cellRenderer,
} from './view.helpers'

import { Box, Typography, useTheme, useMediaQuery } from '@mui/material'
import ConfirmationDialog, { IConfirmationDialogRef } from '@/lib/ConfirmationDialog'
import ComputerIcon from '@mui/icons-material/Computer'

const ToInvoiceTable = (): JSX.Element => {
  const appTheme: ITheme = useTheme()
  const isMobile: boolean = useMediaQuery(appTheme.breakpoints.down('md'))

  const { data: transports, refetch: refetchTransports } = useGetLoadQuery({
    published: false,
    archived: false,
    tags: 'ready-to-invoice',
  })

  const tableRef = useRef<ITableRef>(null)
  useEffect((): void => {
    tableRef.current?.open(transports, columns)
  }, [transports])

  const dialogRef = useRef<ISideDialogRef>(null)
  const handleDialogClose = useCallback((): void => {
    dialogRef.current?.close()
  }, [])
  const handleDialogOpen = useCallback((row: ILoad): void => {
    dialogRef.current?.open(row)
  }, [])

  const confirmationDialogRef = useRef<IConfirmationDialogRef>(null)

  return (
    <>
      {isMobile ? (
        <Box className={styles.inaccessibleOnMobile}>
          <ComputerIcon className={styles.icon} />
          <Typography>{t('Accounting:useDesktop')}</Typography>
        </Box>
      ) : (
        <>
          <ContentTable
            ref={tableRef}
            style={{ height: 'calc(100dvh - 120px)' }}
            cellRenderer={cellRenderer}
            openDialog={handleDialogOpen}
          />
          <SideDialog
            ref={dialogRef}
            title={''}
          >
            <DetailsDialog
              elementRef={dialogRef}
              confirmationRef={confirmationDialogRef}
              refetch={refetchTransports}
              handleClose={handleDialogClose}
            />
          </SideDialog>
          <ConfirmationDialog ref={confirmationDialogRef} />
        </>
      )}
    </>
  )
}

export default ToInvoiceTable
