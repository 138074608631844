import { useSearchCitiesQuery } from '@features/loads/loadsApiSlice'
import SearchIcon from '@mui/icons-material/Search'
import { Autocomplete, Box, CircularProgress, TextField } from '@mui/material'
import { ICity } from '@pkg/loads/models'
import { ChangeEvent, useEffect, useState } from 'react'
import { FieldError } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import appConfig from '@/config'

import styles from './cityField.module.scss'

interface CityFieldProps {
  index: number
  error?: FieldError
  value: any
  country: string
  className?: string
  disabled?: boolean
  handleValueChange: (index: number, city: ICity) => void
}

const CityField = ({ ...props }: CityFieldProps): JSX.Element => {
  const { t } = useTranslation()
  const [searchPhrase, setSearchPhrase] = useState<string>('')

  const {
    data: cities,
    isSuccess: _isCitiesSuccess,
    isFetching: isCitiesFetching,
    status: citiesStatus,
  } = useSearchCitiesQuery(
    { searchPhrase, countryCode: props.country ?? '', limit: 50 },
    {
      skip: searchPhrase.length < 3,
    },
  )

  const handleSearchInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setSearchPhrase(e.target.value)
  }

  const handleValueChange = (value: ICityOption | null): void => {
    if (!value) return
    props.handleValueChange(props.index, value?.originalObject)
  }

  useEffect(() => {
    setSearchPhrase(props.value)
  }, [props.value])

  const getNoOptions = (): JSX.Element => {
    if (citiesStatus === 'uninitialized') {
      return <>{t('Common:typeToSearch')}</>
    }
    if (isCitiesFetching) {
      return (
        <Box className={styles.loader}>
          <CircularProgress
            className={styles.loader}
            size={'1rem'}
          />
        </Box>
      )
    }
    return <>{t('Common:noOptions')}</>
  }

  return (
    <Autocomplete
      sx={{ flex: 1 }}
      disablePortal
      disableClearable
      value={props.value}
      filterOptions={(options) => options}
      options={cities ? parseCitiesForSelect(cities) : []}
      onChange={(e, value) => handleValueChange(value)}
      noOptionsText={getNoOptions()}
      renderInput={(params) => (
        <TextField
          {...params}
          onChange={handleSearchInput}
          disabled={!props.country || props.disabled}
          className={props.className}
          label={t('Fleet:createLoadDialog.city')}
          InputProps={{
            ...params.InputProps,
            startAdornment: <SearchIcon color={'secondary'} />,
          }}
          error={!!props.error}
          helperText={<Box height={2}>{props.error?.message}</Box>}
        />
      )}
    />
  )
}

export default CityField

interface ICityOption {
  label: string
  value: string
  originalObject: ICity
}

const parseCitiesForSelect = (cities: ICity[]): ICityOption[] => {
  return cities.map((city) => ({
    label:
      appConfig.REGION === 'NA'
        ? `${city.name}, ${city.state} ${city.postalCode}`
        : `${city.name}, ${city.postalCode}`,
    value: city.id,
    originalObject: city,
  }))
}
