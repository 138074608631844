import { useEffect, useState } from 'react'

/**
 * The function `useRefreshFieldOnUpdate` returns a boolean value indicating whether a field should be
 * refreshed based on changes in its value.
 * @param {any} value - The `value` parameter is the current value that you want to track for changes.
 * The `useRefreshFieldOnUpdate` custom hook will trigger a refresh whenever this `value` changes
 * compared to its previous value.
 * @returns The `useRefreshFieldOnUpdate` custom hook returns a boolean value to force re-render component
 * when it's value is update. Prevents mui bug when field label covers value.
 */
export const useRefreshFieldOnUpdate = (value: unknown): boolean => {
  const [refresh, setRefresh] = useState<number>(1)
  const [oldValue, setOldValue] = useState<unknown>(value)

  useEffect(() => {
    if (value !== oldValue) {
      setOldValue(value)
      setRefresh((prev) => prev + 1)
    }
  }, [oldValue, value])

  return !!refresh
}
