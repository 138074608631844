import { object, string, number, boolean } from 'yup'
import { t } from 'i18next'

const currentYear: number = new Date().getFullYear()

export const trailerValidator = object().shape({
  plate: string().required(t('Fleet:validatorResponses.platesIsRequiredConstraint') ?? ''),
  category: string().required(t('Fleet:validatorResponses.categoryIsRequired') ?? ''),
  vin: string()
    .required(t('Fleet:validatorResponses.vinRequiredConstraint') ?? '')
    .length(17, t('Fleet:validatorResponses.vinLengthConstraint') ?? ''),
  make: string()
    .required(t('Fleet:validatorResponses.makeRequiredConstraint') ?? '')
    .min(3, t('Fleet:validatorResponses.minCharactersConstraint', { characters: 3 }) ?? ''),
  year: number()
    .min(1900, t('Fleet:validatorResponses.before1900Constraint') ?? '')
    .max(currentYear + 1, t('Fleet:validatorResponses.futureDateConstraint') ?? '')
    .required(t('Fleet:validatorResponses.yearIsRequiredConstraint') ?? ''),
  isCarrierOwned: boolean(),
  carrierId: string().when('is_carrier_owner', {
    is: true,
    then: string().required(t('Fleet:validatorResponses.carrierIdRequiredConstraint') ?? ''),
  }),
})

export const truckValidator = object().shape({
  plate: string()
    .required(() => t('Fleet:validatorResponses.platesIsRequiredConstraint'))
    .min(4, () => t('Fleet:validatorResponses.minCharactersConstraint', { characters: 4 }))
    .max(12, () => t('Fleet:validatorResponses.maxCharactersConstraint', { characters: 12 })),
  vin: string()
    .required(() => t('Fleet:validatorResponses.vinRequiredConstraint'))
    .length(17, () => t('Fleet:validatorResponses.vinLengthConstraint')),
  make: string()
    .required(() => t('Fleet:validatorResponses.makeRequiredConstraint'))
    .min(3, () => t('Fleet:validatorResponses.minCharactersConstraint', { characters: 3 }))
    .max(32, () => t('Fleet:validatorResponses.maxCharactersConstraint', { characters: 32 })),
  year: number()
    .min(1900, () => t('Fleet:validatorResponses.before1900Constraint'))
    .max(currentYear + 1, () => t('Fleet:validatorResponses.futureDateConstraint'))
    .required(() => t('Fleet:validatorResponses.yearIsRequiredConstraint')),
  isCarrierOwner: boolean(),
  carrierId: string().when('is_carrier_owner', {
    is: true,
    then: string().required(() => t('Fleet:validatorResponses.carrierIdRequiredConstraint')),
  }),
})
