import { useCallback, useEffect, useState } from 'react'
import styles from './clusteredMap.module.scss'
import dayjs from 'dayjs'
import L, { Map, Icon } from 'leaflet'
import { useTheme } from '@emotion/react'
import { ILoad } from '@pkg/loads/models'
import useSupercluster from 'use-supercluster'
import { createControlComponent } from '@react-leaflet/core'
import { MapContainer, TileLayer, useMap, Marker, Popup } from 'react-leaflet'

import appConfig from '@/config'

import { Box } from '@mui/material'
import { getRegion } from '@shared/utils/formatters'

interface IMapPoint {
  type: string
  properties: {
    cluster: boolean
    loadID: string
    route: string
    originalObject: ILoad
  }
  geometry: {
    type: string
    coordinates: number[]
  }
}

interface MapViewProps {
  data: any[]
  isDialogOpen: boolean
  handleObjectSelect: (object: any) => void
  type: 'bookedLoads' | 'offeredLoads'
  display: 'deliveryLocation' | 'pickupLocation'
  sx?: object
}

const MapView: React.FC<MapViewProps> = ({ ...props }) => {
  const country: string | null = localStorage.getItem('country')
  const appTheme: any = useTheme()

  const mapIcon: Icon = new Icon({
    iconUrl:
      'https://magda-trans.s3.eu-central-1.amazonaws.com/static/images/geo_markers/blue_icon.svg',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.4/images/marker-shadow.png',
    iconSize: [37.5, 62.5],
    iconAnchor: [18, 62.5],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  })

  const icons: any[] = []
  const fetchIcon = (count: number, size: number) => {
    if (!icons[count]) {
      icons[count] = L.divIcon({
        html: `<div class=${styles.mapCluster} style="width: ${size}px; height: ${size}px; background: ${appTheme.palette.primary.main}">
                    ${count}
                </div>`,
      })
    }

    return icons[count]
  }

  const [selectedObject, setSelectedObject] = useState<any>()
  const handleObjectSelection = (object: any): void => {
    props.handleObjectSelect(object)
    setSelectedObject(object)
  }

  const ShowLoads = ({ data }: { data: any }): JSX.Element => {
    const maxZoom: number = 22
    const [bounds, setBounds] = useState<any[]>([]) // type is probably BBox, but no idea where to import it from
    const [zoom, setZoom] = useState<number>(12)
    const map: Map = useMap()
    map.options.minZoom = 2

    const getPopText = (load: ILoad): string => {
      if (props.type === 'bookedLoads') {
        return `${load.drivers && load.drivers[0].firstName} ${dayjs(load.stops[load.stops.length - 1].date).format(appConfig.DATE_FORMAT)} - ${load.stops[load.stops.length - 1].city} ${load.stops[load.stops.length - 1].countryAbbreviation}`
      }
      return `${load.stops[0].city} ${load.stops[0].countryAbbreviation} - ${load.stops[load.stops.length - 1].city} ${load.stops[load.stops.length - 1].countryAbbreviation}`
    }

    const updateMap = () => {
      const b: L.LatLngBounds = map.getBounds()
      setBounds([
        b.getSouthWest().lng,
        b.getSouthWest().lat,
        b.getNorthEast().lng,
        b.getNorthEast().lat,
      ])
      setZoom(map.getZoom())
      map.setMaxBounds(b)
    }

    const onMove = useCallback(() => {
      updateMap()
    }, [map])

    useEffect(() => {
      map.on('move', onMove)
      return () => {
        map.off('move', onMove)
      }
    }, [map, onMove])

    useEffect(() => {
      updateMap()
    }, [])

    const points: IMapPoint[] = data
      .filter((load: ILoad) => {
        return load.id && load.stops.length > 0
      })
      .map((load: ILoad) => ({
        type: 'Feature',
        properties: {
          cluster: false,
          loadId: load.id,
          originalObject: load,
          route: getPopText(load),
        },
        geometry: {
          type: 'Point',
          coordinates:
            props.display === 'deliveryLocation'
              ? [load.stops[load.stops.length - 1].lon, load.stops[load.stops.length - 1].lat]
              : [load.stops[0].lon, load.stops[0].lat],
        },
      }))

    const { clusters, supercluster: superCluster } = useSupercluster({
      points: points,
      // @ts-ignore
      bounds: bounds,
      zoom: zoom,
      options: { radius: 75, maxZoom: 17 },
    })

    // @ts-ignore
    return clusters.map((cluster, index) => {
      const [longitude, latitude] = cluster.geometry.coordinates
      const { cluster: isCluster, point_count: pointCount } = cluster.properties

      if (isCluster) {
        return (
          <Marker
            key={index}
            position={[latitude, longitude]}
            icon={fetchIcon(pointCount, 10 + (pointCount / points.length) * 40)}
            eventHandlers={{
              click: () => {
                if (cluster.properties.point_count > 10) {
                  const expansionZoom = Math.min(
                    superCluster.getClusterExpansionZoom(cluster.id),
                    maxZoom,
                  )

                  map.setView([latitude, longitude], expansionZoom, {
                    animate: true,
                  })
                }
              },
            }}
          >
            {cluster.properties.point_count_abbreviated <= 10 && (
              <Popup>
                <div>
                  {cluster.properties.point_count_abbreviated > 0 &&
                    superCluster.getLeaves(cluster.id).map((leaf: any) => (
                      <>
                        <strong
                          style={{
                            color: appTheme.palette.primary.main,
                            cursor: 'pointer',
                          }}
                          key={leaf.properties.loadId}
                          onClick={() => handleObjectSelection(leaf.properties.originalObject)}
                        >
                          {leaf.properties.route}
                        </strong>
                        <br />
                      </>
                    ))}
                </div>
              </Popup>
            )}
          </Marker>
        )
      }

      return (
        <Marker
          key={index}
          position={[latitude, longitude]}
          icon={mapIcon}
        >
          <Popup>
            <strong
              style={{
                color: appTheme.palette.primary.main,
                cursor: 'pointer',
              }}
              onClick={() => handleObjectSelection(cluster.properties.originalObject)}
            >
              {cluster.properties.route}
            </strong>
          </Popup>
        </Marker>
      )
    })
  }

  const createRoutineMachineLayer = ({ ...props }) => {
    let markers: L.LatLng[] = []
    props.info.stops.map((stop: any) => markers.push(L.latLng(stop.latitude, stop.longitude)))
    // @ts-ignore
    const instance = L.Routing.control({
      serviceUrl: appConfig.OSRM_SERVER,
      waypoints: markers,
      collapsible: true,
      hide: true,
      routeWhileDragging: true,
      lineOptions: {
        styles: [{ color: appTheme.palette.primary.main, opacity: 1, weight: 5 }],
      },
      altLineOptions: {
        styles: [{ color: appTheme.palette.primary.main, opacity: 0.6, weight: 5 }],
      },
      showAlternatives: true,
      createMarker: function (i: number, wp: any, nWps: number): L.Marker<any> {
        if (i === 0 || i === nWps - 1) {
          return L.marker(wp.latLng, {
            icon: mapIcon,
          })
        } else {
          return L.marker(wp.latLng, {
            icon: mapIcon,
          })
        }
      },
    })

    return instance
  }

  const Routing = createControlComponent(createRoutineMachineLayer)

  if (!country) return <></>

  return (
    <Box
      className={styles.mapContainer}
      sx={props.sx}
    >
      <MapContainer
        center={(appConfig[getRegion(country) as keyof typeof appConfig] as any).mapCenter}
        zoom={4}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        />
        {!props.isDialogOpen ? (
          props.data &&
          props.data != null && (
            <ShowLoads
              data={props.data.filter((data) => {
                return data.stops != null
              })}
            />
          )
        ) : (
          <Routing info={selectedObject} />
        )}
      </MapContainer>
    </Box>
  )
}

export default MapView
