import { useRef, useEffect, MutableRefObject } from 'react'
import { t } from 'i18next'
import { useTheme } from '@emotion/react'
import useOwnUser from 'src/hooks/useOwnUser'

import { IConfirmationDialogRef } from '@lib/ConfirmationDialog'
import { ITruck } from '@pkg/equipment/models'
import ContentList, { IContentListRef } from '@lib/ContentList'
import TruckCard from '@app/fleet/views/TrucksView/components/truckCard'
import { ITableColumn } from '@pkg/sokarUI'
import ContentTable, { ITableRef } from 'src/lib/ContentTable'
import TruckDialog from './truckDialog'
import SideDialog, { ISideDialogRef } from '@lib/SideDialog'
import UsedByCell from '@app/fleet/views/TrucksView/components/usedByCell'

import { Typography, useMediaQuery } from '@mui/material'

const columns: ITableColumn[] = [
  {
    label: t('Fleet:trucksTable.truckNumber'),
    dataKey: 'number',
    isSortable: false,
  },
  {
    label: t('Fleet:trucksTable.plates'),
    dataKey: 'plate',
    isSortable: true,
  },
  {
    label: t('Fleet:trucksTable.ownership'),
    dataKey: 'ownership',
    isSortable: false,
  },
  {
    label: t('Fleet:trucksTable.usedBy'),
    dataKey: 'usedBy',
    isSortable: false,
  },
]

interface TrucksArchiveProps {
  data: ITruck[]
  refetch: () => void
  confirmationDialogRef: MutableRefObject<IConfirmationDialogRef | null>
}

const TrucksArchive: React.FC<TrucksArchiveProps> = ({ ...props }) => {
  const appTheme: any = useTheme()
  const isMobile: boolean = useMediaQuery(appTheme.breakpoints.down('md'))

  const { companyId } = useOwnUser()

  const cellRenderer = (column: ITableColumn, row: ITruck): JSX.Element => {
    if (column.dataKey === 'ownership') {
      return (
        <Typography>
          {row.isCarrierOwned
            ? t('Fleet:trucksTable.contractorsProperty')
            : t('Fleet:trucksTable.companysProperty')}
        </Typography>
      )
    }
    if (column.dataKey === 'usedBy') {
      return <UsedByCell driverId={row.usedBy!} />
    }

    // prettier-ignore
    return (
        <Typography>
            {row[column.dataKey as keyof ITruck] as string}
        </Typography>
    )
  }

  const truckDialogRef = useRef<ISideDialogRef>()
  const handleTruckDialogOpen = (row: ITruck): void => {
    truckDialogRef.current?.open(row)
  }
  const handleTruckDialogClose = () => truckDialogRef.current?.close()

  const ref = useRef<ITableRef>(null)
  const listRef = useRef<IContentListRef>(null)
  useEffect(() => {
    if (isMobile) listRef.current?.open(props.data)
    else ref.current?.open(props.data, columns)
  }, [isMobile, props.data])

  return (
    <>
      {isMobile ? (
        <ContentList
          ref={listRef}
          openDialog={handleTruckDialogOpen}
          listComponent={TruckCard}
        />
      ) : (
        <ContentTable
          ref={ref}
          cellRenderer={cellRenderer}
          openDialog={handleTruckDialogOpen}
        />
      )}
      <SideDialog
        ref={truckDialogRef}
        title={t('Fleet:truckDialog.title')}
      >
        <TruckDialog
          refetch={props.refetch}
          elementRef={truckDialogRef}
          confirmationRef={props.confirmationDialogRef}
          handleClose={handleTruckDialogClose}
        />
      </SideDialog>
    </>
  )
}

export default TrucksArchive
