import { ITheme } from '@pkg/sokarUI'
import { useTranslation } from 'react-i18next'
import styles from './card.module.scss'

import { Box, Paper, Stack, Typography, useTheme } from '@mui/material'

const RequestCard = ({ ...props }): JSX.Element => {
  const appTheme: ITheme = useTheme()
  const { t } = useTranslation()

  return (
    <Paper
      className={styles.wrapper}
      onClick={() => props.openDialog(props.data)}
      sx={{ backgroundColor: appTheme.palette.background.default }}
    >
      <Stack
        direction={'column'}
        spacing={2}
      >
        <Box className={styles.row}>
          <Typography>{t('Management:registerTable.taxId')}</Typography>
          <Typography>{props.data?.vatNumber}</Typography>
        </Box>
        <Box className={styles.row}>
          <Typography>{t('Management:registerTable.name')}</Typography>
          <Typography>{props.data?.user.firstName}</Typography>
        </Box>
        <Box className={styles.row}>
          <Typography>{t('Management:registerTable.surname')}</Typography>
          <Typography>{props.data?.user.lastName}</Typography>
        </Box>
        <Box className={styles.row}>
          <Typography>{t('Management:registerTable.phone')}</Typography>
          <Typography>{props.data?.user.phone}</Typography>
        </Box>
        <Box className={styles.row}>
          <Typography>e-mail</Typography>
          <Typography>{props.data?.user.email}</Typography>
        </Box>
      </Stack>
    </Paper>
  )
}

export default RequestCard
