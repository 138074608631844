import { Route, Routes } from 'react-router-dom'

import CurrentLoads from './views/CurrentLoads'
import DriversView from '@app/fleet/views/DriversView'
import TrailersView from '@app/fleet/views/TrailersView'
import TrucksView from '@app/fleet/views/TrucksView'
import RequireAuth from '@features/auth/RequireAuth'

const Fleet = () => {
  return (
    <Routes>
      <Route element={<RequireAuth />}>
        <Route
          path={'/current-loads'}
          element={<CurrentLoads />}
        />
        <Route
          path={'/drivers'}
          element={<DriversView />}
        />
        <Route
          path={'/trailers'}
          element={<TrailersView />}
        />
        <Route
          path={'/trucks'}
          element={<TrucksView />}
        />
      </Route>
    </Routes>
  )
}

export default Fleet
