import useOwnUser from '@/hooks/useOwnUser'
import { useGetDriverQuery } from '@features/drivers/driversApiSlice'

import { Typography, Skeleton } from '@mui/material'

interface IUsedByCellProps {
  driverId?: string
}

const UsedByCell = ({ ...props }: IUsedByCellProps): JSX.Element => {
  const { companyId } = useOwnUser()

  const {
    data: driver,
    isSuccess: isDriverSuccess,
    isFetching: isDriverFetching,
  } = useGetDriverQuery(
    {
      driverId: props.driverId,
      companyId: companyId,
      key: props.driverId as string,
    },
    { skip: !props.driverId || !companyId },
  )

  if (isDriverFetching) return <Skeleton />
  if (!props.driverId || !isDriverSuccess) return <></>
  return (
    <Typography>
      {driver[0].firstName} {driver[0].lastName}
    </Typography>
  )
}

export default UsedByCell
