import { t } from 'i18next'
import dayjs from 'dayjs'
import styles from './carrierMarket.module.scss'
import { ITableColumn } from '@pkg/sokarUI'
import appConfig from '@/config'
import CountryFlag from '@lib/CountryFlag'
import TrailerInfo from '@lib/TrailerInfo'
import { ICarrierMarketOffer } from '@pkg/market/models'

import { Typography } from '@mui/material'

export const CarrierMarketTableColumns: ITableColumn[] = [
  {
    label: t('Market:publishedTable.equipment'),
    dataKey: 'equipment',
    isSortable: true,
  },
  {
    label: t('Market:publishedTable.weight'),
    dataKey: 'weight',
    isSortable: true,
  },
  {
    label: t('Market:publishedTable.publishedPrice'),
    dataKey: 'publishedPrice',
    isSortable: true,
  },
  {
    label: t('Market:publishedTable.pickupLocation'),
    dataKey: 'pickupLocation',
    isSortable: false,
  },
  {
    label: t('Market:publishedTable.pickupDate'),
    dataKey: 'pickupDate',
    isSortable: false,
  },
  {
    label: t('Market:publishedTable.deliveryLocation'),
    dataKey: 'deliveryLocation',
    isSortable: false,
  },
  {
    label: t('Market:publishedTable.deliveryDate'),
    dataKey: 'deliveryDate',
    isSortable: false,
  },
]

export const carrierMarketCellRenderer = (
  column: ITableColumn,
  row: ICarrierMarketOffer,
): JSX.Element => {
  if (column.dataKey === 'equipment') {
    return <TrailerInfo trailers={row.equipment.split(',')} viewAll={false} />
  }
  if (column.dataKey === 'weight') {
    return (
      <Typography>
        {row.weight} {row.weightUnit}
      </Typography>
    )
  }
  if (column.dataKey === 'publishedPrice') {
    return (
      <Typography>
        {row.publishedPrice} {row.publishedPriceCurrency}
      </Typography>
    )
  }
  if (column.dataKey === 'pickupDate') {
    return <Typography>{dayjs(row.stops[0].date).format(appConfig.DATE_FORMAT)}</Typography>
  }
  if (column.dataKey === 'deliveryDate') {
    return (
      <Typography>
        {dayjs(row.stops[row.stops.length - 1].date).format(appConfig.DATE_FORMAT)}
      </Typography>
    )
  }
  if (column.dataKey === 'pickupLocation') {
    return (
      <div className={styles.containerLocation}>
        <CountryFlag countryName={row.stops[0].countryAbbreviation} />
        <Typography>{`${row.stops[0].countryAbbreviation} ${row.stops[0].city}`}</Typography>
      </div>
    )
  }
  if (column.dataKey === 'deliveryLocation') {
    return (
      <div className={styles.containerLocation}>
        <CountryFlag countryName={row.stops[row.stops.length - 1].countryAbbreviation} />
        <Typography>{`${row.stops[row.stops.length - 1].countryAbbreviation} ${row.stops[row.stops.length - 1].city}`}</Typography>
      </div>
    )
  }

  return <Typography>{row[column.dataKey as keyof ICarrierMarketOffer] as string}</Typography>
}
