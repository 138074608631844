import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import SearchIcon from '@mui/icons-material/Search'
import TableChartIcon from '@mui/icons-material/TableChart'
import { Paper, Tab, Tabs, useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import { useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TableVirtuoso } from 'react-virtuoso'

import { IExternalLoad, IFormattedMarket, IMarket } from '@/pkg/market/models'
import { IAddSubscriptionRequest } from '@/pkg/market/requests'
import { ITheme } from '@/pkg/sokarUI'

import MarketForm from './MarketForm'
import MarketLoadDialog from './MarketLoadDialog'
import {
  MarketFixedHeaderContent,
  MarketRowContent,
  MarketVirtuosoTableComponents,
} from './MarketTable'

interface TabPanelProps {
  index: number
  selectedIndex: number
  onCreateMarketQuery: (data: IAddSubscriptionRequest) => void
  onUpdateMarketQuery: (data: IAddSubscriptionRequest) => void
  onDeleteMarketQuery: (id: string) => void
  subscription?: IMarket
  externalLoads?: IExternalLoad[]
}

const MarketTab = ({
  selectedIndex,
  index,
  subscription,
  externalLoads,
  onCreateMarketQuery,
  onUpdateMarketQuery,
  onDeleteMarketQuery,
}: TabPanelProps) => {
  const appTheme: ITheme = useTheme()
  const marketDialogRef = useRef<any>(null)
  const [currentTab, setCurrentTab] = useState<0 | 1 | 2>(externalLoads ? 1 : 0)
  const handleChange = (event: React.SyntheticEvent, newValue: 0 | 1 | 2) => {
    if (newValue === 2) {
      handleDeleteMarketQuery()
    } else {
      index !== 0 && setCurrentTab(newValue)
    }
  }
  const { t } = useTranslation()

  const mapMarketToRows = useCallback((): IFormattedMarket[] => {
    if (!externalLoads) return [] as IFormattedMarket[]

    const formattedMarket: IFormattedMarket[] = externalLoads //TODO it is going to be changed after testing, so i left it here
    return formattedMarket
  }, [externalLoads])

  const handleDeleteMarketQuery = useCallback(() => {
    if (!selectedIndex || !subscription?.id || !index) {
      setCurrentTab(0)
    } else {
      onDeleteMarketQuery(subscription.id)
    }
  }, [selectedIndex, subscription?.id, index, onDeleteMarketQuery])

  return (
    <>
      <MarketLoadDialog
        ref={marketDialogRef}
        title={t('Market:dialog.title')}
      />
      <div
        role='tabpanel'
        hidden={selectedIndex !== index}
        id={`market-outer-${index}`}
        aria-labelledby={`market-outer-${index}`}
      >
        {selectedIndex === index && (
          <>
            {index !== 0 && (
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                  value={currentTab}
                  onChange={handleChange}
                  variant='scrollable'
                  scrollButtons
                  allowScrollButtonsMobile
                  aria-label={`market-inner-${index}`}
                  className='market tab-header'
                >
                  <Tab
                    label={t('Market:tab.form')}
                    icon={<SearchIcon />}
                    iconPosition='start'
                  />
                  <Tab
                    label={t('Market:tab.table')}
                    icon={<TableChartIcon />}
                    iconPosition='start'
                  />
                  <Tab
                    label={t('Market:tab.deleteTab')}
                    icon={<DeleteForeverIcon />}
                    iconPosition='start'
                    disabled={!subscription?.id}
                    sx={{ color: appTheme.palette.error.main }}
                  />
                </Tabs>
              </Box>
            )}
            <div
              role='tabpanel'
              hidden={currentTab !== 0}
              id={`market-inner-tab-${currentTab}`}
              aria-labelledby={`market-inner-tab-${currentTab}`}
              className='market tab-wrapper'
            >
              {currentTab === 0 && (
                <MarketForm
                  onCreateMarketQuery={onCreateMarketQuery}
                  onUpdateMarketQuery={onUpdateMarketQuery}
                  subscription={subscription}
                />
              )}
              {currentTab === 1 && (
                <Paper className='market table'>
                  <TableVirtuoso
                    data={mapMarketToRows()}
                    components={MarketVirtuosoTableComponents}
                    fixedHeaderContent={MarketFixedHeaderContent}
                    itemContent={(_, row: IFormattedMarket) =>
                      MarketRowContent(_, row, marketDialogRef)
                    }
                  />
                </Paper>
              )}
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default MarketTab
