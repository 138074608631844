import { Currency } from '../types'
import { ILoad } from './models'
import { IUpdateLoadRequest } from './requests'

export const createUpdateLoadRequest = (load: ILoad): IUpdateLoadRequest => {
  const updateLoadRequest: IUpdateLoadRequest = {
    id: load.id,
    externalId: load.externalId,
    type: load.type,
    stops: load.stops,
    published: load.published,
    archived: load.archived,
    commodityName: load.commodityName,
    publishedPriceCurrency: load.publishedPriceCurrency! as Currency,
    weight: load.weight,
    weightUnit: load.weightUnit,
    shipperId: load.shipper?.id || '',
    carrierId: load.carrier?.id || '',
    statusName: load.statusName || '',
    truckId: load.truck?.id || '',
    trailerId: load.trailer?.id || '',
    driverIds: load.drivers ? load.drivers.map((driver) => driver.id) : [],
    invoiceStatus: load.invoiceStatus || '',
    shipperPrice: load.shipperPrice || 0,
    shipperPriceCurrency: load.shipperPriceCurrency! as Currency,
    carrierPrice: load.carrierPrice || 0,
    carrierPriceCurrency: load.carrierPriceCurrency! as Currency,
    description: load.description,
    equipment: load.equipment,
    publishedPrice: load.publishedPrice || 0,
    tags: load.tags ?? [],
    services: load.services,
    paymentDays: load.paymentDays,
    vehicleSize: load.vehicleSize,
  }

  return updateLoadRequest
}
