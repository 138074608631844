import styles from './countryFlag.module.scss'

const CountryFlag = ({ countryName }: { countryName: string }): JSX.Element => {
  return (
    <div>
      <img
        className={styles.imageFlag}
        src={`https://skr-common.s3.eu-central-1.amazonaws.com/country_flags/${countryName.toLowerCase()}.svg`}
        alt={'country_flag'}
      />
    </div>
  )
}

export default CountryFlag
