import { useContext } from 'react'
import { t } from 'i18next'
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import AlertContext from '../context/AlertProvider'

const useRefetchWithAlert = <T extends () => Promise<QueryReturnValue<any>>>(refresh: any): T => {
  const alertContext = useContext(AlertContext)

  if (!alertContext) {
    throw new Error('AlertContext is not available. Make sure to provide it in the component tree.')
  }

  const { dispatch } = alertContext

  const enhancedFunction = () => {
    refresh().then((res: any) => {
      if (res.data) {
        dispatch({
          type: 'SHOW',
          payload: {
            content: t('Common:UI.responses.dataRefreshed'),
            severity: 'success',
          },
        })
      }
      if (res.error) {
        dispatch({
          type: 'SHOW',
          payload: {
            content: t('Common:UI.responses.dataNotRefreshed'),
            severity: 'error',
          },
        })
      }
    })
  }

  return enhancedFunction as T
}

export default useRefetchWithAlert
