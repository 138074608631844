import { ITruck, ITrailer } from './models'
import { IUpdateTruckRequest, IUpdateTrailerRequest } from './requests'

export const createUpdateTruckRequest = (truck: ITruck): IUpdateTruckRequest => {
  const updateTruckRequest: IUpdateTruckRequest = {
    id: truck.id,
    plate: truck.plate,
    companyId: truck.companyId,
    isCarrierOwned: truck.isCarrierOwned,
    carrierId: truck.carrierId,
    number: truck.number,
    vin: truck.vin,
    make: truck.make,
    model: truck.model,
    year: truck.year,
    archived: truck.archived,
  }

  return updateTruckRequest
}

export const createUpdateTrailerRequest = (trailer: ITrailer): IUpdateTrailerRequest => {
  const updateTrailerRequest: IUpdateTrailerRequest = {
    id: trailer.id,
    plate: trailer.plate,
    companyId: trailer.companyId,
    category: trailer.category,
    isCarrierOwned: trailer.isCarrierOwned,
    carrierId: trailer.carrierId,
    number: trailer.number,
    vin: trailer.vin,
    make: trailer.make,
    model: trailer.model,
    year: trailer.year,
    archived: trailer.archived,
  }

  return updateTrailerRequest
}
