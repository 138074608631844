import { useCallback, useState } from 'react'

export type OptimisticUpdateMethod = 'CREATE' | 'UPDATE' | 'DELETE'

/**
 * The `useOptimisticUpdate` function in TypeScript provides functionality for optimistic updates in
 * React components.
 * @param {T[]} [initialData] - The `initialData` parameter is an optional array of objects that have
 * an `id` property of type `string` or `number`. This data will be used to initialize the state of the
 * `useOptimisticUpdate` hook.
 * @returns The `useOptimisticUpdate` custom hook is returning an object with the following properties
 * and methods:
 */
export const useOptimisticUpdate = <T extends { id: string | number }>(initialData?: T[]) => {
  const [data, setData] = useState<T[] | undefined>(initialData)
  const [updatedData, setUpdatedData] = useState<T[] | undefined>(initialData)

  const onSuccessRequest = (newData?: T) => {
    if (newData && data) {
      setData([newData, ...data] as T[])
    } else if (newData && !data) {
      setData([newData])
    } else {
      setData(updatedData)
    }
  }

  const onFailedRequest = () => {
    setData(initialData)
    setUpdatedData(initialData)
  }

  /* The `updateData` function defined using `useCallback` in the `useOptimisticUpdate` custom hook is
  responsible for updating the data in an optimistic way based on the specified method (`CREATE`,
  `UPDATE`, or `DELETE`) and the object to be updated. */
  const updateData = useCallback(
    (method?: OptimisticUpdateMethod, updatedObject?: T) => {
      switch (method) {
        case 'CREATE': {
          const newData = [updatedObject, ...(data ?? [])] as T[]
          setUpdatedData(newData)
          break
        }
        case 'UPDATE': {
          const newData = data?.map((obj) => (obj.id === updatedObject?.id ? updatedObject : obj))
          setUpdatedData(newData)
          break
        }
        case 'DELETE': {
          const newData = data?.filter((obj) => obj.id !== updatedObject?.id)
          setUpdatedData(newData)
          break
        }
        default:
          break
      }
    },
    [data],
  )

  const reloadData = useCallback((newData: T[]) => {
    setData(newData)
    setUpdatedData(newData)
  }, [])

  return {
    updatedData,
    updateData,
    onSuccessRequest,
    onFailedRequest,
    reloadData,
  }
}
