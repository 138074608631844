import { ITableColumn } from '@pkg/sokarUI'
import { t } from 'i18next'
import { Box, Typography, IconButton } from '@mui/material'
import { IDocument } from '@pkg/documents/models'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'

export const DocumentsArchiveColumns: ITableColumn[] = [
  {
    label: t('Document:archive.actions'),
    dataKey: 'options',
    isSortable: false,
  },
  {
    label: t('Document:archive.name'),
    dataKey: 'name',
    isSortable: true,
  },
  {
    label: t('Document:archive.category'),
    dataKey: 'category',
    isSortable: false,
  },
]

export const DocumentsArchiveCellRenderer = (
  { dataKey }: ITableColumn,
  row: IDocument,
  handleDownload: (doc: IDocument) => void,
): JSX.Element => {
  if (dataKey == 'options') {
    return (
      <Box>
        <IconButton onClick={() => handleDownload(row)}>
          <CloudDownloadIcon />
        </IconButton>
      </Box>
    )
  }
  if (dataKey == 'category') {
    return <Typography>{t(`Document:categories.${row.category}`)}</Typography>
  }

  return <Typography>{row[dataKey as keyof IDocument] as string}</Typography>
}
