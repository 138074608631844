import React, { useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  TextField,
  Button,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Checkbox,
  FormControlLabel,
  Container,
  Grid,
  Typography,
  Modal,
} from '@mui/material'
import PhoneNumberField from '@lib/PhoneNumberField'
import { IRegisterAskRequest } from '@pkg/users/requests'
import './styles.scss'
import Logo from '@assets/img/sokar_logo.svg'
import { registerSchema } from '@pkg/users/validators'
import { parseError } from '@shared/utils/formatters'
import { useRegisterAskMutation } from 'src/features/users/usersApiSlice'
import useAlert from '@hooks/useAlert'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@emotion/react'
import useCustomerID from '@/hooks/useCustomerID'
import { useGetCustomerIdQuery } from '@/api/apiSlice'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

const Register: React.FC = () => {
  const { t } = useTranslation()
  const appTheme: any = useTheme()
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IRegisterAskRequest>({
    resolver: yupResolver(registerSchema),
  })
  const { dispatch: dispatchAlert } = useAlert()
  const [register] = useRegisterAskMutation()

  const customerTag = useCustomerID()
  const { data: customerId } = useGetCustomerIdQuery(customerTag, { skip: !customerTag })

  const [openModal, setOpenModal] = useState(false)
  const [modalMessage, setModalMessage] = useState('')

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const onSubmit = (data: IRegisterAskRequest) => {
    if (!customerId) {
      return
    }
    data.user.roles = data.companyType.split(' ')
    data.user.customerId = customerId
    register(data)
      .unwrap()
      .then(() => {
        dispatchAlert({
          type: 'SHOW',
          payload: {
            content: t('Root:register.responseSuccess'),
            severity: 'success',
          },
        })
        setModalMessage(' ' + t('Root:register.waitForApproval'))
        setOpenModal(true)
      })
      .catch((err: any) => {
        const error = parseError<any>(err.data.message)
        dispatchAlert({
          type: 'SHOW',
          payload: {
            content: String(t(error.dictKey, { ...error.dependencies })),
            severity: 'error',
          },
        })
        // setModalMessage(String(t(error.dictKey, { ...error.dependencies })))
        // setOpenModal(true)
      })
  }

  return (
    <Container className='register-container'>
      <img
        src={Logo}
        alt={"application's logo"}
        className='register-logo'
      />
      <Typography
        component='h1'
        variant='h5'
        className='register-title'
        sx={{
          color: appTheme.palette.primary.main,
        }}
      >
        {t('Root:register.signUp')}
      </Typography>
      <Typography
        component='p'
        className='register-subtitle'
      >
        {t('Root:register.registerSubTitle')}
      </Typography>

      <form
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        className='register-form'
      >
        <Grid
          container
          spacing={2}
          className='register-grid-container'
        >
          <Grid
            item
            xs={12}
            sm={6}
            className='register-grid-item'
          >
            <Controller
              name='user.firstName'
              control={control}
              defaultValue=''
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label={t('Root:register.labelFirstName')}
                  variant='outlined'
                  fullWidth
                  required
                  error={!!error}
                  helperText={
                    <Box
                      height={10}
                      sx={{ fontSize: '0.6rem' }}
                    >
                      {error?.message}
                    </Box>
                  }
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            className='register-grid-item'
          >
            <Controller
              name='user.lastName'
              control={control}
              defaultValue=''
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label={t('Root:register.labelLastName')}
                  variant='outlined'
                  fullWidth
                  required
                  error={!!error}
                  helperText={
                    <Box
                      height={10}
                      sx={{ fontSize: '0.6rem' }}
                    >
                      {error?.message}
                    </Box>
                  }
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            className='register-grid-item'
          >
            <Controller
              name='user.email'
              control={control}
              defaultValue=''
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label={t('Root:register.labelEmail')}
                  variant='outlined'
                  fullWidth
                  required
                  error={!!error}
                  helperText={
                    <Box
                      height={10}
                      sx={{ fontSize: '0.6rem' }}
                    >
                      {error?.message}
                    </Box>
                  }
                />
              )}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            className='register-grid-item'
          >
            <Controller
              name='user.username'
              control={control}
              defaultValue=''
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label={t('Root:register.labelUsername')}
                  variant='outlined'
                  fullWidth
                  required
                  error={!!error}
                  helperText={
                    <Box
                      height={15}
                      sx={{ fontSize: '0.6rem' }}
                    >
                      {error?.message}
                    </Box>
                  }
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            className='register-grid-item'
          >
            <Controller
              name='user.password'
              control={control}
              defaultValue=''
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label={t('Root:register.labelPassword')}
                  type='password'
                  variant='outlined'
                  fullWidth
                  required
                  error={!!error}
                  helperText={
                    <Box
                      height={20}
                      sx={{ fontSize: '0.6rem' }}
                    >
                      {error?.message}
                    </Box>
                  }
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            className='register-grid-item'
          >
            <Controller
              name='user.confirmPassword'
              control={control}
              defaultValue=''
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label={t('Root:register.labelConfirmPassword')}
                  type='password'
                  variant='outlined'
                  fullWidth
                  required
                  error={!!error}
                  helperText={
                    <Box
                      height={15}
                      sx={{ fontSize: '0.6rem' }}
                    >
                      {error?.message}
                    </Box>
                  }
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            className='register-grid-item'
          >
            <Controller
              name='vatNumber'
              control={control}
              defaultValue=''
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label={t('Root:register.labelVatNumber')}
                  variant='outlined'
                  fullWidth
                  required
                  error={!!error}
                  helperText={
                    <Box
                      height={10}
                      sx={{ fontSize: '0.6rem' }}
                    >
                      {error?.message}
                    </Box>
                  }
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            className='register-grid-item'
          >
            <Controller
              name='companyType'
              control={control}
              render={({ field, fieldState: { error } }) => (
                <FormControl
                  fullWidth
                  variant='outlined'
                  required
                  error={!!error}
                >
                  <InputLabel>{t('Root:register.companyType')}</InputLabel>
                  <Select
                    {...field}
                    label='Company Type'
                    required
                    error={!!error}
                  >
                    <MenuItem value='client'>{t('Root:register.customer')}</MenuItem>
                    <MenuItem value='carrier'>{t('Root:register.carrier')}</MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid
            item
            xs={12}
            className='register-grid-item'
          >
            <Controller
              name='user.phone'
              control={control}
              defaultValue=''
              render={({ field, fieldState: { error } }) => (
                <div>
                  <PhoneNumberField
                    {...field}
                    error={error}
                  />
                </div>
              )}
            />
          </Grid>
          <Grid
            item
            xs={12}
            className='register-grid-item'
          >
            <FormControlLabel
              className='register-checkbox-label'
              control={
                <Controller
                  name='terms'
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <Checkbox
                      {...field}
                      color='primary'
                      required
                    />
                  )}
                />
              }
              label={
                <span className='register-accept'>
                  {t('Root:register.accept')}
                  <a
                    href='https://policy.onitsoft.com'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    {t('Root:register.acceptTerms')}
                  </a>
                </span>
              }
            />
          </Grid>
        </Grid>
        <Button
          type='submit'
          variant='contained'
          color='primary'
          className='register-button'
        >
          {t('Root:register.signUp')}
        </Button>
      </form>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
      >
        <Box
          className='email-sent-modal'
          style={{
            backgroundColor: appTheme.palette.background.default,
          }}
        >
          <CheckCircleIcon
            className='email-icon'
            sx={{
              color: appTheme.palette.primary.main,
            }}
          />
          <Typography
            className='modal-title'
            variant='h6'
            component='h2'
          >
            {t('Root:register.success')}
          </Typography>
          <Typography
            className='modal-message'
            variant='body2'
          >
            {modalMessage}
          </Typography>
          <a href='/signin'>
            <Button
              variant='contained'
              color='primary'
              onClick={handleCloseModal}
              fullWidth
            >
              {t('Root:register.btnText')}
            </Button>
          </a>
        </Box>
      </Modal>
    </Container>
  )
}

export default Register
