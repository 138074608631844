import { t } from 'i18next'
import dayjs from 'dayjs'
import { FieldError } from 'react-hook-form'
import appConfig from '@/config'

import styles from './dateField.module.scss'

import { Box, Stack, Tooltip, IconButton } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'

interface DateFieldProps {
  error?: FieldError
  value: any
  onChange: (arg: any) => void
  className?: string
  onFocus?: () => void
}

const DateField = ({ error, value, onChange, className, onFocus }: DateFieldProps): JSX.Element => {
  return (
    <Box className={`${styles.wrapper} ${className}`}>
      <DatePicker
        format={appConfig.DATE_FORMAT}
        label={t('Market:offerDialog.date')}
        value={dayjs(value)}
        onChange={onChange}
        className={styles.textField}
        slotProps={{
          textField: {
            onFocus,
            error: !!error,
            helperText: (
              <Box
                className={styles.errorBox}
                height={2}
              >
                {error?.message}
              </Box>
            ),
          },
        }}
      />
      <Stack
        direction={'column'}
        className={styles.dateModifier}
      >
        <Tooltip title={t('Market:offerDialog.addDay')}>
          <IconButton onClick={() => onChange(dayjs(value).add(1, 'day'))}>
            <AddIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={t('Market:offerDialog.subtractDay')}>
          <IconButton onClick={() => onChange(dayjs(value).subtract(1, 'day'))}>
            <RemoveIcon />
          </IconButton>
        </Tooltip>
      </Stack>
    </Box>
  )
}

export default DateField
