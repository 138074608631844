export const eqType = [
  'V',
  'R',
  'F',
  'VR',
  'C',
  'SD',
  'DF',
  'DD',
  'SS',
  'FT',
  'E',
  'T',
  'B',
  'Z',
  'M',
  'Q',
  'P',
  'CNRU',
  'CPPU',
  'CSXU',
  'EMHU',
  'EMPU',
  'EMWU',
  'EPTY',
  'PACU',
  'W',
  'G',
  'K',
  'DR',
  'H',
  'FWS',
  'BT',
  'ZS',
  'XM',
  'MV',
  'MF',
  'FM',
  'DV',
  'LALL',
  'SDL',
  'A',
  'BL',
  'RC',
  'HS',
  'GM',
  'GS',
  'FS',
  'SDS',
  'RTC',
  'RTB',
  'WFB',
  'PO',
  'DDT',
  'DDB',
  'DDF',
  'SFB',
  'SFC',
  'RBC',
  'FB',
  'FBT',
  'FBX',
  'SKC',
  'LL',
  'LLS',
  'LLX',
  'TKC',
  'TKF',
  'IM',
  'IMF',
]

export const marketMode = ['TL_LTL', 'TL', 'LTL']
