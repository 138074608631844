export const trailerTypes = {
  TF: 'Equipment.curtains',
  T: 'Equipment.curtains_standard',
  TB: 'Equipment.curtains_bde',
  TW: 'Equipment.coilmulde',
  TJ: 'Equipment.joloda',
  TM: 'Equipment.mega',
  V: 'Equipment.van',
  VB: 'Equipment.van_box',
  R: 'Equipment.reefer',
  RI: 'Equipment.isoterm',
  RH: 'Equipment.cold_hook',
  CC: 'Equipment.tanker_chemicals',
  CG: 'Equipment.tanker_gas',
  CP: 'Equipment.tanker_petroleum',
  CF: 'Equipment.tanker_food',
  CO: 'Equipment.tanker_other',
  CS: 'Equipment.tanker_silo',
  DW: 'Equipment.dumper_walkingfloor',
  D: 'Equipment.dumper',
  SC20: 'Equipment.tanker_20',
  ST20: 'Equipment.standard_20',
  SC40: 'Equipment.tanker_40',
  ST40: 'Equipment.standard_40',
  ST45: 'Equipment.standard_45',
  DD: 'Equipment.dropdeck',
  O: 'Equipment.other',
  D7: 'Equipment.tandem_77_77',
  F: 'Equipment.flatbed',
  B: 'Equipment.bus',
}
