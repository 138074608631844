/* eslint-disable react/display-name */
import styles from './contentTable.module.scss'

import { ExpandLess as ExpandLessIcon, ExpandMore as ExpandMoreIcon } from '@mui/icons-material'
import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material'
import {
  forwardRef,
  Fragment,
  MouseEvent,
  Reducer,
  useImperativeHandle,
  useReducer,
  useState,
} from 'react'
import { TableComponents, TableVirtuoso } from 'react-virtuoso'
import { ITableColumn, ITheme } from 'src/pkg/sokarUI'

import { sortDataSet } from './functions'

/**
 * TableProps Interface - Defines the properties required for the `ContentTable` component
 *
 * @property {JSX.Element} [freeActions] - Optional element for action buttons in the table header
 * @property {(row: unknown) => void} [openDialog] - Callback for opening a dialog on row click
 * @property {object} [style] - Additional CSS styles for table container
 * @property {(column: ITableColumn, row: unknown, appTheme?: ITheme) => JSX.Element} cellRenderer - Defines how each row's
 * cell will be rendered
 * @property {() => void} [handleEndReached] - Callback when end of table is reached
 * @property {boolean} [batchOperations] - Enables checkboxes for batch operations
 */
interface ITableProps {
  freeActions?: JSX.Element
  openDialog?: (row: any) => void
  style?: object
  cellRenderer: (column: ITableColumn, row: any, appTheme?: ITheme) => JSX.Element
  handleEndReached?: () => void
  batchOperations?: boolean
}

/**
 * `ITableRef` - Interface for controlling the table. It exports funcstions for external components
 *
 * @property {(dataset: unknown, columns: ITableColumn[]) => void, appTheme?: ITheme} open - Opens table
 * with provided dataset, columns & optional theme interface
 * @property {() => string[]} getSelection - Retrieves currently selected row Ids (requires batchOperations to be `true`)
 * @property {() => void} clearSelection - Restores selection state to default value
 */
export interface ITableRef {
  open: (dataset: any, columns: ITableColumn[]) => void
  getSelection: () => string[]
  clearSelection: () => void
}

/**
 * IContentTableReducerState Interface - Describes the state structure for the `contentTableReducer`
 *
 * @interface IContentTableReducerState
 * @property {unknown[]} dataset - The dataset displayed in the table
 * @property {ITableColumn[]} columns - Columns displayed in the table
 * @property {string[]} selection - Selected row Ids for batch actions
 */
interface IContentTableReducerState {
  dataset: unknown[]
  columns: ITableColumn[]
  selection: string[]
}

const defaultContentTableValues: IContentTableReducerState = {
  dataset: [],
  columns: [],
  selection: [],
}

/**
 * ContentTableActions Type - Defines the action structure for the `contentTableReducer`
 *
 * @property {'handleDatasetChange' | 'handleSelectionChange' | 'handleColumnsChange' | 'clearSelection'} command - The action type
 * @property {unknown[] | string | ITableColumn[]} payload - The action payload
 */
type ContentTableActions =
  | { command: 'handleDatasetChange'; payload: any[] }
  | { command: 'handleSelectionChange'; payload: string }
  | { command: 'handleColumnsChange'; payload: ITableColumn[] }
  | { command: 'clearSelection' }

/**
 * `contentTableReducer` - Reducer function to manage state updates for `ContentTable`
 *
 * @param {IContentTableReducerState} state - Current state of the table
 * @param {ContentTableActions} action - Action to modify the state
 * @returns {IContentTableReducerState} The updated state after applying the action
 */
const contentTableReducer: Reducer<IContentTableReducerState, ContentTableActions> = (
  state,
  action,
) => {
  switch (action.command) {
    case 'handleDatasetChange':
      return {
        ...state,
        dataset: action.payload,
      }
    case 'handleSelectionChange': {
      const newSelection = state.selection.includes(action.payload)
        ? state.selection.filter((id: string) => id !== action.payload)
        : [...state.selection, action.payload]

      return {
        ...state,
        selection: newSelection,
      }
    }
    case 'handleColumnsChange':
      return {
        ...state,
        columns: action.payload,
      }
    case 'clearSelection':
      return {
        ...state,
        selection: [],
      }
    default:
      return state
  }
}

/**
 * ContentTable - Main component rendering a virtualized table with columns, dataset & optional selection
 *
 * @param {TableProps} props - Table properties to configure appearance and behavior
 * @returns {JSX.Element} The virtualized content table
 */
const ContentTable = forwardRef<ITableRef, ITableProps>((props, ref) => {
  const appTheme: ITheme = useTheme()
  const [state, dispatch] = useReducer(contentTableReducer, defaultContentTableValues)

  useImperativeHandle(ref, () => ({
    open(_dataset: any[] | null, _columns: ITableColumn[]) {
      if (_dataset === null) return
      dispatch({ command: 'handleDatasetChange', payload: _dataset })
      dispatch({ command: 'handleColumnsChange', payload: _columns })
    },
    getSelection() {
      return state.selection
    },
    clearSelection() {
      dispatch({ command: 'clearSelection' })
    },
  }))

  const rowContent = (_index: number, row: any) => {
    const handleRowClick = (event: MouseEvent, _row: any): void => {
      const target = event.target as HTMLElement
      if (!['P', 'TD'].includes(target.tagName)) {
        return
      }
      if (props.openDialog) {
        props.openDialog(_row)
      }
    }

    return (
      <Fragment key={_index}>
        {state.columns.map((column, _idx) => (
          <TableCell
            className={`${
              props.openDialog
                ? styles.contentTableClickableCell
                : styles.contentTableNotclickableCell
            }
                ${
                  ['deliveryCity', 'pickupCity'].includes(column.dataKey) &&
                  styles.contentTableNoCenter
                }`}
            key={column.dataKey}
            onClick={(event) => handleRowClick(event, row)}
          >
            {props.cellRenderer(column, row, appTheme)}
          </TableCell>
        ))}
        {props.freeActions && (
          <TableCell
            className={
              props.openDialog
                ? styles.contentTableClickableCell
                : styles.contentTableNotclickableCell
            }
            onClick={(event) => handleRowClick(event, row)}
          >
            {props.batchOperations && (
              <Checkbox
                checked={state.selection.includes(row.id)}
                onClick={() => dispatch({ command: 'handleSelectionChange', payload: row.id })}
              />
            )}
            {props.cellRenderer({ label: '', dataKey: 'options', isSortable: false }, row)}
          </TableCell>
        )}
      </Fragment>
    )
  }

  const [sortingKey, setSortingKey] = useState<string>('')
  const [counter, setCounter] = useState<number>(0)

  const fixedHeaderContent = () => {
    const SortableHeaderCell = ({ column }: { column: ITableColumn }): JSX.Element => {
      const handleSortableCellClick = () => {
        if (sortingKey !== column.dataKey) {
          setCounter(1)
        } else {
          setCounter((cnt) => (cnt === 2 ? 0 : cnt + 1))
        }
        setSortingKey(column.dataKey)
      }

      return (
        <TableCell
          key={column.dataKey}
          variant={'head'}
          className={styles.clickableContentTableHeaderCell}
          onClick={handleSortableCellClick}
          sx={{
            backgroundColor: appTheme.palette.primary.main,
          }}
        >
          <Typography
            className={styles.contentTableSortableCell}
            sx={{ color: appTheme.palette.primary.contrastText }}
            key={counter}
          >
            {column.label}
            {(sortingKey !== column.dataKey || counter === 0) && (
              <ExpandLessIcon className={styles.contentTableHiddenArrow} />
            )}
            {sortingKey === column.dataKey && counter === 1 && <ExpandMoreIcon />}
            {sortingKey === column.dataKey && counter === 2 && <ExpandLessIcon />}
          </Typography>
        </TableCell>
      )
    }

    const HeaderCell = ({ column }: { column: ITableColumn }): JSX.Element => {
      return (
        <TableCell
          key={column.dataKey}
          variant={'head'}
          className={styles.notclickableContentTableHeaderCell}
          sx={{
            backgroundColor: appTheme.palette.primary.main,
          }}
        >
          <Typography sx={{ color: appTheme.palette.primary.contrastText }}>
            {column.label}
          </Typography>
        </TableCell>
      )
    }

    return (
      <TableRow>
        {state.columns.map((column, index) =>
          column.isSortable ? (
            <SortableHeaderCell
              column={column}
              key={index}
            />
          ) : (
            <HeaderCell
              column={column}
              key={index}
            />
          ),
        )}
        {props.freeActions && (
          <TableCell
            variant={'head'}
            sx={{
              backgroundColor: appTheme.palette.primary.main,
            }}
          >
            <div className={styles.contentTableFreeActions}>{props.freeActions}</div>
          </TableCell>
        )}
      </TableRow>
    )
  }

  const VirtuosoTableComponents: TableComponents<any> = {
    Scroller: forwardRef<HTMLDivElement>((_props, _ref) => (
      <TableContainer
        component={Paper}
        {..._props}
        ref={_ref}
        sx={{
          backgroundColor: appTheme.palette.background.default,
          overflowX: 'hidden',
        }}
      />
    )),
    Table: (_props) => (
      <Table
        {..._props}
        sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }}
      />
    ),
    // @ts-ignore
    TableHead,
    TableRow: ({ item: _item, ..._props }) => (
      <TableRow
        {..._props}
        className={`${
          _props['data-index'] === 0 &&
          `forcarriers-market-tour-8
          offersmanager-market-tour-4
          ongoing-market-tour-4
          loads-fleet-tour-4
          drivers-fleet-tour-3
          trucks-fleet-tour-3
          trailers-fleet-tour-3`
        } ${styles.tableRow}`}
      />
    ),
    TableBody: forwardRef<HTMLTableSectionElement>((_props, _ref) => (
      <TableBody
        {..._props}
        ref={_ref}
      />
    )),
  }

  return (
    <Paper
      className={styles.contentTableContainer}
      sx={props.style}
    >
      <TableVirtuoso
        data={sortDataSet(state.dataset, sortingKey, counter)}
        itemContent={rowContent}
        fixedHeaderContent={fixedHeaderContent}
        components={VirtuosoTableComponents}
        endReached={props.handleEndReached}
      />
    </Paper>
  )
})

ContentTable.displayName = 'Content table'

export default ContentTable
