import * as Sentry from '@sentry/react'
import { IUser } from '@pkg/users/models'
import { createSlice } from '@reduxjs/toolkit'

export interface IAuthSlice {
  token: string | null
  refreshToken: string | null
  userId: string | null
  customerId: string | null
  userInfo: IUser | null
  tokenExpiry: number | null
  countryCode: string
}

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    token: null,
    refreshToken: null,
    tokenExpiry: new Date().getTime(),
    userId: null,
    customerId: null,
    userInfo: null,
  },
  reducers: {
    setCredentials: (state, action) => {
      const { refreshToken, sessionToken, userId, customerId } = action.payload
      const currentTime = new Date()
      let options = { timeZone: 'utc' }
      let utcTime = currentTime.toLocaleString('en-US', options)
      const currentUTCTime = new Date(utcTime)
      state.userId = userId
      state.token = sessionToken
      state.refreshToken = refreshToken
      state.customerId = customerId
      state.tokenExpiry = currentUTCTime.getTime() + 60 * 60 * 1000

      if (!userId) {
        Sentry.setUser(null)
      }
    },
    setUser: (state, action) => {
      const { userInfo } = action.payload
      state.userInfo = userInfo

      if (!state.userId) {
        Sentry.setUser(null)
      } else {
        Sentry.setUser({
          id: state.userId,
          email: userInfo?.email,
          username: userInfo?.username,
        })
      }
    },
    signOut: (state) => {
      state.token = null
      state.refreshToken = null
      state.userId = null
      state.userInfo = null

      Sentry.setUser(null)
    },
  },
})

export const { setCredentials, setUser, signOut } = authSlice.actions

export default authSlice.reducer

export const selectCurrentToken = (state: { auth: IAuthSlice }) => state.auth.token
export const selectRefreshToken = (state: { auth: IAuthSlice }) => state.auth.refreshToken
export const selectExpiryTimestamp = (state: { auth: IAuthSlice }) =>
  state.auth.tokenExpiry ? state.auth.tokenExpiry : null
export const selectCurrentUser = (state: any) => state.auth.userInfo
export const selectCurrentRoles = (state: { auth: IAuthSlice }) => state.auth.userInfo?.roles
