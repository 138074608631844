import { Theme } from './pkg/types'

export const setTheme = (theme: Theme) => {
  localStorage.setItem('theme', theme)
}

export const getTheme = () => {
  const theme = localStorage.getItem('theme')
  if (!theme) {
    const mq = window.matchMedia('(prefers-color-scheme: light)')
    localStorage.setItem('theme', mq.matches ? 'dark' : 'light')
    return localStorage.getItem('theme') as Theme
  }
  return theme as Theme
}
