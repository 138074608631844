import { t } from 'i18next'
import { ITableColumn } from '@pkg/sokarUI'
import { IDriver } from '@pkg/drivers/models'
import { parsePhoneNumber, getFileAlertColour } from '@shared/utils/formatters'

import { SvgIconProps, Chip, Tooltip, Typography } from '@mui/material'
import WarningIcon from '@mui/icons-material/Warning'

export const driversTableColumns: ITableColumn[] = [
  {
    label: t('Fleet:driversTable.name'),
    dataKey: 'firstName',
    isSortable: true,
  },
  {
    label: t('Fleet:driversTable.surname'),
    dataKey: 'lastName',
    isSortable: true,
  },
  {
    label: t('Fleet:driversTable.phone'),
    dataKey: 'phone',
    isSortable: false,
  },
  {
    label: 'e-mail',
    dataKey: 'email',
    isSortable: false,
  },
  {
    label: t('Fleet:driversTable.driverType'),
    dataKey: 'groups',
    isSortable: false,
  },
]

export const driverTableCellRenderer = (column: ITableColumn, row: IDriver): JSX.Element => {
  if (column.dataKey === 'groups')
    return (
      <Chip
        color={'primary'}
        label={
          row.isOwner ? t('Fleet:driversTable.ownerDriver') : t('Fleet:driversTable.fleetDriver')
        }
      />
    )
  if (column.dataKey === 'phone') {
    return <Typography>{parsePhoneNumber(row[column.dataKey])}</Typography>
  }
  if (column.dataKey === 'options') {
    if (row.documents?.length > 0 && getFileAlertColour(row.documents) != 'text') {
      return (
        <Tooltip title={t('Common:documentRequiresAttention')}>
          <WarningIcon color={getFileAlertColour(row.documents) as SvgIconProps['color']} />
        </Tooltip>
      )
    }
  }

  return <Typography>{row[column.dataKey as keyof IDriver] as string}</Typography>
}
