import { useGetDriverQuery, useSearchDriverQuery } from '@features/drivers/driversApiSlice'
import useOwnUser from '@hooks/useOwnUser'
import useRefetchWithAlert from '@hooks/useRefetchWithAlert'
import ConfirmationDialog, { IConfirmationDialogRef } from '@lib/ConfirmationDialog'
import ContentList, { IContentListRef } from '@lib/ContentList'
import ContentTable, { ITableRef } from '@lib/ContentTable'
import SideDialog, { ISideDialogRef } from '@lib/SideDialog'
import AddIcon from '@mui/icons-material/Add'
import ReplayIcon from '@mui/icons-material/Replay'
import { Box, IconButton, Skeleton, Tooltip, useMediaQuery, useTheme } from '@mui/material'
import { IDriver } from '@pkg/drivers/models'
import { ITheme } from '@pkg/sokarUI'
import { t } from 'i18next'
import { FC, Fragment, useCallback, useEffect, useRef, useState } from 'react'

import { useSokarTour } from '@/hooks/useSokarTour'

import CreateDriverDialog from './components/createDriverDialog'
import DriverCard from './components/driverCard'
import DriverDialog from './components/driverDialog'
import DriversOptionsCard from './components/driversOptionsCard'
import styles from './drivers.module.scss'
import {
  driversTableColumns as columns,
  driverTableCellRenderer as cellRenderer,
} from './view.helpers'

const DriversView: FC = () => {
  const appTheme: ITheme = useTheme()
  const isMobile: boolean = useMediaQuery(appTheme.breakpoints.down('md'))
  const { companyId } = useOwnUser()

  const createDriverDialogRef = useRef<ISideDialogRef>(null)
  const handleCreateDriverDialogOpen = () => {
    createDriverDialogRef.current?.open({})
  }
  const handleCreateDriverDialogClose = () => createDriverDialogRef.current?.close()

  const driverDialogRef = useRef<ISideDialogRef>(null)
  const handleDriverDialogOpen = useCallback((row: IDriver): void => {
    driverDialogRef.current?.open(row)
  }, [])
  const handleDriverDialogClose = useCallback((): void => {
    driverDialogRef.current?.close()
  }, [])

  const {
    data: drivers,
    isSuccess: isDriversSuccess,
    refetch: refetchDrivers,
  } = useGetDriverQuery(
    { companyId: companyId ?? '', archived: false, key: 'driversTable' },
    { skip: !companyId },
  )

  const { setIsOpen, currentStep } = useSokarTour(
    !!drivers,
    true,
    'drivers-fleet-tour',
    'Fleet:onboarding.drivers',
    9,
  )

  useEffect(() => {
    if (currentStep >= 2 && drivers?.length === 0) {
      setIsOpen(false)
    }
    if (currentStep === 2 && drivers) {
      driverDialogRef.current?.open(drivers[0])
    }
  }, [currentStep, setIsOpen, drivers])

  const refresh = useRefetchWithAlert(refetchDrivers)

  const [isDriversSearched, setIsDriversSearched] = useState<boolean>(false)
  const [searchPhrase, setSearchPhrase] = useState<string>('')
  const handleSearchDrivers = (phrase: string, isSearched: boolean) => {
    setIsDriversSearched(isSearched)
    setSearchPhrase(phrase)
  }

  const { data: searchedDrivers } = useSearchDriverQuery(
    { phrase: searchPhrase, companyId, archived: false },
    { skip: !isDriversSearched },
  )

  const ref = useRef<ITableRef>(null)
  const listRef = useRef<IContentListRef>(null)
  useEffect((): void => {
    if (isDriversSearched && !isMobile) {
      ref.current?.open(searchedDrivers, columns)
      return
    }
    if (isDriversSearched && isMobile) {
      listRef.current?.open(searchedDrivers)
      return
    }
    if (isMobile) {
      listRef.current?.open(drivers)
      return
    }
    ref.current?.open(drivers, columns)
  }, [isMobile, drivers, isDriversSearched, searchedDrivers])

  const freeActions: JSX.Element = (
    <Fragment>
      <Tooltip
        title={t('Fleet:driversTable.add')}
        placement={'left'}
        arrow={true}
        className='drivers-fleet-tour-2'
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={handleCreateDriverDialogOpen}
        >
          <AddIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('Fleet:driversTable.refresh')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => refresh()}
        >
          <ReplayIcon />
        </IconButton>
      </Tooltip>
    </Fragment>
  )

  const confirmationDialogRef = useRef<IConfirmationDialogRef>(null)

  const getProperComponent = (): JSX.Element => {
    if (!isDriversSuccess) {
      return (
        <Box className={styles.loader}>
          {Array(12)
            .fill(null)
            .map((_, index) => (
              <Skeleton
                key={index}
                animation={'wave'}
              />
            ))}
        </Box>
      )
    }
    if (isMobile) {
      return (
        <ContentList
          ref={listRef}
          listComponent={DriverCard}
          openDialog={handleDriverDialogOpen}
        />
      )
    }
    return (
      <ContentTable
        ref={ref}
        freeActions={freeActions}
        cellRenderer={cellRenderer}
        openDialog={handleDriverDialogOpen}
      />
    )
  }

  return (
    <Fragment>
      <DriversOptionsCard handleSearch={handleSearchDrivers} />
      {getProperComponent()}
      <SideDialog
        ref={createDriverDialogRef}
        title={t('Fleet:createDriverDialog.title')}
      >
        <CreateDriverDialog
          refetch={refetchDrivers}
          handleClose={handleCreateDriverDialogClose}
        />
      </SideDialog>
      <SideDialog
        ref={driverDialogRef}
        title={t('Fleet:driverDialog.title')}
      >
        <DriverDialog
          refetch={refetchDrivers}
          elementRef={driverDialogRef}
          handleClose={handleDriverDialogClose}
          confirmationRef={confirmationDialogRef}
        />
      </SideDialog>
      <ConfirmationDialog ref={confirmationDialogRef} />
    </Fragment>
  )
}

export default DriversView
