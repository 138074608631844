import useCustomerID from '@hooks/useCustomerID'
import SecurityUpdateIcon from '@mui/icons-material/SecurityUpdate'
import {
  Box,
  IconButton,
  Popover,
  PopoverVirtualElement,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import { ITheme } from '@pkg/sokarUI'
import { t } from 'i18next'
import { FC, Fragment, MouseEvent, Reducer, useReducer } from 'react'

import QRCode from '@/assets/img/mobile_app_qr.svg'

import styles from './dropdown.module.scss'

interface mobileAppDropDownState {
  isDropDownOpen: boolean
  anchor:
    | Element
    | (() => Element)
    | PopoverVirtualElement
    | (() => PopoverVirtualElement)
    | undefined
    | null
}

const defaultValues: mobileAppDropDownState = {
  isDropDownOpen: false,
  anchor: null,
}

type mobileAppDropDownActions =
  | {
      command: 'handleDropDownOpen'
      payload: MouseEvent<HTMLButtonElement>
    }
  | {
      command: 'handleDropDownClose'
    }

const mobileAppDropDownReducer: Reducer<mobileAppDropDownState, mobileAppDropDownActions> = (
  state,
  action,
) => {
  switch (action.command) {
    case 'handleDropDownOpen':
      return {
        ...state,
        isDropDownOpen: !state.isDropDownOpen,
        anchor: action.payload.target as Element,
      }
    case 'handleDropDownClose':
      return {
        ...state,
        isDropDownOpen: false,
      }
    default:
      return state
  }
}

interface MobileAppDropDownProps {
  style: object
}

const MobileAppDropDown: FC<MobileAppDropDownProps> = ({ style }) => {
  const [state, dispatch] = useReducer(mobileAppDropDownReducer, defaultValues)
  const tag = useCustomerID()
  const appTheme: ITheme = useTheme()

  return (
    <Fragment>
      <Tooltip title={t('Common:customerTagButton')}>
        <IconButton
          sx={style}
          onClick={(e) => dispatch({ command: 'handleDropDownOpen', payload: e })}
        >
          <SecurityUpdateIcon sx={{ color: appTheme.palette.text.light }} />
        </IconButton>
      </Tooltip>
      <Popover
        open={state.isDropDownOpen}
        anchorEl={state.anchor}
        className={styles.popover}
        onClose={() => dispatch({ command: 'handleDropDownClose' })}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box className={styles.dropDownContent}>
          <img
            src={QRCode}
            className={styles.QR}
            alt={String(t('Common:mobileAppQR'))}
          />
          <Typography>{t('Common:customerTag', { tag })}</Typography>
        </Box>
      </Popover>
    </Fragment>
  )
}

export default MobileAppDropDown
