import { useSelector } from 'react-redux'
import { useLocation, Navigate, Outlet } from 'react-router'
import { selectCurrentToken, selectCurrentRoles } from './authSlice'

interface RequireAuthProps {
  allowedRoles?: string[]
}

const RequireAuth = ({ allowedRoles }: RequireAuthProps) => {
  const token = useSelector(selectCurrentToken)
  const roles = useSelector(selectCurrentRoles) ?? []
  const location = useLocation()

  // TO-DO: missing token redirects to login; without proper role redirect to not authorized page
  if (!token)
    return (
      <Navigate
        to='/signin'
        state={{ from: location }}
        replace
      />
    )
  if (
    !allowedRoles ||
    roles.find((role: string) => allowedRoles?.includes(role)) ||
    roles.includes('admin')
  )
    return <Outlet />

  return (
    <Navigate
      to='/signin'
      state={{ from: location }}
      replace
    />
  ) // Unauthorized
}

export default RequireAuth
