import { string, array, number, object } from 'yup'
import { t } from 'i18next'
import { assert } from '@/shared/utils/utilities'

const Validators = {
  missing: t('Market:validators.unexpectedError'),
  specifyNumber: t('Market:validators.specifyNumber'),
  required: t('Market:validators.fieldRequired'),
  positive: t('Market:validators.mustBePositive'),
  earlyDateTo: t('Market:validators.earlyDateTo'),
  earlyDelivery: t('Market:validators.earlyDelivery'),
}
export const carrierInstanceLoadValidator = object().shape({
  externalId: string().required(
    t('Fleet:validatorResponses.externalIdIsRequiredConstraint') ?? '',
  ),
  weight: number()
    .typeError(t('Fleet:validatorResponses.specifyNumber') ?? '')
    .required(t('Fleet:validatorResponses.weightIsRequiredConstraint') ?? '')
    .positive(t('Fleet:validatorResponses.weightMustBePositiveConstraint') ?? ''),
  shipperPrice: number()
    .typeError(t('Fleet:validatorResponses.specifyNumber') ?? '')
    .required(t('Fleet:validatorResponses.priceIsRequiredConstraint') ?? '')
    .positive(t('Fleet:validatorResponses.priceMustBePositiveConstraint') ?? ''),
  stops: array()
    .of(
      object().shape({
        countryAbbreviation: string().required(
          t('Fleet:validatorResponses.countryIsRequiredConstraint') ?? '',
        ),
        city: string().required(t('Fleet:validatorResponses.cityIsRequiredConstraint') ?? ''),
        category: string().required(
          t('Fleet:validatorResponses.categoryIsRequiredConstraint') ?? '',
        ),
        date: string()
          .nullable()
          .required(t('Fleet:validatorResponses.dateIsRequiredConstraint') ?? ''),
        dateTo: string()
          .required(t('Fleet:validatorResponses.dateToIsRequiredConstraint') ?? '')
          .test(
            'isDateAfterOrEqualToStartingPoint',
            t('Fleet:validatorResponses.dateToIsAfterOrEqualDateConstraint') ?? '',
            function (value) {
              if (!value) return false
              const { date }: { date: any } = this.parent
              return new Date(value) >= new Date(date)
            },
          ),
        time: string().required(t('Fleet:validatorResponses.timeIsRequiredConstraint') ?? ''),
        timeTo: string()
          .required(t('Fleet:validatorResponses.timeToIsRequiredConstraint') ?? '')
          .test(
            'isDateAfterOrEqualToStartingPoint',
            t('Fleet:validatorResponses.dateToIsAfterOrEqualDateConstraint') ?? '',
            function (value) {
              if (!value) return false
              const { time } = this.parent
              return new Date(value) >= new Date(time)
            },
          ),
      }),
    )
    .test(
      'pickupAfterDelivery',
      t('Fleet:validatorResponses.pickupAfterDeliveryConstraint') ?? '',
      function (stops) {
        if (!stops) return false
        const sortedStops = stops.slice().sort((a, b) => {
          const dateA = new Date(a.date as string).getTime()
          const dateB = new Date(b.date as string).getTime()
          return dateA - dateB
        })

        if (sortedStops[0].category != 'P' && sortedStops[stops.length - 1].category != 'D') {
          return this.createError({
            path: `stops[${stops.length - 1}].date`,
            message: t('Fleet:validatorResponses.pickupAfterDeliveryConstraint') ?? '',
          })
        }
        return true
      },
    ),
  shipperId: string().required(t('Fleet:validatorResponses.shipperIsRequiredConstraint') ?? ''),
  carrierId: string().required(t('Fleet:validatorResponses.carrierIsRequiredConstraint') ?? ''),
  truckId: string().required(t('Fleet:validatorResponses.truckIsRequiredConstraint') ?? ''),
  trailerId: string().required(t('Fleet:validatorResponses.trailerIsRequiredConstraint') ?? ''),
  driverIds: array()
    .of(string())
    .min(1, t('Fleet:validatorResponses.driverIsRequiredConstraint') ?? ''),
})

export const brokerInstancePublishedLoadValidator = object().shape({
  weight: number()
    .typeError(t('Fleet:validatorResponses.specifyNumber') ?? '')
    .required(t('Fleet:validatorResponses.weightIsRequiredConstraint') ?? '')
    .positive(t('Fleet:validatorResponses.weightMustBePositiveConstraint') ?? ''),
  publishedPrice: number()
    .typeError(t('Fleet:validatorResponses.specifyNumber') ?? '')
    .required(t('Fleet:validatorResponses.priceIsRequiredConstraint') ?? '')
    .positive(t('Fleet:validatorResponses.priceMustBePositiveConstraint') ?? ''),
   stops: array()
    .of(
      object().shape({
        countryAbbreviation: string().required(Validators.required),
        city: string().required(Validators.required),
        category: string().required(Validators.required),
        date: string().required(Validators.required),
        dateTo: string()
          .required(Validators.required)
          .test('isDateAfterOrEqualToStartingPoint', Validators.earlyDateTo, function (value) {
            if (!value) return false
            const { date } = this.parent
            return new Date(value) >= new Date(date)
          }),
      }),
    )
    .test('pickupAfterDelivery', Validators.earlyDelivery, function (stops) {
      if (!stops) return false

      const sortedStops = stops.slice().sort((a, b) => {
        assert(typeof a.date === 'string', "stop's date cannot be undefined at this moment")
        const dateA = new Date(`${a.date} ${a.time}`).getTime()
        assert(typeof b.date === 'string', "stop's date cannot be undefined at this moment")
        const dateB = new Date(`${b.date} ${b.time}`).getTime()
        return dateA - dateB
      })

      const firstStop = sortedStops[0]
      const lastStop = sortedStops[sortedStops.length - 1]

      const firstStopDate = new Date(`${firstStop.date} ${firstStop.time}`).getTime()
      const lastStopDate = new Date(`${lastStop.date} ${lastStop.timeTo}`).getTime()

      if (firstStopDate > lastStopDate) {
        return this.createError({
          path: `stops[${stops.length - 1}].date`,
          message: Validators.earlyDelivery,
        })
      }

      return true
    }),
})

export const shipperInstancePublishedLoadValidator = object().shape({
  publishedPrice: number()
    .typeError(t('Fleet:validatorResponses.specifyNumber') ?? '')
    .required(t('Fleet:validatorResponses.priceIsRequiredConstraint') ?? '')
    .positive(t('Fleet:validatorResponses.priceMustBePositiveConstraint') ?? ''),
  stops: array().of(
    object().shape({
      countryAbbreviation: string().required(
        t('Fleet:validatorResponses.countryIsRequiredConstraint') ?? '',
      ),
      city: string().required(t('Fleet:validatorResponses.cityIsRequiredConstraint') ?? ''),
      date: string().required(t('Fleet:validatorResponses.dateIsRequiredConstraint') ?? ''),
    }),
  ),
})

export const assignCarrierValidation = object().shape({
  shipperId: string().required(t('Fleet:validatorResponses.shipperIsRequiredConstraint') ?? ''),
  carrierId: string().required(t('Fleet:validatorResponses.carrierIsRequiredConstraint') ?? ''),
  driverIds: array()
    .of(string())
    .when('addDriver', {
      is: false,
      then: array().required(t('Fleet:validatorResponses.driverIsRequiredConstraint') ?? ''),
      otherwise: array().of(string()).notRequired(),
    }),
  truckId: string().when('addTruck', {
    is: false,
    then: string().required(t('Fleet:validatorResponses.truckIsRequiredConstraint') ?? ''),
    otherwise: string().notRequired(),
  }),
  trailerId: string().when('addTrailer', {
    is: false,
    then: string().required(t('Fleet:validatorResponses.trailerIsRequiredConstraint') ?? ''),
    otherwise: string().notRequired(),
  }),
})
