import { ITableColumn, ITheme } from '@pkg/sokarUI'
import { ILoad } from '@pkg/loads/models'
import { assert } from '@shared/utils/utilities'
import { t } from 'i18next'

import { useGetUserQuery } from '@features/users/usersApiSlice'
import { Typography, Skeleton } from '@mui/material'

export const TransportsToInvoiceColumns: ITableColumn[] = [
  {
    label: t('Accounting:toInvoiceTable.externalId'),
    dataKey: 'externalId',
    isSortable: true,
  },
  {
    label: t('Accounting:toInvoiceTable.invoiceStatus'),
    dataKey: 'invoiceStatus',
    isSortable: true,
  },
  {
    label: t('Accounting:toInvoiceTable.rate'),
    dataKey: 'rate',
    isSortable: true,
  },
  {
    label: t('Accounting:toInvoiceTable.owner'),
    dataKey: 'owner',
    isSortable: true,
  },
]
const getProperColour = (status: string, appTheme: ITheme): string => {
  switch (status) {
    case 'invoiced':
      return appTheme.palette.success.main
    case 'awaiting-rev-rc':
      return appTheme.palette.warning.main
    case 'awaiting-better-paperwork':
      return appTheme.palette.error.main
    default:
      return appTheme.palette.text.primary
  }
}

const OwnerCell = ({ ownerId, colour }: { ownerId: string; colour: string }): JSX.Element => {
  const { data: owner, isSuccess: isOwnerSuccess } = useGetUserQuery(ownerId, { skip: !ownerId })

  if (!isOwnerSuccess) {
    return <Skeleton />
  }

  return <Typography color={colour}>{`${owner.firstName} ${owner.lastName}`}</Typography>
}

export const TransportsToInvoiceCellRenderer = (
  column: ITableColumn,
  row: ILoad,
  appTheme?: ITheme,
): JSX.Element => {
  assert(row.invoiceStatus != undefined, 'Load object cannot miss invoice status at this point')
  assert(appTheme != undefined, 'TransportsToInvoiceCellRenderer requires appTheme to be provided')

  const { dataKey } = column
  const { invoiceStatus, documents, shipperPrice, shipperPriceCurrency, owner } = row

  const colour = getProperColour(invoiceStatus, appTheme)

  if (dataKey === 'invoiceStatus') {
    return <Typography color={colour}>{t(`Accounting:statuses.${invoiceStatus}`)}</Typography>
  }
  if (dataKey === 'invoiceName') {
    if (!invoiceStatus || invoiceStatus != 'invoiced' || documents.length == 0) {
      return <Typography color={colour}>-</Typography>
    }

    return (
      <Typography color={colour}>
        {
          documents?.filter((document) => {
            return document.category == 'invoice'
          })[0].name
        }
      </Typography>
    )
  }
  if (dataKey === 'owner') {
    return (
      <OwnerCell
        ownerId={owner.id}
        colour={colour}
      />
    )
  }
  if (dataKey === 'rate') {
    return <Typography color={colour}>{`${shipperPriceCurrency} ${shipperPrice}`}</Typography>
  }
  return <Typography color={colour}>{row[column.dataKey as keyof ILoad] as string}</Typography>
}
