import './style.scss'

import { MenuItem, Stack, TextField, Box, useTheme } from '@mui/material'
import { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Directionals } from 'src/shared/utils/formatters'
import { FieldError } from 'react-hook-form'
import { ITheme } from '@pkg/sokarUI'

interface PhoneNumberFieldProps {
  value: any
  onChange: (arg: any) => void
  sx?: object
  error?: FieldError
}

const PhoneNumberField: React.FC<PhoneNumberFieldProps> = ({ ...props }) => {
  const { t } = useTranslation()
  const appTheme: ITheme = useTheme()
  const [directional, setDirectional] = useState<string>('001')
  const [phoneNumber, setPhoneNumber] = useState<string>('')

  useEffect(() => {
    if (props.value) {
      setDirectional(String(props.value).substring(0, 3))
      setPhoneNumber(String(props.value).substring(3, String(props.value).length))
    } else {
      setDirectional('001')
      setPhoneNumber('')
    }
  }, [props.value])

  const getDirectionals = (): JSX.Element[] => {
    const sortedDirectionals = Object.values(Directionals).sort((a, b) => Number(a) - Number(b))
    return sortedDirectionals.map(_directional => (
      <MenuItem
        key={_directional}
        value={_directional}
      >
        {formatDirectional(_directional)}
      </MenuItem>
    ))
  }

  const handlePhoneNumberChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const cleanedPhoneNumber = e.target.value.replace(/[^\d\s-]/g, '')
    setPhoneNumber(cleanedPhoneNumber)
  }

  useEffect(() => {
    props.onChange(directional + phoneNumber.replace(/\D/g, ''))
  }, [directional, phoneNumber])

  return (
    <Stack
      direction={'column'}
      sx={props.sx}
    >
      <Stack
        direction={'row'}
        spacing={2}
        className={'phone_number_field_wrapper'}
      >
        <TextField
          select
          value={directional}
          className={'phone_number_field_dir_number'}
          label={t('Common:inputs.directionalPhone')}
          onChange={(e) => setDirectional(e.target.value)}
          error={!!props.error}
        >
          {getDirectionals()}
        </TextField>
        <TextField
          value={phoneNumber}
          className={'phone_number_field_number'}
          label={t('Common:inputs.phoneNumber')}
          onChange={(e) => handlePhoneNumberChange(e)}
          error={!!props.error}
        />
      </Stack>
      <Box
        height={2}
        sx={{ color: appTheme.palette.error.main }}
        className={'phone_number_field_error'}
      >
        {props.error?.message}
      </Box>
    </Stack>
  )
}

export default PhoneNumberField

const formatDirectional = (input: string): string => {
  return `+${input.replace(/0/g, '')}`
}
