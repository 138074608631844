import { StepType, useTour } from '@reactour/tour'
import { t } from 'i18next'
import { Dispatch, SetStateAction, useEffect } from 'react'

import { companiesSteps } from '@/lib/Onboarding/companiesSteps'
import { externalMarketSteps } from '@/lib/Onboarding/externalMarketSteps'
import { fleetCurrentLoadsSteps } from '@/lib/Onboarding/fleetCurrentLoadsSteps'
import { forCarriersMarketSteps } from '@/lib/Onboarding/forCarriersMarketSteps'

export type SokarTours =
  | '/market/external'
  | '/market/for-carriers'
  | '/market/offers-manager'
  | '/market/ongoing'
  | '/market/for-brokers'
  | '/market/transport-needs'
  | '/companies'
  | '/fleet/current-loads'
  | '/fleet/drivers'
  | '/fleet/trucks'
  | '/fleet/trailers'

const SOKAR_ONBOARDING_KEY = 'sokar-onboarding'

export interface ISokarTour {
  setIsOpen: Dispatch<SetStateAction<boolean>>
  isOpen: boolean
  currentStep: number
  setSteps: Dispatch<SetStateAction<StepType[]>> | undefined
  setCurrentStep: Dispatch<SetStateAction<number>>
}

/**
 * The `useSokarTour` function is a custom hook in TypeScript that manages a tour feature based on user
 * triggers and true values.
 * @param {unknown} trigger - The `trigger` parameter in the `useSokarTour` function is used to
 * determine when to open the tour. If the `trigger` value is equal to the `trueValue`, and the current
 * pathname is not included in the onboarding array, the tour will be opened.
 * @param {unknown} trueValue - The `trueValue` parameter in the `useSokarTour` function is used to
 * determine when to trigger the tour. When the `trigger` value matches the `trueValue`, the tour will
 * be opened for the user.
 * @returns The `useSokarTour` function returns an object with four properties: `setIsOpen`,
 * `isOpen, currentStep`, `setSteps` and `setCurrentSteps`.
 */
export const useSokarTour = (
  trigger: unknown,
  trueValue: unknown,
  className: string,
  translationKey: string,
  noSteps: number,
  actionsMap?: Record<number, boolean>,
): ISokarTour => {
  const { setIsOpen, isOpen, currentStep, setSteps, setCurrentStep } = useTour()

  useEffect(() => {
    const { pathname } = window.location
    setSteps?.(generateSteps(noSteps, className, translationKey, actionsMap))
    const onboardingData = localStorage.getItem(SOKAR_ONBOARDING_KEY)

    let onboardingArray: string[] = []
    if (onboardingData) {
      onboardingArray = JSON.parse(onboardingData)
    } else {
      localStorage.setItem(SOKAR_ONBOARDING_KEY, JSON.stringify([]))
    }

    if (!onboardingArray.includes(pathname) && trigger === trueValue) {
      setIsOpen(true)
    }
  }, [setIsOpen, trigger, trueValue, setSteps, noSteps, className, translationKey])

  useEffect(() => {
    if (currentStep === generateSteps(noSteps, className, translationKey, actionsMap).length - 1) {
      completeTour()
    }
  }, [className, currentStep, noSteps, translationKey])

  return { setIsOpen, isOpen, currentStep, setSteps, setCurrentStep }
}

/**
 * @deprecated use `generateSteps()` insted
 *
 * The function `getTour` takes a string input and returns an array of steps based on the input.
 * @param {string} sokarTour - The `sokarTour` parameter in the `getTour` function is a string that
 * represents the current tour or route in the application. The function uses this parameter to
 * determine which set of steps to return based on the route provided.
 * @returns An array of StepType objects is being returned based on the value of the sokarTour
 * parameter in the getTour function. The specific array returned depends on the value of sokarTour:
 */
export const getTour = (sokarTour: string): StepType[] => {
  switch (sokarTour) {
    case '/companies':
      return companiesSteps
    case '/market/external':
      return externalMarketSteps
    case '/market/for-carriers':
      return forCarriersMarketSteps
    case '/fleet/current-loads':
      return fleetCurrentLoadsSteps
    default:
      return [
        {
          selector: '',
          content: '',
        },
      ] as StepType[]
  }
}

/**
 * The `completeTour` function stores tour data in local storage, ensuring each tour is only added
 * once.
 * @param {SokarTours} [tour] - The `tour` parameter in the `completeTour` function is of type
 * `SokarTours`. It is an optional parameter, meaning it can be provided or not when calling the
 * function. If provided, it represents a specific tour that the user has completed. If not provided,
 * the function
 */
export const completeTour = (tour?: SokarTours) => {
  const { pathname } = window.location
  const storage = localStorage.getItem(SOKAR_ONBOARDING_KEY)

  let onboardingData: SokarTours[]
  if (!storage) {
    onboardingData = [tour ?? (pathname as SokarTours)]
  } else {
    onboardingData = JSON.parse(storage)
    if (!onboardingData.includes(tour ?? (pathname as SokarTours))) {
      onboardingData.push(tour ?? (pathname as SokarTours))
    }
  }

  localStorage.setItem(SOKAR_ONBOARDING_KEY, JSON.stringify(onboardingData))
}

/**
 * The function `generateSteps` creates an array of step objects with selectors and content based on
 * the input parameters.
 * @param {number} noSteps - The `noSteps` parameter is the number of steps you want to generate for a
 * guided tour or tutorial.
 * @param {string} className - The `className` parameter is a string that represents the base class
 * name for the steps. Each step will have a unique class name based on this parameter, with a number
 * appended to it (e.g., market-tour-1, market-tour-2, etc.).
 * @param {string} translationKey - The `translationKey` parameter is a string that represents the key
 * used to retrieve the translation for each step content. It is used in conjunction with the step
 * number to form the complete translation key for each step.
 * @returns The `generateSteps` function returns an array of `StepType` objects, where each object
 * contains a `selector` property with a CSS class name based on the `className` and index, and a
 * `content` property with a translation key based on the `translationKey` and index.
 */
export const generateSteps = (
  noSteps: number,
  className: string,
  translationKey: string,
  actionsMap?: Record<number, boolean>,
): StepType[] => {
  const arr = []
  for (let i = 0; i < noSteps; i++) {
    arr.push({
      selector: `.${className}-${i + 1}`,
      content: t(`${translationKey}.step${i + 1}`),
      disableActions: actionsMap ? actionsMap[i] : false,
    } as StepType)
  }
  return arr
}
