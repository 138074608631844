import { useContext } from 'react'
import AlertContext, { IAlertContext } from '../context/AlertProvider'

/**
 * Custom hook to access the alert context
 *
 * @returns {IAlertContext} The server response context including the state and dispatch function
 * @throws {Error} If this hook is used outside of a Alertrovider, an error is thrown
 */
const useAlert = (): IAlertContext => {
  const context = useContext(AlertContext)

  if (!context) {
    throw new Error('useAlert must be used within a AlertProvider')
  }

  return context
}

export default useAlert
