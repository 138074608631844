import { useState, SyntheticEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { Control, FieldArrayWithId } from 'react-hook-form'
import { ITheme } from '@pkg/sokarUI'
import styles from './additionals.module.scss'
import AdditionalsList from './components/AdditionalsList'
import { Additional as InvoiceAdditional, CreateFromTemplate } from '@pkg/documents/requests'

import { Box, Tab, Paper, useTheme } from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'

import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'

interface AdditionalsProps {
  control: Control<CreateFromTemplate>
  services: FieldArrayWithId<CreateFromTemplate, 'invoiceDetails.services', 'id'>[]
  appendAddition: (value: InvoiceAdditional) => void
  removeAddition: () => void
  deductions: FieldArrayWithId<CreateFromTemplate, 'invoiceDetails.deductions', 'id'>[]
  appendDeduction: (value: InvoiceAdditional) => void
  removeDeduction: () => void
}

/* 
    This component is responsible for managing the addiotional transfers for invoices,
    such as: leases, insurance charges, etc. 
*/
const Additionals = ({ ...props }: AdditionalsProps): JSX.Element => {
  const appTheme: ITheme = useTheme()
  const { t } = useTranslation()
  const [selectedTabIndex, setSelectedTabIndex] = useState<string>('0')
  const handleSelectedTabChange = (event: SyntheticEvent, newValue: string) => {
    setSelectedTabIndex(newValue)
  }

  return (
    <Box
      component={Paper}
      className={styles.wrapper}
      sx={{ bgcolor: appTheme.palette.background.default }}
    >
      <TabContext value={selectedTabIndex}>
        <TabList
          onChange={handleSelectedTabChange}
          variant={'fullWidth'}
        >
          <Tab
            icon={<AddIcon />}
            label={t('Accounting:additions')}
            iconPosition={'end'}
            value={'0'}
            className={styles.tab}
          />
          <Tab
            icon={<RemoveIcon />}
            label={t('Accounting:deductions')}
            iconPosition={'end'}
            value={'1'}
            className={styles.tab}
          />
        </TabList>
        <TabPanel value={'0'}>
          <AdditionalsList
            type={'invoiceDetails.services'}
            control={props.control}
            value={props.services}
            append={props.appendAddition}
            remove={props.removeAddition}
          />
        </TabPanel>
        <TabPanel value={'1'}>
          <AdditionalsList
            type={'invoiceDetails.deductions'}
            control={props.control}
            value={props.deductions}
            append={props.appendDeduction}
            remove={props.removeDeduction}
          />
        </TabPanel>
      </TabContext>
    </Box>
  )
}

export default Additionals
