import { Cancel, Verified } from '@mui/icons-material'
import { Box, Dialog, DialogTitle, Skeleton, Typography, useTheme } from '@mui/material'
import { forwardRef, useImperativeHandle, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ICheckCompanyResponse } from '@/pkg/companies/models'
import { ITheme } from '@/pkg/sokarUI'

export interface ICompanyCheckDialog {
  onClose?: () => void
}

export interface ICompanyCheckDialogRef {
  open: () => void
  loadData: (payload: ICheckCompanyResponse) => void
  close: () => void
}

const CompanyCheckDialog = forwardRef<ICompanyCheckDialogRef, ICompanyCheckDialog>(
  ({ onClose }, ref) => {
    const { t } = useTranslation()
    const appTheme: ITheme = useTheme()

    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [data, setData] = useState<ICheckCompanyResponse | undefined>()
    const [isFetching, setIsFetching] = useState<boolean>(true)

    const handleClose = () => {
      onClose?.()
      setIsOpen(false)
    }

    useImperativeHandle(ref, () => ({
      open() {
        setIsOpen(true)
        setIsFetching(true)
      },
      loadData(payload) {
        setData(payload)
        setIsFetching(false)
      },
      close() {
        handleClose()
      },
    }))
    return (
      <Dialog
        onClose={handleClose}
        open={isOpen}
        className='companies dialog-check'
      >
        <DialogTitle>
          <Typography className='companies dialog-check title'>
            {!data?.name || (isFetching && t('Companies:checkDialog.title'))}
            {!isFetching && data?.name && data?.name}
            {!isFetching && !data?.name && t('Companies:checkDialog.noAvalibleName')}
            {!isFetching && data?.valid && (
              <Verified sx={{ color: appTheme.palette.success.main }} />
            )}
            {!isFetching && !data?.valid && <Cancel sx={{ color: appTheme.palette.error.main }} />}
          </Typography>
        </DialogTitle>
        <Box className='companies dialog-check content'>
          {isFetching && (
            <>
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </>
          )}

          {!isFetching && (
            <Box className='companies dialog-check data'>
              <Box className='row'>
                <Typography>{t('Companies:checkDialog.taxId')}: </Typography>
                <Typography>{data?.vatId}</Typography>
              </Box>
              <Box className='row'>
                <Typography>{t('Companies:checkDialog.regon')}: </Typography>
                <Typography>{data?.regon}</Typography>
              </Box>
              <Box className='row'>
                <Typography>{t('Companies:checkDialog.address')}: </Typography>
                <Typography>{data?.address ?? '-'}</Typography>
              </Box>
            </Box>
          )}
        </Box>
      </Dialog>
    )
  },
)

CompanyCheckDialog.displayName = 'CheckCompanyDialog'

export default CompanyCheckDialog
