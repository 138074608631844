import { TourProvider } from '@reactour/tour'
import {
  browserProfilingIntegration,
  init,
  metrics,
  reactRouterV6BrowserTracingIntegration,
  replayIntegration,
} from '@sentry/react'
import React, { useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router'
import { PersistGate } from 'redux-persist/integration/react'
import { AlertProvider } from 'src/context/AlertProvider'

import App from './App'
import { completeTour } from './hooks/useSokarTour'
import { persistor, store } from './store'

process.env.NODE_ENV === 'production' &&
  init({
    dsn: 'https://39a8e46628cba9cf1322247d659c3291@sentry.onitsoft.com/2',
    integrations: [
      metrics.metricsAggregatorIntegration(),
      reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      browserProfilingIntegration(),
      replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    tracesSampleRate: 1.0,

    replaysSessionSampleRate: 0.01,
    replaysOnErrorSampleRate: 1.0,
  })

const root = createRoot(document.getElementById('root')!)

root.render(
  <React.StrictMode>
    <AlertProvider>
      <Provider store={store}>
        <PersistGate
          loading={null}
          persistor={persistor}
        >
          <TourProvider
            steps={[]}
            scrollSmooth
            onClickClose={({ setIsOpen }) => {
              completeTour()
              setIsOpen(false)
            }}
            onClickMask={({ setIsOpen }) => {
              completeTour()
              setIsOpen(false)
            }}
            styles={{ close: (base) => ({ ...base, right: 8, top: 8 }) }}
          >
            <App />
          </TourProvider>
        </PersistGate>
      </Provider>
    </AlertProvider>
  </React.StrictMode>,
)
