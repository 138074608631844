import { useGetLoadQuery, useSearchLoadsQuery } from '@features/loads/loadsApiSlice'
import useRefetchWithAlert from '@hooks/useRefetchWithAlert'
import ClusteredMap from '@lib/ClusteredMap'
import ConfirmationDialog, { IConfirmationDialogRef } from '@lib/ConfirmationDialog'
import ContentList, { IContentListRef } from '@lib/ContentList'
import ContentTable, { ITableRef } from '@lib/ContentTable'
import SideDialog, { ISideDialogRef } from '@lib/SideDialog'
import AddIcon from '@mui/icons-material/Add'
import ReplayIcon from '@mui/icons-material/Replay'
import { Box, IconButton, Skeleton, Tooltip, useMediaQuery, useTheme } from '@mui/material'
import { ILoad } from '@pkg/loads/models'
import { ITheme } from '@pkg/sokarUI'
import { t } from 'i18next'
import { FC, useCallback, useEffect, useRef, useState } from 'react'

import { useSokarTour } from '@/hooks/useSokarTour'

import CreateLoadDialog from './components/CreateLoadDialog'
import CurrentLoadsOptionsCard from './components/CurrentLoadsOptionCard'
import LoadCard from './components/LoadCard'
import LoadDialog from './components/LoadDialog'
import styles from './currentLoads.module.scss'
import {
  currentLoadsCellRenderer as cellRenderer,
  currentLoadsTableColumns as columns,
} from './view.helpers'

const CurrentLoads: FC = () => {
  const appTheme: ITheme = useTheme()
  const isMobile: boolean = useMediaQuery(appTheme.breakpoints.down('md'))
  const [isMapView, setIsMapView] = useState<boolean>(false)
  const [searchPhrase, setSearchPhrase] = useState<string>('')
  const [isSearched, setIsSearched] = useState<boolean>(false)

  const loadDialogRef = useRef<ISideDialogRef>(null)
  const handleLoadDialogClose = useCallback((): void => {
    loadDialogRef.current?.close()
  }, [])
  const handleLoadDialogOpen = useCallback((row: ILoad): void => {
    loadDialogRef.current?.open(row)
  }, [])

  const {
    data: loads,
    isSuccess: isLoadsSuccess,
    refetch: refetchLoads,
  } = useGetLoadQuery(
    {
      published: false,
      archived: false,
      tags: 'carrier-load',
    },
    { skip: isSearched },
  )
  const refresh = useRefetchWithAlert(refetchLoads)

  const { data: searchedLoads, isSuccess: isSearchSuccess } = useSearchLoadsQuery(
    { phrase: searchPhrase, archived: false, published: false, tags: 'carrier-load' },
    {
      skip: !isSearched,
    },
  )

  const { setIsOpen, currentStep } = useSokarTour(
    !!loads,
    true,
    'loads-fleet-tour',
    'Fleet:onboarding.loads',
    10,
  )

  useEffect(() => {
    if (currentStep >= 3 && loads?.length === 0) {
      setIsOpen(false)
    }
    if (currentStep === 3 && loads) {
      loadDialogRef.current?.open(loads[0])
    }
  }, [currentStep, setIsOpen, loads])

  const handleSearch = (phrase: string, _isSearched: boolean): void => {
    setSearchPhrase(phrase)
    setIsSearched(_isSearched)
  }

  const createLoadDialogRef = useRef<ISideDialogRef>(null)
  const handleCreateLoadDialogClose = () => createLoadDialogRef.current?.close()
  const handleCreateLoadDialogOpen = (): void => {
    createLoadDialogRef.current?.open({})
  }

  const freeActions: JSX.Element = (
    <>
      <Tooltip
        title={t('Fleet:currentLoads.add')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={handleCreateLoadDialogOpen}
          className='loads-fleet-tour-3'
        >
          <AddIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('Fleet:currentLoads.refresh')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => refresh()}
        >
          <ReplayIcon />
        </IconButton>
      </Tooltip>
    </>
  )

  const ref = useRef<ITableRef>(null)
  const listRef = useRef<IContentListRef>(null)
  const confirmationDialogRef = useRef<IConfirmationDialogRef>(null)
  useEffect((): void => {
    if (isSearchSuccess && !isMobile) {
      ref.current?.open(searchedLoads, columns)
      return
    }
    if (isSearchSuccess && isMobile) {
      listRef.current?.open(searchedLoads)
      return
    }
    if (isMobile) {
      listRef.current?.open(loads)
      return
    }
    ref.current?.open(loads, columns)
  }, [isMapView, isMobile, isSearchSuccess, loads, searchedLoads])

  const getProperComponent = (): JSX.Element => {
    if (isMobile && !isMapView) {
      return (
        <ContentList
          ref={listRef}
          listComponent={LoadCard}
          openDialog={handleLoadDialogOpen}
        />
      )
    }
    if (isMapView) {
      return (
        <ClusteredMap
          data={loads ?? []}
          isDialogOpen={false}
          handleObjectSelect={handleLoadDialogOpen}
          type={'bookedLoads'}
          display={'deliveryLocation'}
        />
      )
    }
    return (
      <ContentTable
        ref={ref}
        freeActions={freeActions}
        cellRenderer={cellRenderer}
        openDialog={handleLoadDialogOpen}
      />
    )
  }

  if (!isLoadsSuccess && !isSearchSuccess) {
    return (
      <Box className={styles.loader}>
        {Array(12)
          .fill(null)
          .map((_, index) => (
            <Skeleton
              key={index}
              animation={'wave'}
            />
          ))}
      </Box>
    )
  }

  return (
    <>
      <CurrentLoadsOptionsCard
        isMapView={isMapView}
        value={searchPhrase}
        handleSearch={handleSearch}
        handleViewToggle={() => setIsMapView((prev) => !prev)}
      />
      {getProperComponent()}
      <SideDialog
        ref={createLoadDialogRef}
        title={t('Fleet:createLoadDialog.title')}
      >
        <CreateLoadDialog
          refetch={refetchLoads}
          handleClose={handleCreateLoadDialogClose}
        />
      </SideDialog>
      <SideDialog
        ref={loadDialogRef}
        title={t('Fleet:loadDialog.title')}
      >
        <LoadDialog
          refetch={refetchLoads}
          elementRef={loadDialogRef}
          handleClose={handleLoadDialogClose}
          confirmationRef={confirmationDialogRef}
        />
      </SideDialog>
      <ConfirmationDialog ref={confirmationDialogRef} />
    </>
  )
}

export default CurrentLoads
