import { Autocomplete, Box, MenuItem, TextField } from '@mui/material'
import { t } from 'i18next'
import { FC, SyntheticEvent } from 'react'
import { FieldError } from 'react-hook-form'

import CountryFlag from '../CountryFlag'
import { ICountrySelectOption, parseCountriesForFilter, parseCountriesForSelect } from './utils'

interface ICountrySelectProps {
  value: string
  onChange: (e: SyntheticEvent<Element, Event>, option: ICountrySelectOption | null) => void
  className?: string
  error?: FieldError
  disabled?: boolean
}

const CountrySelect: FC<ICountrySelectProps> = ({
  value,
  onChange: handleChange,
  error,
  className,
  disabled,
}) => {
  return (
    <Autocomplete
      disablePortal
      value={parseCountriesForSelect().find((option) => option.value === value) ?? undefined}
      onChange={handleChange}
      options={parseCountriesForSelect()}
      disableClearable={true}
      className={className}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t('Common:country')}
          error={!!error}
          disabled={disabled}
          helperText={<Box height={2}>{error?.message}</Box>}
        />
      )}
      renderOption={(props, option) => {
        return (
          <MenuItem
            {...props}
            key={option.value}
          >
            <CountryFlag countryName={option.value} />({option.label}){' '}
            {t(`Common:countries.${option.value}`)}
          </MenuItem>
        )
      }}
      getOptionLabel={() => t(`Common:countries.${value}`)}
      filterOptions={parseCountriesForFilter}
    />
  )
}

export default CountrySelect
