import { useDeleteDriverMutation, useUpdateDriverMutation } from '@features/drivers/driversApiSlice'
import useAlert from '@hooks/useAlert'
import { IConfirmationDialogRef } from '@lib/ConfirmationDialog'
import { ISideDialogChildProps, ISideDialogRef } from '@lib/SideDialog'
import ArchiveIcon from '@mui/icons-material/Archive'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import EditIcon from '@mui/icons-material/Edit'
import HistoryIcon from '@mui/icons-material/History'
import HomeIcon from '@mui/icons-material/Home'
import LockPersonIcon from '@mui/icons-material/LockPerson'
import PaymentsIcon from '@mui/icons-material/Payments'
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1'
import WorkHistoryIcon from '@mui/icons-material/WorkHistory'
import { Box, Button, IconButton, Skeleton, Tooltip, useTheme } from '@mui/material'
import { ITheme } from '@pkg/sokarUI'
import { parseError } from '@shared/utils/formatters'
import { t } from 'i18next'
import { lazy, LazyExoticComponent, MutableRefObject, Suspense, useEffect, useState } from 'react'

import { createUpdateDriverRequest } from '@/pkg/drivers/converters'

import styles from './driverDialog.module.scss'

interface DriverDialogProps extends ISideDialogChildProps {
  elementRef: MutableRefObject<ISideDialogRef | null>
  confirmationRef: MutableRefObject<IConfirmationDialogRef | null>
  refetch: () => void
  handleClose: () => void
}

type driverDialogElements = 'info' | 'edit' | 'history' | 'loads' | 'permissions'

const DriverDialog: React.FC<DriverDialogProps> = ({ ...props }) => {
  const appTheme: ITheme = useTheme()
  const [deleteDriver] = useDeleteDriverMutation()
  const [updateDriver] = useUpdateDriverMutation()
  const { dispatch: dispatchAlert } = useAlert()
  const [driverId, setDriverId] = useState<string>('')

  const handleDeleteDriver = (id: string) => {
    const handleDelete = (id: string) => {
      deleteDriver(id)
        .unwrap()
        .then(() => {
          dispatchAlert({
            type: 'SHOW',
            payload: {
              content: t('Fleet:responses.driverDeleted'),
              severity: 'success',
            },
          })
        })
        .then(() => props.confirmationRef.current?.close())
        .then(() => props.handleClose())
        .then(() => props.refetch())
        .catch((err: any) => {
          const error = parseError<any>(err.message)
          dispatchAlert({
            type: 'SHOW',
            payload: {
              content: String(t(error.dictKey, { ...error.dependencies })),
              severity: 'error',
            },
          })
          props.confirmationRef.current?.close()
        })
    }

    props.confirmationRef.current?.open({
      title: t('Fleet:confirmationDialog.deleteDriverTitle'),
      text: t('Fleet:confirmationDialog.deleteDriverText'),
      actions: [
        <Button onClick={() => props.confirmationRef.current?.close()}>{t('Common:no')}</Button>,
        <Button onClick={() => handleDelete(id)}>{t('Common:yes')}</Button>,
      ],
    })
  }

  const handleArchiveDriver = () => {
    const handleArchive = () => {
      if (!props.data) {
        return
      }
      const driver = createUpdateDriverRequest(props.data)
      driver.archived = true
      updateDriver(driver)
        .unwrap()
        .then(() => {
          dispatchAlert({
            type: 'SHOW',
            payload: {
              content: t('Fleet:responses.driverUpdated'),
              severity: 'success',
            },
          })
        })
        .then(() => props.confirmationRef.current?.close())
        .then(() => props.handleClose())
        .then(() => props.refetch())
        .catch((err) => {
          const error = parseError<any>(err.message)
          dispatchAlert({
            type: 'SHOW',
            payload: {
              content: String(t(error.dictKey, { ...error.dependencies })),
              severity: 'error',
            },
          })
          props.confirmationRef.current?.close()
        })
    }

    props.confirmationRef.current?.open({
      title: t('Fleet:confirmationDialog.archiveDriverTitle'),
      text: t('Fleet:confirmationDialog.archiveDriverText'),
      actions: [
        <Button onClick={() => props.confirmationRef.current?.close()}>{t('Common:no')}</Button>,
        <Button onClick={() => handleArchive()}>{t('Common:yes')}</Button>,
      ],
    })
  }

  const dialogActions = (
    <>
      <Tooltip
        title={t('Fleet:driverDialog.info')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => setElementKey('info')}
          className='drivers-fleet-tour-4'
        >
          <HomeIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('Fleet:driverDialog.edit')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => setElementKey('edit')}
          className='drivers-fleet-tour-5'
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('Fleet:driverDialog.history')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => setElementKey('history')}
          className='drivers-fleet-tour-6'
        >
          <HistoryIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('Fleet:driverDialog.loads')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => setElementKey('loads')}
          className='drivers-fleet-tour-7'
        >
          <WorkHistoryIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('Fleet:driverDialog.paymentHistory')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          disabled
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => setElementKey('loads')}
        >
          <PaymentsIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('Fleet:driverDialog.makeUser')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          disabled
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => setElementKey('info')}
        >
          <PersonAddAlt1Icon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('Fleet:driverDialog.permissions')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          disabled
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => setElementKey('permissions')}
        >
          <LockPersonIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('Fleet:driverDialog.delete')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => handleDeleteDriver(driverId)}
          className='drivers-fleet-tour-8'
        >
          <DeleteForeverIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('Fleet:driverDialog.archive')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => handleArchiveDriver()}
          className='drivers-fleet-tour-9'
        >
          <ArchiveIcon />
        </IconButton>
      </Tooltip>
    </>
  )

  const [elementKey, setElementKey] = useState<driverDialogElements>('info')
  const getProperElement = (
    key: driverDialogElements,
  ): LazyExoticComponent<React.ComponentType<any>> => {
    switch (key) {
      case 'edit':
        return lazy(() => import('./edit'))
      case 'loads':
        return lazy(() => import('./loads'))
      case 'history':
        return lazy(() =>
          import('@lib/EventsHistory').then((module) => ({
            default: (props: any) => (
              <module.default
                domain={'driver'}
                objectId={props.data.id}
              />
            ),
          })),
        )
      default:
        return lazy(() => import('./info'))
    }
  }

  useEffect(() => {
    setElementKey('info')
    if (props.data) setDriverId(props.data.id)
  }, [props.data])

  useEffect(() => {
    props.elementRef.current?.setActions(dialogActions)
  }, [driverId])

  const ProperComponent = getProperElement(elementKey)

  return (
    <Suspense
      fallback={
        <Box className={styles.suspense}>
          <Skeleton animation={'wave'} />
        </Box>
      }
    >
      <ProperComponent data={props.data} />
    </Suspense>
  )
}

export default DriverDialog
