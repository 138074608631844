import { SetStateAction, useEffect, useState, Dispatch, ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import OptionsCard from '@lib/OptionsCard'
import styles from './optionCard.module.scss'

import { Button, TextField, MenuItem } from '@mui/material'
import { fleetObject } from '@pkg/sokarUI'

import SearchIcon from '@mui/icons-material/Search'
import SearchOffIcon from '@mui/icons-material/SearchOff'

interface IArchiveOptionsCardProps {
  value: string
  archiveKey: fleetObject
  setArchiveKey: Dispatch<SetStateAction<fleetObject>>
  handleSearch: (phrase: string, isSearched: boolean) => void
}

const CurrentLoadsOptionsCard = ({ ...props }: IArchiveOptionsCardProps): JSX.Element => {
  const { t } = useTranslation()
  const [phrase, setPhrase] = useState<string>('')

  const handleClear = () => {
    setPhrase('')
    props.handleSearch('', false)
  }

  const handleArchiveSelection = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void => {
    handleClear()
    props.setArchiveKey(event.target.value as fleetObject)
  }

  useEffect(() => {
    if (props.value) setPhrase(props.value)
  }, [props.value])

  return (
    <OptionsCard>
      <TextField
        select
        value={props.archiveKey}
        className={styles.objectSelect}
        onChange={handleArchiveSelection}
      >
        <MenuItem value={'load'}>{t('Fleet:load')}</MenuItem>
        <MenuItem value={'driver'}>{t('Fleet:driver')}</MenuItem>
        <MenuItem value={'truck'}>{t('Fleet:truck')}</MenuItem>
        <MenuItem value={'trailer'}>{t('Fleet:trailer')}</MenuItem>
      </TextField>
      <TextField
        value={phrase}
        disabled={!props.archiveKey}
        className={styles.optionCardSearch}
        label={t('Fleet:currentLoads.searchTitle')}
        onChange={(e) => setPhrase(e.target.value)}
      />
      <Button
        variant={'contained'}
        onClick={() => props.handleSearch(phrase, true)}
        disabled={!phrase}
      >
        <SearchIcon />
      </Button>
      <Button
        variant={'contained'}
        onClick={() => handleClear()}
        disabled={!phrase}
      >
        <SearchOffIcon />
      </Button>
    </OptionsCard>
  )
}

export default CurrentLoadsOptionsCard
