import { useTranslation } from 'react-i18next'
import { IDriver } from '@pkg/drivers/models'
import { useTheme } from '@emotion/react'
import './style.scss'

import { Box, Stack, Paper, Divider, Typography } from '@mui/material'

import PersonIcon from '@mui/icons-material/Person'
import PhoneIcon from '@mui/icons-material/Phone'
import EmailIcon from '@mui/icons-material/Email'

interface IDriverCardProps {
  data: IDriver
  openDialog: (row: any) => void
}

const DriverCard = ({ ...props }: IDriverCardProps): JSX.Element => {
  const { t } = useTranslation()
  const appTheme: any = useTheme()

  return (
    <Paper
      className={'driver_card_wrapper'}
      onClick={() => props.openDialog(props.data)}
      sx={{ backgroundColor: appTheme.palette.background.default }}
    >
      <Stack direction={'column'} spacing={2}>
        <Typography>
          {t(`Fleet:driverCard.${props.data.isOwner ? 'contractor' : 'driver'}`)}
        </Typography>
        <Divider />
        <Box className={'driver_card_text'}>
          <PersonIcon />
          {props.data.firstName} {props.data.lastName}
        </Box>
        <Box className={'driver_card_text'}>
          <PhoneIcon />
          {props.data.phone}
        </Box>
        <Box className={'driver_card_text'}>
          <EmailIcon />
          {props.data.email}
        </Box>
      </Stack>
    </Paper>
  )
}

export default DriverCard
