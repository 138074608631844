import dayjs from 'dayjs'
import { t } from 'i18next'

const country: string | null = localStorage.getItem('country')

export const usMyMarketOffersStopDefaults = (): Partial<any> => {
  const today = dayjs().startOf('day')

  return {
    city: '',
    date: today,
    dateTo: today,
    time: today.hour(7).minute(0),
    timeTo: today.hour(15).minute(0),
    countryAbbreviation: country?.toUpperCase() ?? 'US',
    country: t(`Common:countries.${country?.toUpperCase()}` ?? 'US'),
  }
}

export const usMyMarketOffersDefaults = (): Partial<any> => {
  const today = dayjs().startOf('day')
  const tomorrow = dayjs().startOf('day').add(1, 'day')

  return {
    type: 'FTL',
    publishedPriceCurrency: 'USD',
    weightUnit: 'lbs',
    paymentDays: 49,
    vehicleSize: '53ft',
    stops: [
      {
        city: '',
        category: 'P',
        date: today,
        dateTo: today,
        time: today.hour(7).minute(0),
        timeTo: today.hour(15).minute(0),
        countryAbbreviation: country?.toUpperCase() ?? 'US',
        country: t(`Common:countries.${country?.toUpperCase()}` ?? 'US'),
      },
      {
        city: '',
        category: 'D',
        date: tomorrow,
        dateTo: tomorrow,
        time: today.hour(7).minute(0),
        timeTo: today.hour(15).minute(0),
        countryAbbreviation: country?.toUpperCase() ?? 'US',
        country: t(`Common:countries.${country?.toUpperCase()}` ?? 'US'),
      },
    ],
  }
}

export const usMyMarketOfferCarrierAssignmentDefaults = (): Partial<any> => {
  return {
    shipperPriceCurrency: 'USD',
    carrierPriceCurrency: 'USD',
    statusName: 'ho-pu',
  }
}
