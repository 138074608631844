import './style.scss'

import CloseIcon from '@mui/icons-material/Close'
import { Box, Button, IconButton, Tooltip, Typography, useTheme } from '@mui/material'
import { ITheme } from '@pkg/sokarUI'
// eslint-disable-next-line import/named
import { DefaultTFuncReturn } from 'i18next'
import { t } from 'i18next'
import {
  cloneElement,
  forwardRef,
  Fragment,
  isValidElement,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'

import ConfirmationDialog, { IConfirmationDialogRef } from '../ConfirmationDialog'

interface SideDialogProps {
  title: string | JSX.Element | DefaultTFuncReturn
  isOpen?: boolean
  children: React.ReactNode
  actions?: JSX.Element
  handleClose?: React.Dispatch<React.SetStateAction<boolean>>
  dialogActions?: JSX.Element
  className?: string
}

export interface ISideDialogRef {
  open: (data: any) => void
  setActions: (actions: any) => void
  close: () => void
  enableGuard: () => void
  disableGuard: () => void
}

export interface ISideDialogChildProps {
  data?: any
  isOpen?: boolean
}

const SideDialog = forwardRef<ISideDialogRef | undefined, SideDialogProps>(
  ({ children, ...props }, ref) => {
    const appTheme: ITheme = useTheme()
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [data, setData] = useState<any>(undefined)
    const [dialogActions, setDialogActions] = useState<JSX.Element[]>([])
    const [isGuardEnabled, setIsGuardEnabled] = useState<boolean>(false)
    useEffect(() => {
      if (props.dialogActions) setDialogActions([props.dialogActions])
    }, [props.dialogActions])

    const close = (): void => {
      setIsOpen(false)
      if (data) {
        setData(undefined)
      }
    }

    useImperativeHandle(ref, () => ({
      open(_data: any) {
        if (!_data) return
        setData(_data)
        setIsOpen(true)
      },
      setActions(_actions: any) {
        if (!_actions) return
        setDialogActions(_actions)
      },
      close() {
        close()
      },
      enableGuard() {
        setIsGuardEnabled(true)
      },
      disableGuard() {
        setIsGuardEnabled(false)
      },
    }))

    const PropsInjectedChildren = () => {
      children && Array.isArray(children)
        ? children.map((child: any) => {
            if (isValidElement(child)) {
              return cloneElement(child as any, { isOpen, data })
            }
          })
        : isValidElement(children)
      return cloneElement(children as any, { isOpen, data })
    }

    const confirmationDialogRef = useRef<IConfirmationDialogRef>(null)
    const handleClose = (): void => {
      if (isGuardEnabled) {
        confirmationDialogRef.current?.open({
          title: t('Market:offerDialog.showInterestTitle'),
          text: <p className={''}>{t('Market:offerDialog.showInterestText')}</p>,
          actions: [
            <Button onClick={() => confirmationDialogRef.current?.close()}>
              {t('Common:no')}
            </Button>,
            <Button onClick={close}>{t('Common:yes')}</Button>,
          ],
        })
      }
      close()
    }

    return (
      <Fragment>
        <Box
          className={`${isOpen ? 'side_dialog_display' : 'side_dialog_donotdisplay'} ${props.className}`}
          sx={{ backgroundColor: appTheme.palette.background.default }}
        >
          <Box
            className={'side_dialog_actions_column'}
            sx={{
              background:
                'linear-gradient(0deg, rgba(89, 131, 252, 1) 0%, rgba(0, 165, 224, 1) 100%)',
            }}
          >
            <Tooltip
              title={t('Common:close')}
              placement={'left'}
              arrow={true}
            >
              <IconButton onClick={handleClose}>
                <CloseIcon sx={{ color: appTheme.palette.text.light }} />
              </IconButton>
            </Tooltip>
            {dialogActions}
          </Box>
          <Box className={'side_dialog_content'}>
            <Box className={'side_dialog_title_bar'}>
              <Typography variant={'h1'}>{props.title}</Typography>
            </Box>
            <Box className={'side_dialog_contents'}>{isOpen && PropsInjectedChildren()}</Box>
          </Box>
        </Box>
        <ConfirmationDialog ref={confirmationDialogRef} />
      </Fragment>
    )
  },
)

SideDialog.displayName = 'SideDialog'

export default SideDialog
