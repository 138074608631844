import { useState, forwardRef, useImperativeHandle } from 'react'
import { Virtuoso } from 'react-virtuoso'
import './style.scss'

import { List, ListItem } from '@mui/material'

interface IContentListProps {
  listComponent: React.FC<any>
  openDialog?: (row: any) => void
  style?: object
  handleEndReached?: () => void
}

export interface IContentListRef {
  open: (_dataSet: any) => void
}

const ContentList = forwardRef<IContentListRef, IContentListProps>((props, ref) => {
  const [dataSet, setDataSet] = useState<any>([])

  useImperativeHandle(ref, () => ({
    open(_dataSet: any | null) {
      if (!_dataSet) return
      setDataSet(_dataSet)
    },
  }))

  return (
    <List
      component={'div'}
      className={'content_list_wrapper'}
      sx={props.style}
    >
      <Virtuoso
        className={'content_list_virtuoso'}
        data={dataSet}
        itemContent={(index, listItem) => {
          const Component = props.listComponent
          return (
            <ListItem key={index}>
              <Component
                data={listItem}
                openDialog={!!props.openDialog ? props.openDialog : () => {}}
              />
            </ListItem>
          )
        }}
        endReached={props.handleEndReached}
      />
    </List>
  )
})

ContentList.displayName = 'ContentList'

export default ContentList
