import { Route, Routes } from 'react-router-dom'
import RequireAuth from '@features/auth/RequireAuth'

import Documents from './views/Documents'
import Models from './views/Models'

const Archive = () => {
  return (
    <Routes>
      <Route element={<RequireAuth />}>
        <Route
          path={'/models'}
          element={<Models />}
        />
        <Route
          path={'/documents'}
          element={<Documents />}
        />
      </Route>
    </Routes>
  )
}

export default Archive
