import { IDriver } from './models'
import { IDriverUpdateRequest } from './requests'

export const createUpdateDriverRequest = (driver: IDriver): IDriverUpdateRequest => {
  const updateTruckRequest: IDriverUpdateRequest = {
    id: driver.id,
    companyId: driver.companyId,
    carrierId: driver.carrierId,
    number: driver.number,
    archived: driver.archived,
    firstName: driver.firstName,
    lastName: driver.lastName,
    phone: driver.phone,
    email: driver.email,
    socialSecurity: driver.socialSecurity,
    isOwner: driver.isOwner,
    compensationType: driver.compensationType,
    orderIncomePercentage: driver.orderIncomePercentage,
    payPerKilometer: driver.payPerKilometer,
    permissions: driver.permissions,
    username: driver.username,
  }

  return updateTruckRequest
}
