import { apiSlice } from '@api/apiSlice'
import { ICity, ILoad } from '@pkg/loads/models'
import { ICarrierLoad } from '@pkg/loads/models'
import { IUpdateLoadRequest } from '@/pkg/loads/requests'
import { ISearchMarketOffersRequest } from '@pkg/market/requests'

interface IGetLoadParameters extends ISearchMarketOffersRequest {
  published: boolean
  id?: string
  archived?: boolean
  driverId?: string
  ownerId?: string
  tags?: string
}

export const loadsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLoad: builder.query<ILoad[], IGetLoadParameters>({
      query: ({ published, id, archived, driverId, ownerId, tags }) => ({
        url: '/loads',
        params: { published, id, archived, driverId, ownerId, tags },
      }),
      transformResponse: (response: { loads: ILoad[] }) => {
        return response.loads.slice()
      },
    }),
    createLoad: builder.mutation<ILoad, any>({
      query: (load) => ({
        url: '/loads',
        method: 'POST',
        body: load,
      }),
    }),
    updateLoad: builder.mutation<ILoad, IUpdateLoadRequest>({
      query: (load) => ({
        url: '/loads',
        method: 'PUT',
        body: load,
      }),
    }),
    searchLoads: builder.query<
      ILoad[],
      {
        phrase: string
        companyId?: string
        archived?: boolean
        published?: boolean
        ownerId?: string
        tags?: string
      }
    >({
      query: ({ phrase, companyId, archived, published, ownerId, tags }) => ({
        url: `/loads/search`,
        params: { phrase, companyId, archived, published, ownerId, tags },
      }),
      transformResponse: (response: { loads: ILoad[] }) => {
        return response.loads.slice()
      },
    }),
    deleteLoad: builder.mutation<{ id: string }, string>({
      query: (id) => ({
        url: `/loads/${id}`,
        method: 'DELETE',
      }),
    }),
    searchCities: builder.query<
      ICity[],
      { searchPhrase: string; countryCode: string; limit: number }
    >({
      query: ({ searchPhrase: phrase, countryCode: countryId, limit }) => ({
        url: `/loads/city/search`,
        params: { phrase, countryId, limit },
      }),
      transformResponse: (response: { cities: ICity[] }) => response.cities,
    }),
    getCarrierLoad: builder.query<ICarrierLoad[], IGetLoadParameters>({
      query: (params) => ({
        url: '/loads/carrier',
        params: { ...params },
      }),
      transformResponse: (response: { loads: ICarrierLoad[] }) => {
        return response.loads.slice()
      },
    }),
    searchCarrierLoads: builder.query<ICarrierLoad[], IGetLoadParameters>({
      query: (params) => ({
        url: `/loads/carrier/search`,
        params: { ...params },
      }),
      transformResponse: (response: { loads: ICarrierLoad[] }) => {
        return response.loads.slice()
      },
    }),
    sendInterest: builder.mutation<void, string>({
      query: (loadId) => ({
        url: '/loads/send-interest',
        method: 'POST',
        body: { loadId },
      }),
    }),
    getSimilarArchivedLoads: builder.query<ILoad[], string>({
      query: (loadId) => ({
        url: '/loads/similar-archived-loads',
        params: { loadId },
      }),
      transformResponse: (response: { loads: ILoad[] }) => {
        return response.loads.slice()
      },
    }),
    changeStatus: builder.mutation<ILoad, { loadId: string; statusName: string }>({
      query: (payload) => ({
        url: '/loads/status',
        method: 'POST',
        body: payload,
      }),
      transformResponse: (respone: { load: ILoad }) => {
        return respone.load
      },
    }),
    batchDeleteLoads: builder.mutation<string[], { ids: string[] }>({
      query: (payload) => ({
        url: '/loads/batch-delete',
        method: 'POST',
        body: payload,
      }),
      transformResponse: (response: { ids: string[] }) => {
        return response.ids
      },
    }),
    batchArchiveLoads: builder.mutation<string[], { ids: string[] }>({
      query: (payload) => ({
        url: '/loads/batch-archivbe',
        method: 'POST',
        body: payload,
      }),
      transformResponse: (response: { ids: string[] }) => {
        return response.ids
      },
    }),
  }),
})

export const {
  useGetLoadQuery,
  useCreateLoadMutation,
  useUpdateLoadMutation,
  useSearchLoadsQuery,
  useDeleteLoadMutation,
  useSearchCitiesQuery,
  useGetCarrierLoadQuery,
  useSearchCarrierLoadsQuery,
  useSendInterestMutation,
  useGetSimilarArchivedLoadsQuery,
  useChangeStatusMutation,
  useBatchDeleteLoadsMutation,
  useBatchArchiveLoadsMutation,
} = loadsApiSlice
