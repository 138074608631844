import { ICreateTrailerRequest } from './requests'

export const initICreateTrailerRequest = (): ICreateTrailerRequest => {
  return {
    plate: '',
    companyId: '',
    category: '',
    vin: '',
    make: '',
    model: '',
    year: '',
    number: '',
    isCarrierOwned: false,
    archived: false,
  }
}
