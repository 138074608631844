import { FC, Fragment, useState, useRef, useEffect } from 'react'
import { t } from 'i18next'
import styles from './documents.module.scss'
import OptionsCard from '@lib/OptionsCard'
import ContentTable, { ITableRef } from '@lib/ContentTable'
import {
  useGetDocumentCategoriesQuery,
  useGetDocumentContentMutation,
  useGetDocumentsQuery,
} from '@features/documents/documentsApiSlice'
import useAlert from '@hooks/useAlert'
import { parseError } from '@shared/utils/formatters'

import {
  DocumentsArchiveColumns as columns,
  DocumentsArchiveCellRenderer as cellRenderer,
} from './view.helpers'

import { Box, Skeleton, TextField, MenuItem } from '@mui/material'
import { IDocument } from '@/pkg/documents/models'

const DocumentsArchive: FC = () => {
  const [downloadDocument] = useGetDocumentContentMutation()
  const [category, setCategory] = useState<string>('invoice')
  const { dispatch: dispatchAlert } = useAlert()

  const { data: documents, isFetching: isDocumentsFetching } = useGetDocumentsQuery({
    categories: category,
  })

  const { data: categories } = useGetDocumentCategoriesQuery()

  const tableRef = useRef<ITableRef>(null)
  useEffect((): void => {
    if (documents) {
      tableRef.current?.open(documents, columns)
    }
  }, [documents])

  const handleDocumentDownload = (doc: IDocument): void => {
    downloadDocument(doc.id)
      .unwrap()
      .then((res) => {
        // If anyone has better idea then enlighten me
        const fileType = doc.name.split('.')[1]
        fetch(
          `data:${fileType === 'pdf' ? 'application' : 'image'}/${fileType};base64,` + res.content,
        )
          .then((res) => res.blob())
          .then((file) => {
            const url = URL.createObjectURL(file)
            const a = document.createElement('a')
            a.href = url
            a.download = doc.name
            a.style.display = 'none'

            document.body.appendChild(a)
            a.click()

            document.body.removeChild(a)
            URL.revokeObjectURL(url)
          })
      })
      .catch((err) => {
        const error = parseError<any>(err)
        dispatchAlert({
          type: 'SHOW',
          payload: {
            content: String(t(error.dictKey, { ...error.dependencies })),
            severity: 'error',
          },
        })
      })
  }

  const getContent = () => {
    if (isDocumentsFetching || !documents) {
      return (
        <Box className={styles.loader}>
          {Array(12)
            .fill(null)
            .map((_, index) => (
              <Skeleton
                key={index}
                animation={'wave'}
              />
            ))}
        </Box>
      )
    }
    return (
      <ContentTable
        ref={tableRef}
        cellRenderer={(column, row, _appTheme) => {
          return cellRenderer(column, row, handleDocumentDownload)
        }}
      />
    )
  }

  return (
    <Fragment>
      <OptionsCard>
        <TextField
          select
          value={category}
          className={styles.searchField}
          onChange={(e) => setCategory(e.target.value)}
        >
          {categories?.map((cat) => (
            <MenuItem value={cat}>{t(`Document:categories.${cat}`)}</MenuItem>
          ))}
        </TextField>
      </OptionsCard>
      {getContent()}
    </Fragment>
  )
}

export default DocumentsArchive
