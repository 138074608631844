import { assert } from '@shared/utils/utilities'

import { ICreateMarketOffer } from '../models'
import { ICreateMarketOfferRequest } from '../requests'
import { parseEuCreateMyMarketOffer } from './euParsers'
import { parseUsCreateMyMarketOffer } from './usParsers'

/**
 * Returns a function to parse a market offer request based on the region
 *
 * This function checks the region and asserts that it is not an empty string
 * It parses the request using region-specific parsers
 *
 * @param {string} region - The region code, e.g., 'EU' or 'US'. Cannot be an empty string
 * @param {ICreateMarketOffer} request - The market offer request object that needs to be parsed
 * @returns {ICreateMarketOfferRequest} - The parsed market offer request tailored for the specified region
 *
 * @throws {Error} Will throw an error if the region is an empty string
 */
export const parseCreateMyMarketOffer = (
  region: string,
  request: ICreateMarketOffer,
): ICreateMarketOfferRequest => {
  assert(region !== '', 'Region cannot be an empty string')
  if (region === 'EU') {
    return parseEuCreateMyMarketOffer(request)
  }

  return parseUsCreateMyMarketOffer(request)
}
