import { IDriverCreateRequest } from './requests'

export const initICreateDriver = (): IDriverCreateRequest => {
  return {
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    socialSecurity: '',
    compensationType: '',
    companyId: '',
    isOwner: false,
    number: '',
    permissions: [],
  }
}
