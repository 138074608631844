import { useRef, useEffect, MutableRefObject } from 'react'
import { useTheme } from '@emotion/react'
import useOwnUser from 'src/hooks/useOwnUser'
import { t } from 'i18next'

import { IConfirmationDialogRef } from '@lib/ConfirmationDialog'
import { ITrailer } from 'src/pkg/equipment/models'
import { ITableColumn } from 'src/pkg/sokarUI'
import ContentList, { IContentListRef } from '@lib/ContentList'
import TrailerCard from '@app/fleet/views/TrailersView/components/trailerCard'
import ContentTable, { ITableRef } from 'src/lib/ContentTable'
import TrailerDialog from './trailerDialog'
import SideDialog from '@lib/SideDialog'
import UsedByCell from '@app/fleet/views/TrailersView/components/usedByCell'

import { Chip, Typography, useMediaQuery } from '@mui/material'

const columns: ITableColumn[] = [
  {
    label: t('Fleet:trailersTable.trailerNumber'),
    dataKey: 'number',
    isSortable: false,
  },
  {
    label: t('Fleet:trailersTable.plates'),
    dataKey: 'plate',
    isSortable: true,
  },
  {
    label: t('Fleet:trailersTable.ownership'),
    dataKey: 'ownership',
    isSortable: false,
  },
  {
    label: t('Fleet:trailersTable.type'),
    dataKey: 'category',
    isSortable: true,
  },
  {
    label: t('Fleet:trailersTable.usedBy'),
    dataKey: 'usedBy',
    isSortable: false,
  },
]

interface TrailerArchiveProps {
  data: ITrailer[]
  refetch: () => void
  confirmationDialogRef: MutableRefObject<IConfirmationDialogRef | null>
}

const TrailersArchive: React.FC<TrailerArchiveProps> = ({ ...props }) => {
  const appTheme: any = useTheme()
  const isMobile: boolean = useMediaQuery(appTheme.breakpoints.down('md'))

  const trailerDialogRef = useRef<any>(null)
  const handleTrailerDialogOpen = (row: ITrailer): void => {
    trailerDialogRef.current?.open(row)
  }
  const handleTrailerDialogClose = (): void => trailerDialogRef.current?.close()

  const { companyId } = useOwnUser()

  const cellRenderer = (column: ITableColumn, row: ITrailer): JSX.Element => {
    if (column.dataKey === 'category')
      return (
        <Chip
          color={'primary'}
          label={row['category']}
        />
      )
    if (column.dataKey === 'ownership')
      return (
        <Typography>
          {row.isCarrierOwned
            ? t('Fleet:trailersTable.contractorsProperty')
            : t('Fleet:trailersTable.companysProperty')}
        </Typography>
      )
    if (column.dataKey === 'usedBy') {
      return <UsedByCell driverId={row.usedBy!} />
    }

    // prettier-ignore
    return (
        <Typography>
            {row[column.dataKey as keyof ITrailer] as string}
        </Typography>
    )
  }

  const ref = useRef<ITableRef>(null)
  const listRef = useRef<IContentListRef>(null)
  useEffect(() => {
    if (isMobile) listRef.current?.open(props.data)
    else ref.current?.open(props.data, columns)
  }, [isMobile, props.data, columns])

  return (
    <>
      {isMobile ? (
        <ContentList
          ref={listRef}
          openDialog={handleTrailerDialogOpen}
          listComponent={TrailerCard}
        />
      ) : (
        <ContentTable
          ref={ref}
          cellRenderer={cellRenderer}
          openDialog={handleTrailerDialogOpen}
        />
      )}
      <SideDialog
        ref={trailerDialogRef}
        title={t('Fleet:trailerDialog.title')}
      >
        <TrailerDialog
          refetch={props.refetch}
          handleClose={handleTrailerDialogClose}
          elementRef={trailerDialogRef}
          confirmationRef={props.confirmationDialogRef}
        />
      </SideDialog>
    </>
  )
}

export default TrailersArchive
