/* eslint-disable css-modules/no-unused-class */
import {
  useBatchDeleteLoadsMutation,
  useGetLoadQuery,
  useSearchLoadsQuery,
} from '@features/loads/loadsApiSlice'
import useAlert from '@hooks/useAlert'
import useOwnUser from '@hooks/useOwnUser'
import useRefetchWithAlert from '@hooks/useRefetchWithAlert'
import ConfirmationDialog, { IConfirmationDialogRef } from '@lib/ConfirmationDialog'
import ContentList, { IContentListRef } from '@lib/ContentList'
import ContentTable, { ITableRef } from '@lib/ContentTable'
import SideDialog, { ISideDialogRef } from '@lib/SideDialog'
import { Add as AddIcon, Delete as DeleteIcon, Replay as ReplayIcon } from '@mui/icons-material'
import { Box, Button, IconButton, Skeleton, Tooltip, useMediaQuery, useTheme } from '@mui/material'
import { ILoad } from '@pkg/loads/models'
import { ITheme } from '@pkg/sokarUI'
import { parseError } from '@shared/utils/formatters'
import { t } from 'i18next'
import { useCallback, useEffect, useRef, useState } from 'react'

import { useSokarTour } from '@/hooks/useSokarTour'

import CreateOfferDialog from './components/CreateOfferDialog'
import OfferCard from './components/offerCard'
import OfferDialog from './components/offerDialog'
import OffersMarketOptionCard from './components/offersMarketOptionsCard'
import styles from './offersManager.module.scss'
import tourData from './tour_data.json'
import {
  OffersManagerCellRenderer as cellRenderer,
  OffersManagerTableColumns as columns,
} from './view.helpers'

const OffersManager: React.FC = () => {
  const appTheme: ITheme = useTheme()
  const isMobile: boolean = useMediaQuery(appTheme.breakpoints.down('md'))
  const { dispatch: dispatchAlert } = useAlert()

  const ref = useRef<ITableRef>(null)
  const offerDialogRef = useRef<ISideDialogRef>(null)
  const handleOfferDialogClose = useCallback((): void => {
    offerDialogRef.current?.close()
  }, [])

  const createOfferDialogRef = useRef<ISideDialogRef>(null)
  const handleCreateOfferDialogOpen = useCallback((): void => {
    createOfferDialogRef.current?.open({})
  }, [])
  const handleCreateOfferDialogClose = useCallback((): void => {
    createOfferDialogRef.current?.close()
  }, [])

  const [batchDeleteLoads] = useBatchDeleteLoadsMutation()
  const handleBatchDelete = () => {
    const loadsIdsToDelete = ref.current?.getSelection()

    if (!loadsIdsToDelete || loadsIdsToDelete.length === 0) {
      dispatchAlert({
        type: 'SHOW',
        payload: {
          content: t('Market:offerDialog.pleaseSelectOffers'),
          severity: 'info',
        },
      })
      return
    }

    const handleDelete = (): void => {
      batchDeleteLoads({ ids: loadsIdsToDelete })
        .unwrap()
        .then(() => {
          dispatchAlert({
            type: 'SHOW',
            payload: {
              content: t('Market:offerDialog.offersDeleted'),
              severity: 'success',
            },
          })
        })
        .then(() => ref.current?.clearSelection())
        .then(() => refetchOffers())
        .then(() => confirmationDialogRef.current?.close())
        .catch((err: any) => {
          const error = parseError<any>(err.data.message)
          dispatchAlert({
            type: 'SHOW',
            payload: {
              content: String(t(error.dictKey, { ...error.dependencies })),
              severity: 'error',
            },
          })
          confirmationDialogRef.current?.close()
        })
    }

    confirmationDialogRef.current?.open({
      title: t('Market:offerDialog.batchDeleteOfferTitle'),
      text: t('Market:offerDialog.batchDeleteOfferText'),
      actions: [
        <Button onClick={() => confirmationDialogRef.current?.close()}>{t('Common:no')}</Button>,
        <Button onClick={handleDelete}>{t('Common:yes')}</Button>,
      ],
    })
  }

  const freeActions: JSX.Element = (
    <>
      <Tooltip
        title={t('Market:publishedTable.add')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={handleCreateOfferDialogOpen}
        >
          <AddIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('Market:publishedTable.delete')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={handleBatchDelete}
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('Market:publishedTable.refresh')}
        placement={'left'}
        arrow={true}
      >
        <IconButton
          sx={{ color: appTheme.palette.text.light }}
          onClick={() => refresh()}
        >
          <ReplayIcon />
        </IconButton>
      </Tooltip>
    </>
  )

  const owner = useOwnUser()
  const [isSearched, setIsSearched] = useState<boolean>(false)
  const [searchPhrase, setSearchPhrase] = useState<string>('')
  const handleSearchOffers = (phrase: string, _isSearched: boolean) => {
    setIsSearched(_isSearched)
    setSearchPhrase(phrase)
  }

  const { data: searchedOffers, isSuccess: isSearchSuccess } = useSearchLoadsQuery(
    { phrase: searchPhrase, published: true, archived: false, ownerId: owner.id },
    { skip: !isSearched },
  )

  const {
    data: loadOffers,
    isSuccess: isOffersSuccess,
    refetch: refetchOffers,
  } = useGetLoadQuery({ published: true, ownerId: owner.id, tags: 'broker-load' })
  const refresh = useRefetchWithAlert(refetchOffers)

  const { isOpen: isTourOpen, setCurrentStep } = useSokarTour(
    !!loadOffers,
    true,
    'offersmanager-market-tour',
    'Market:onboarding.offersManager',
    10,
    {
      3: true,
      4: false,
    },
  )

  const handleOfferDialogOpen = useCallback(
    (row: ILoad): void => {
      if (isTourOpen) {
        setTimeout((): void => {
          setCurrentStep(4)
        }, 350)
      }
      offerDialogRef.current?.open(row)
    },
    [isTourOpen],
  )

  useEffect(() => {
    if (isOffersSuccess && !isSearched) {
      ref.current?.open(loadOffers, columns)
      listRef.current?.open(loadOffers)
    }
  }, [isOffersSuccess, isSearched, loadOffers])

  useEffect(() => {
    if (isSearchSuccess && isSearched) {
      ref.current?.open(searchedOffers, columns)
      listRef.current?.open(searchedOffers)
    }
  }, [isSearchSuccess, isSearched, searchedOffers])

  const confirmationDialogRef = useRef<IConfirmationDialogRef>(null)
  const listRef = useRef<IContentListRef>(null)
  useEffect((): void => {
    if (isMobile) {
      if ((!loadOffers || loadOffers.length === 0) && isTourOpen) {
        listRef.current?.open([tourData.offer])
      } else {
        listRef.current?.open(loadOffers)
      }
    } else {
      if ((!loadOffers || loadOffers.length === 0) && isTourOpen) {
        ref.current?.open([tourData.offer], columns)
      } else {
        ref.current?.open(loadOffers, columns)
      }
    }
  }, [isMobile, loadOffers, isTourOpen])

  const getProperView = (): JSX.Element => {
    if (!isOffersSuccess) {
      return (
        <Box className={styles.loader}>
          {Array(12)
            .fill(null)
            .map((_, index) => (
              <Skeleton
                key={index}
                animation={'wave'}
              />
            ))}
        </Box>
      )
    }
    if (isMobile) {
      return (
        <ContentList
          ref={listRef}
          openDialog={handleOfferDialogOpen}
          listComponent={OfferCard}
        />
      )
    }
    return (
      <ContentTable
        ref={ref}
        batchOperations={true}
        freeActions={freeActions}
        cellRenderer={cellRenderer}
        openDialog={handleOfferDialogOpen}
      />
    )
  }

  return (
    <>
      <OffersMarketOptionCard
        value={searchPhrase}
        handleSearch={handleSearchOffers}
      />
      {getProperView()}
      <SideDialog
        ref={offerDialogRef}
        title={t('Market:carrierLoadDialog.title')}
      >
        <OfferDialog
          refetch={refetchOffers}
          elementRef={offerDialogRef}
          confirmationRef={confirmationDialogRef}
          handleClose={handleOfferDialogClose}
        />
      </SideDialog>
      <SideDialog
        ref={createOfferDialogRef}
        title={t('Market:offerDialog.createTitle')}
      >
        <CreateOfferDialog
          refetch={refetchOffers}
          handleClose={handleCreateOfferDialogClose}
        />
      </SideDialog>
      <ConfirmationDialog ref={confirmationDialogRef} />
    </>
  )
}

export default OffersManager
