import { motion } from 'framer-motion'

export interface IAnimatedButton {
  children: JSX.Element | JSX.Element[]
  className?: string
}

export const AnimatedButton = ({ children, className }: IAnimatedButton) => {
  return (
    <motion.div
      className={`animatable ${className}`}
      whileHover={{
        scale: 1.1,
        transition: { duration: 0.2 },
      }}
      whileTap={{ scale: 0.9 }}
    >
      {children}
    </motion.div>
  )
}
