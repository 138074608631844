import styles from './card.module.scss'
import { t } from 'i18next'
import { ITheme } from '@pkg/sokarUI'
import { ITrailer } from '@pkg/equipment/models'

import UsedByCell from '../usedByCell'

import { Box, Stack, Paper, Divider, Typography, useTheme } from '@mui/material'

import PersonIcon from '@mui/icons-material/Person'
import NumbersIcon from '@mui/icons-material/Numbers'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'

interface ITrailerCardProps {
  data: ITrailer
  openDialog: (row: any) => void
}

const TrailerCard = ({ ...props }: ITrailerCardProps): JSX.Element => {
  const appTheme: ITheme = useTheme()

  return (
    <Paper
      className={styles.wrapper}
      onClick={() => props.openDialog(props.data)}
      sx={{ backgroundColor: appTheme.palette.background.default }}
    >
      <Stack
        direction={'column'}
        spacing={2}
      >
        <Typography>
          {t(`Fleet:trailerCard.${props.data.isCarrierOwned ? 'contractor' : 'company'}`)}
        </Typography>
        <Divider />
        <Box className={styles.text}>
          <NumbersIcon />
          {props.data.number}
        </Box>
        <Box className={styles.text}>
          <LocalShippingIcon />
          {props.data.plate}
        </Box>
        <Box className={styles.text}>
          <PersonIcon />
          {props.data.usedBy && <UsedByCell driverId={props.data.usedBy} />}
        </Box>
      </Stack>
    </Paper>
  )
}

export default TrailerCard
