import { useState, FC, ChangeEvent } from 'react'
import { t } from 'i18next'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import useOwnUser from '@hooks/useOwnUser'
import useAlert from '@hooks/useAlert'

import CompanySearch from '@lib/CompanySearch'
import { parseError } from '@shared/utils/formatters'
import { truckValidator } from '@pkg/equipment/validators'
import { ICreateTruckRequest } from '@pkg/equipment/requests'
import { ICreateFleetObjectDialogProps } from '@pkg/sokarUI'
import { useCreateTruckMutation } from '@features/equipment/equipmentApiSlice'

import { Box, Stack, Button, Checkbox, TextField, Typography } from '@mui/material'

const CreateTruckDialog: FC<ICreateFleetObjectDialogProps> = ({ ...props }) => {
  const { companyId } = useOwnUser()
  const { dispatch: dispatchAlert } = useAlert()

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ICreateTruckRequest>({
    resolver: yupResolver(truckValidator),
  })

  const [createTruck] = useCreateTruckMutation()
  const onSubmit = (truck: ICreateTruckRequest): void => {
    if (!companyId) {
      dispatchAlert({
        type: 'SHOW',
        payload: {
          content: String(t('Common:error.unexpectedError')),
          severity: 'error',
        },
      })
      return
    }
    truck.companyId = companyId
    if (!truck.isCarrierOwned) {
      delete truck.carrierId
    }

    createTruck(truck)
      .unwrap()
      .then(() => {
        dispatchAlert({
          type: 'SHOW',
          payload: {
            content: t('Fleet:responses.truckCreated'),
            severity: 'success',
          },
        })
      })
      .then(() => props.refetch())
      .then(() => props.handleClose(false))
      .catch((err: any) => {
        const error = parseError<any>(err.data.message)
        dispatchAlert({
          type: 'SHOW',
          payload: {
            content: String(t(error.dictKey, { ...error.dependencies })),
            severity: 'error',
          },
        })
      })
  }

  const [isCarrierSelectDisabled, setIsCarrierSelectDisabled] = useState<boolean>(true)
  const handleIsCarrierOwnedChange = (
    value: boolean,
    onChange: (...event: any[]) => void,
    e: ChangeEvent<HTMLInputElement>,
  ): void => {
    setIsCarrierSelectDisabled(!value)
    onChange(e)
  }

  return (
    <Stack
      component={'form'}
      onSubmit={handleSubmit(onSubmit)}
      direction={'column'}
      spacing={2.5}
    >
      <Controller
        control={control}
        name={'plate'}
        render={({ field }) => (
          <TextField
            {...field}
            label={t('Fleet:createTruckDialog.plates')}
            error={!!errors?.plate}
            helperText={<Box height={3}>{errors?.plate?.message}</Box>}
          />
        )}
      />
      <Controller
        control={control}
        name={'vin'}
        render={({ field }) => (
          <TextField
            {...field}
            label={'VIN'}
            error={!!errors?.vin}
            helperText={<Box height={3}>{errors?.vin?.message}</Box>}
          />
        )}
      />
      <Controller
        control={control}
        name={'make'}
        render={({ field }) => (
          <TextField
            {...field}
            label={t('Fleet:createTruckDialog.make')}
            error={!!errors?.make}
            helperText={<Box height={3}>{errors?.make?.message}</Box>}
          />
        )}
      />
      <Controller
        control={control}
        name={'model'}
        render={({ field }) => (
          <TextField
            {...field}
            label={t('Fleet:createTruckDialog.model')}
          />
        )}
      />
      <Controller
        control={control}
        name={'year'}
        render={({ field }) => (
          <TextField
            {...field}
            type={'number'}
            label={t('Fleet:createTruckDialog.year')}
            error={!!errors?.year}
            helperText={<Box height={3}>{errors?.year?.message}</Box>}
          />
        )}
      />
      <Controller
        control={control}
        name={'number'}
        render={({ field }) => (
          <TextField
            {...field}
            label={t('Fleet:createTruckDialog.number')}
          />
        )}
      />
      <Stack
        spacing={0}
        direction={'row'}
        alignItems={'center'}
      >
        <Typography>{t('Fleet:createTruckDialog.isCarrierOwned')}:</Typography>
        <Controller
          control={control}
          name={'isCarrierOwned'}
          render={({ field }) => (
            <Checkbox
              value={field.value}
              onChange={(e) => handleIsCarrierOwnedChange(e.target.checked, field.onChange, e)}
            />
          )}
        />
      </Stack>
      <Controller
        control={control}
        name={'carrierId'}
        render={({ field }) => (
          <CompanySearch
            {...field}
            error={errors?.carrierId}
            isDisabled={isCarrierSelectDisabled}
          />
        )}
      />
      <Button
        type={'submit'}
        variant={'contained'}
      >
        {t('Common:create')}
      </Button>
    </Stack>
  )
}

export default CreateTruckDialog
