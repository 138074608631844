import L, { LatLngTuple, LeafletMouseEvent } from 'leaflet'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MapContainer, Marker, Popup, TileLayer, useMapEvents } from 'react-leaflet'

import { DeadheadRadius } from '@/pkg/market/models'

import { getDistanceFromLatLonInKm, zoomSwitch } from '../utils/calcDist'
import updateMap from '../utils/updateMap'

export interface IMarketMapBoard {
  locationType: 'origin' | 'destination'
  originCords?: LatLngTuple
  destinationCords?: LatLngTuple
  onLocationChange: (type: 'origin' | 'destination', location?: LatLngTuple) => void
  originRadius?: number
  destinationRadius?: number
  originUnit: 'Kilometers' | 'Miles'
  destinationUnit: 'Kilometers' | 'Miles'
  originDeadheadRadius: DeadheadRadius
  destinationDeadheadRadius: DeadheadRadius
}

const MarketMapBoard = ({
  originCords,
  destinationCords,
  onLocationChange,
  locationType,
  originDeadheadRadius,
  destinationDeadheadRadius,
  originRadius = 0,
  destinationRadius = 0,
  originUnit = 'Miles',
  destinationUnit = 'Miles',
}: IMarketMapBoard) => {
  const { t } = useTranslation()
  const [positionOrigin, setPositionOrigin] = useState<LatLngTuple | undefined>(originCords)
  const [positionDestination, setPositionDestination] = useState<LatLngTuple | undefined>(
    destinationCords,
  )
  const [middlePoint, setMiddlePoint] = useState<LatLngTuple | undefined>(undefined)
  const [zoom, setZoom] = useState<number>(
    zoomSwitch(getDistanceFromLatLonInKm(positionOrigin, positionDestination)),
  )
  const [circleOrigin, setCircleOrigin] = useState<L.Circle | null>(null)
  const [circleDestination, setCircleDestination] = useState<L.Circle | null>(null)
  const [radiusOrigin, setRadiusOrigin] = useState<number>(originRadius)
  const [radiusDestination, setRadiusDestination] = useState<number>(destinationRadius)

  const useEffectDebounceOrigin = useRef(0)
  const useEffectDebounceDestination = useRef(0)

  const calculateMiddlePoint = useCallback(() => {
    if (!positionOrigin || !positionDestination) return undefined
    const [lat1, lon1] = positionOrigin
    const [lat2, lon2] = positionDestination

    const latMid = (lat1 + lat2) / 2
    const lonMid = (lon1 + lon2) / 2

    return [latMid, lonMid] as LatLngTuple
  }, [positionOrigin, positionDestination])

  useEffect(() => {
    if (!positionOrigin || !positionDestination) return
    setMiddlePoint(calculateMiddlePoint())
    setZoom(zoomSwitch(getDistanceFromLatLonInKm(positionOrigin, positionDestination)))
  }, [positionOrigin, positionDestination, calculateMiddlePoint])

  const SelectLocation = () => {
    const map = useMapEvents({
      click: (e?: LeafletMouseEvent) => {
        if (locationType === 'origin' && e?.latlng) {
          setPositionOrigin([e.latlng.lat, e.latlng.lng])
          onLocationChange(locationType, [e.latlng.lat, e.latlng.lng])

          if (circleOrigin) {
            circleOrigin.remove()
            setCircleOrigin(null)
          }

          const newCircleOrigin = L.circle([e.latlng.lat, e.latlng.lng], {
            radius: originRadius * 1000,
          }).addTo(map)

          setCircleOrigin(newCircleOrigin)
        } else if (locationType === 'destination' && e?.latlng) {
          setPositionDestination([e.latlng.lat, e.latlng.lng])
          onLocationChange(locationType, [e.latlng.lat, e.latlng.lng])

          if (circleDestination) {
            circleDestination.remove()
          }

          const newCircleDestination = L.circle([e.latlng.lat, e.latlng.lng], {
            radius: destinationRadius * 1000,
          }).addTo(map)

          setCircleDestination(newCircleDestination)
        }
      },
    })

    useEffect(() => {
      useEffectDebounceOrigin.current += 1
      if (!positionOrigin && originCords) setPositionOrigin(originCords)

      updateMap({
        deadheadRadius: originDeadheadRadius,
        unit: originUnit,
        position: positionOrigin,
        radius: originRadius,
        updatedRadius: radiusOrigin,
        circle: circleOrigin,
        setCircle: setCircleOrigin,
        setRadius: setRadiusOrigin,
        _L: L,
        map,
        debounce: useEffectDebounceOrigin.current,
      })
    }, [
      map,
      positionOrigin,
      originCords,
      originDeadheadRadius,
      originUnit,
      originRadius,
      radiusOrigin,
    ])

    useEffect(() => {
      useEffectDebounceDestination.current += 1
      if (!positionDestination && destinationCords) setPositionDestination(destinationCords)

      updateMap({
        deadheadRadius: destinationDeadheadRadius,
        unit: destinationUnit,
        position: positionDestination,
        radius: destinationRadius,
        updatedRadius: radiusDestination,
        circle: circleDestination,
        setCircle: setCircleDestination,
        setRadius: setRadiusDestination,
        _L: L,
        map,
        debounce: useEffectDebounceDestination.current,
      })
    }, [
      map,
      positionDestination,
      destinationCords,
      destinationDeadheadRadius,
      destinationUnit,
      destinationRadius,
      radiusDestination,
    ])

    return null
  }

  return (
    <>
      <MapContainer
        center={middlePoint ?? [37, 0]}
        zoom={zoom}
        className='market'
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        />
        {positionOrigin && (
          <Marker position={positionOrigin}>
            <Popup>{t('Market:form.origin')}</Popup>
          </Marker>
        )}
        {positionDestination && (
          <Marker position={positionDestination}>
            <Popup>{t('Market:form.destination')}</Popup>
          </Marker>
        )}
        <SelectLocation />
      </MapContainer>
    </>
  )
}

export default MarketMapBoard
