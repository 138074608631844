import { motion, AnimatePresence } from 'framer-motion'
import sokarLogo from 'src/assets/img/sokar_logo_v2.svg'
import './styles.scss'

interface ISplashScreenProps {
  finishLoading: () => void
  isLoading: boolean
}

const SplashScreen = ({ ...props }: ISplashScreenProps): JSX.Element => {
  return (
    <>
      {props.isLoading && (
        <motion.div
          className={'splash_screen_wrapper'}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            duration: 1.25,
          }}
          onAnimationComplete={props.finishLoading}
        >
          <AnimatePresence>
            <motion.img
              key={'splash_screen_logo'}
              src={sokarLogo}
              style={{ width: '400px' }}
              initial={{ scale: 0.5, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{
                duration: 1,
              }}
            />
          </AnimatePresence>
        </motion.div>
      )}
    </>
  )
}

export default SplashScreen
